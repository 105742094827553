import { Heading } from 'grommet';
import { TemplateList } from './TemplateList';
import { TemplatesStyled } from './Templates.css';

export const Templates = () => {
  return  (
    <TemplatesStyled 
      forwardedAs="section"
      width="100%"      
    >
      <Heading 
        level="1" 
        size="small" 
        color="h1"
        margin={{ vertical: 'small' }}
      >
        Manage Templates
      </Heading>
      <TemplateList master={ false } />
    </TemplatesStyled>
  )
};