import { useState, useCallback, useEffect } from 'react';
import { Box, Paragraph, Button } from 'grommet';
/* ----- */
import { useModal } from '../modal/Modal.js';
import { getClientName } from '../utilities/util.js';

export const useClientDelete = (onDelete) => {
  const [ client, setClient ] = useState(null);
  const clientFullName = getClientName(client);
  const { 
    Modal, 
    // opModal, 
    // clModal, 
  } = useModal();    

  const showDeleteModal = useCallback((client) => {
    setClient(client);
  }, []);

  const deleteClient = async () => {
    setClient(null); // closes modal
    await onDelete(client.id);    
  };

  const closeModal = useCallback(() => {
    setClient(null);
  }, []);

  const DeleteModal = () => {
    return client ? (
      <Modal 
        initOpen={ true }
        content={
          <>
            <Paragraph size="small" margin={{ top: 'none', right: 'none', bottom: 'small', left: 'none' }}>Are you sure you want to permanently delete { clientFullName.length ?  clientFullName : 'this client' }?</Paragraph>

            <Box direction="row" justify="between">
              
              <Button default size="small" color="cancel_btn" onClick={ closeModal } a11yTitle="cancel delete client" label="Cancel" />

              <Button secondary className="delete-btn" size="small" color="warn_btn" onClick={ deleteClient } a11yTitle="delete client" label="Delete Client" />

            </Box>
          </>
        }  
      />      
    ) : null;
  };   

  return { 
    DeleteModal: DeleteModal,
    deleteClient: showDeleteModal,
  };
};