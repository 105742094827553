import styled from 'styled-components';

export const ProfileFormStyled = styled.form`
  max-width: 60rem;
  position: relative;
  .text-input-wrap {
    margin-bottom: ${props => props.theme.global.edgeSize.small};
  }
  [name="state"] {
    text-transform: uppercase;
  }
`;