import styled from 'styled-components';

export const ConnectFormStyled = styled.form`
  max-width: 40rem;
  position: relative;
  width: 100%; 
  display: flex;
  flex-flow: column nowrap;
  /* margin-top: ${props => props.theme.global.edgeSize.small}; */
  fieldset {
    border: none;
  }
`;