import { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Settings } from 'iconoir-react';
import { ResponsiveContext } from 'grommet';
/* ----- */
import { useContextVal } from '../auth/Auth.js';
import { useLogOut } from '../log_in_out/LogInOut';
import { SettingsNavStyled } from './SettingsNav.css.js';


export const SettingsNav = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const ctxt = useContextVal();  
  const size = useContext(ResponsiveContext);
  const { logOut, loading } = useLogOut();
  const isConnected = Number.isInteger(ctxt?.user?.pp_user_id);

  const navBtnClick = (route) => {
    if (route === pathname) {
      window.location.reload();
    } else {
      navigate(route);
    }
  };

  const defaultNavItems = [
    { 
      label: 'User Profile', 
      onClick: () => navBtnClick('/profile'),
      plain: true,
      size: 'small',
      className: 'settings-nav-btn'
    },
    { 
      label: `${ ctxt?.user?.stripe_configured === false ? 'Add' : 'Update' } Credit Card`, 
      onClick: () => navBtnClick('/billing'),
      plain: true,
      size: 'small',
      className: 'settings-nav-btn'
    },
    { 
      label: `${ isConnected ? 'Disconnect' : 'Connect to' } Paperless Pipeline`, 
      onClick: () => navBtnClick('/connect'),
      plain: true,
      size: 'small',
      className: 'settings-nav-btn'
    }
  ];

  const logOutItem = { 
    label: 'Log Out',
    onClick: logOut,          
    plain: true,
    size: 'small',
    className: 'settings-nav-btn'
  };

  const otherNavItems = [
    { 
      label: 'Manage Clients',
      onClick: () => navBtnClick('/clients'),   
      plain: true,
      size: 'small',
      className: 'settings-nav-btn'
    },
    { 
      label: 'Client Messages',
      onClick: () => navBtnClick('/client-messages/pending'),   
      plain: true,
      size: 'small',
      className: 'settings-nav-btn'
    },
    { 
      label: 'Message Template Setup',
      onClick: () => navBtnClick('/templates'),   
      plain: true,
      size: 'small',
      className: 'settings-nav-btn'
    },   
    { 
      label: null,
      plain: true,
      size: 'small',
      className: 'settings-nav-spacer'
    },            
  ];

  const navItems = size === 'large' ? [
    ...defaultNavItems, 
    logOutItem
  ] : [
    ...otherNavItems,
    ...defaultNavItems,     
    logOutItem
  ];
  
  return (
    <SettingsNavStyled
      // open={ open }
      className="settings-nav"
      label={ loading ? 
        <span className="log-out-ui">Logging Out</span> : 
        null 
      }
      size="small"
      disabled={ loading }
      icon={ <Settings height={20} width={20} /> }      
      dropAlign={{ top: 'bottom', right: 'right' }}
      dropProps={{
        background: 'cyan3',
        margin: { top: 'xxsmall' },
        round: 'xsmall',        
      }}      
      items={ navItems }
    />
  );
};