import { Heading } from 'grommet';
import { useContextVal } from '../auth/Auth';
import { ConnectForm } from './ConnectForm';
import { ConnectStyled } from './Connect.css';

export const Connect = () => {
  const ctxt = useContextVal();
  const isConnected = Number.isInteger(ctxt?.user?.pp_user_id);
  return  (
    <ConnectStyled 
      forwardedAs="section"      
    >
      <Heading 
        level="1" 
        size="small" 
        color="h1"
        margin={{ vertical: 'small' }}
      >
        { isConnected ? 'Disconnect' : 'Connect to' } Paperless Pipeline
      </Heading>
      <ConnectForm />
    </ConnectStyled>
  )
};