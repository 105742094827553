import { CheckBox, Box } from 'grommet';
import { useController } from 'react-hook-form';
import { formErr /*, onError */ } from '../errors/errors';

export const CheckboxGroup = ({ 
  control,
  name: checkboxName,
  label, 
  register, 
  errors,
  a11yTitle = null,
  msg = null,
  tooltip = null,
  boxProps = null,
  ...rest
}) => {
  const {
    field: {  /* onChange, ref, onBlur, */ name, value },
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields }
  } = useController({
    name: checkboxName,
    control: control,
    // rules: { required: true },
    // defaultValue: '',
  });  
  return (
    <>
      <Box         
        className="checkbox-wrap"        
        direction="row"   
        data-tip={ tooltip }
        { ...boxProps }
      >

        <CheckBox           
          fill={ false }
          a11yTitle={ a11yTitle ? a11yTitle : label }
          label={ label }
          checked={ value } 
          data-tip={ tooltip }
          { ...rest }  
          { ...register(name) }   
        />   

      </Box>

      { msg }  

      { formErr(errors, name) }
    </>
  );
};

