import styled from 'styled-components';

export const ClientFormStyled = styled.form`
  width: 64rem;

  .text-input-wrap {
    margin-bottom: ${props => props.theme.global.edgeSize.small};
  }
  .checkbox-wrap {
    margin-top: ${props => props.theme.global.edgeSize.xsmall};
  }  
  .text-input-wrap label {
    padding-left: ${props => props.theme.global.edgeSize.xxsmall};
  }
  [name="state"] {
    text-transform: uppercase;
  }

  /* media queries -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
  @media (max-width: ${props => props.theme.global.breakpoints.small.value}px) { 
    width: 100%;
  }
  /* @media (max-width: ${props => props.theme.global.breakpoints.xsmall.value}px) { }   */
`;