import styled from 'styled-components';

export const SignUpFormStyled = styled.form`
  width: 100%;
  max-width: 28rem;
  
  .text-input-wrap {
    margin-bottom: ${props => props.theme.global.edgeSize.small};
  }
  .text-input-wrap label,
  .checkbox-wrap label > span {
    padding-left: ${props => props.theme.global.edgeSize.xxsmall};
  }
`;