import { useState, useEffect, useContext } from 'react';
import { Grid, Pagination, Box, Heading, Text, Button, ResponsiveContext } from 'grommet';
import { EyeEmpty, ArrowUp } from 'iconoir-react';
/* ----- */
import { useAxios } from '../ajax/Ajax.js';
import { useError } from '../errors/errors.js';
import { useLoading } from '../loading/loading.js';
import { useContextVal } from '../auth/Auth.js';
import { getTs } from '../utilities/util.js';
import { AuditLogListHeader, AuditLogListItem } from './AuditLogListItem.js';
import { AuditLogFilters } from './AuditLogFilters.js';
import { AuditLogListStyled } from './AuditLogList.css.js';

const defaultErrMsg = 'An error occurred while accessing your audit log. Please refresh the page and try again.';

export const AuditLogList = () => {  
  const ctxt = useContextVal();
  const ts = getTs();
  const size = useContext(ResponsiveContext);  
  const [ filterQuery, setFilterQuery ] = useState('');
  const [ auditLogs, setAuditLogs ] = useState(null);
  const [ showAllDetails, setShowAllDetails ] = useState(false);
  const [ pageObj, setPageObj ] = useState({ 
    page: 1, 
    pages: 1,
    per_page: 0,
    total: 0,
  });
  const [
    { 
      data: getData, 
      loading: getLoading, 
      error: getError
    },
    executeGet
  ] = useAxios(
    {
      url: `audit-log/?page=${ pageObj.page }${ filterQuery }`,
      // url: 'audit-log/',
      method: 'GET',
      // params: { page: pageObj.page, ...filterQuery },
    },
    { manual: true }    
  );

  const { ErrModal, onError } = useError(null, defaultErrMsg);
  const { Loading } = useLoading(
    getLoading, 
    'Loading Audit Logs...'
  );

  const paginate = ({ page, startIndex, endIndex }) => {
    if (page !== pageObj.page) {
      setPageObj(curr => {
        return {
          ...curr,
          ...{ page: page },
        }
      });
    }
  };

  const toggleAll = () => {
    setShowAllDetails(!showAllDetails);
  };
  
  useEffect(() => {
    if (      
      getData?.success && 
      getData?.audit_logs
    ) {      
      setAuditLogs(getData.audit_logs);
      setPageObj({
        page: getData?.page || 0,
        pages: getData?.pages || 0,
        total: getData?.total || 0,
        per_page: getData?.per_page || 0,
      });
    }    
    getData && !getData.success && onError(getData);
  }, [ getData, onError ]);  

  useEffect(() => {    
    executeGet(null, { /* useCache: true */ });
  }, [ pageObj.page, executeGet ]);

  useEffect(() => {    
    onError(getError?.toJSON());
  }, [ getError, onError ]);
  
  return  (
    <AuditLogListStyled 
      gap="none"
      width={{ min: '28rem', max: '100rem' }}
      height={{ min: '30rem' }}
    >

      <Box 
        justify="between" 
        direction="row-responsive"
        align-content="between"
        alignSelf="start"
        basis="auto"
        fill="horizontal"
        flex={ false }
      >

        <Heading 
          level="1" 
          size="small" 
          color="h1"
          margin={{ vertical: 'small' }}
        >
          Audit Log
        </Heading>

        { auditLogs?.length > 0 && pageObj?.pages > 1 &&
          <Pagination 
            className="pagination"
            a11yTitle="pagination ui"
            pad={{ top: 'none', right: 'none', bottom: 'none', left: 'small' }}
            margin="none"
            page={ pageObj?.page }
            numberEdgePages={ 1 }            
            numberItems={ pageObj?.total }
            numberMiddlePages={ 3 }
            step={ pageObj?.per_page }
            onChange={ paginate }
            size="small"
            alignSelf="end"
            style={{ pointerEvents: `${ getLoading ? 'none' : 'all'}` }} 
          /> 
        }

      </Box>

      {/* <Text>
        // https://storybook.grommet.io/?path=/story/controls-pagination-grid--paginated-grid
        Showing {indices[0] + 1} - {indices[1]} of {data.length}
      </Text> */}

      <Box 
        direction="row" 
        justify="between" 
        align="end" 
        pad={{ vertical: 'small' }}
        className="filter-wrap"
      >

        <AuditLogFilters 
          setFilterQuery={ setFilterQuery } 
          getLoading={ getLoading }
          size={ size }
          eventOpts={ ctxt?.settings?.audit_event_choices || [] }  
          dateRangeOpts={ ctxt?.settings?.date_range_choices || [] }  
        />

        <Button 
          className="row-btn"
          onClick={ toggleAll } 
          a11yTitle="Show / Hide All Details"
          icon={ showAllDetails ? <ArrowUp height={20} width={20} /> : <EyeEmpty height={20} width={20} /> }  
        />        

      </Box>

      { auditLogs?.length > 0 &&
        <Grid
          fill="horizontal"
          rows={['auto']}
          align="stretch"
          columns={['auto', 'auto', 'auto', 'auto', '44px']}
          border={ false }
          pad="none"
        > 
          <AuditLogListHeader />
          {
            auditLogs?.map( (auditLog, index) => {                    
              return <AuditLogListItem 
                auditLog={ auditLog } 
                key={`audit-log-list-item-${index}-${ts}`} 
                showAllDetails={ showAllDetails }
              />;
            })
          }
        </Grid>
      }  

      { auditLogs && !auditLogs.length && 
        <Box
          fill="horizontal"
          pad="small"
          direction="row" 
          round="xsmall"
          background="brand_light"
          justify="center"
          align="center"
        >
          <Text size="small">No audit logs found.</Text>
        </Box>
      }        

      <Loading /> 

      <ErrModal />

    </AuditLogListStyled>    
  );
};