import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Box, Button, Grid, Text } from 'grommet';
import * as yup from 'yup';
import { useContextVal } from '../auth/Auth';
import { InputGroup } from '../ui/InputGroup';
import { FormInfoBox } from '../ui/FormInfoBox.js';
import { useAxios /*, cache */ } from '../ajax/Ajax';
import { useError } from '../errors/errors';
import { useSuccess } from '../success/success';
import { useLoading } from '../loading/loading.js';
import { BillingFormStyled } from './BillingForm.css';
import { CreditCard } from 'iconoir-react';

const defaultErrMsg = 'An error occurred while processing billing details. Please refresh the page and try again.';
const successMsg = 'Your billing details have been successfully updated.';

const initFormVals = { 
  card_number: '',
  card_name: '',
  card_expires: '',
  card_zip_code: ''
};

export const stripeSchema = yup.object({
  card_number: yup.string().trim().test(
    'test-number',
    'Please enter a valid credit card number.',
    value => {
      return window.Stripe ? window.Stripe.card.validateCardNumber(value) : false;
    }
  ).min(16, 'Not a valid card number').max(32, 'Not a valid card number').required('Card number is required.'),
  card_name: yup.string().trim().max(50, 'Not a valid name, too long.').required('Please enter a valid name.'),
  card_expires: yup.string().trim().test(
    'test-name',
    'Please enter a valid expiration date.',
    value => {
      return window.Stripe ?  window.Stripe.card.validateExpiry(value) : false;
    }
  ).max(8, 'Not a valid card expiry date.').required('Card expiry is required.'),
  card_zip_code: yup.string().trim().test(
    'test-zip-code',
    'Please enter a valid billing ZIP code.',
    value => {
      const zip_rex = /^\d{5}$/;
      const ca_zip_rex = /^[ABCEGHJKLMNPRSTVXY]\d[A-Z]\s\d[A-Z]\d$/;
      if (value.length !== 5 || !value.match(zip_rex)) {
        if ((value.length === 7) && value.match(ca_zip_rex)) {
          return true;
        }
        return false;
      }
      return true;
    }
  ).max(10, 'Not a valid zip code.').required('Card zip code is required.'),
});

export const BillingForm = () => {
  const ctxt = useContextVal();  
  const [ last4, setLast4 ] = useState(null);
  const [ stripeLoaded, setStripeLoaded ] = useState(false);
  const [ stripeLoading, setStripeLoading ] = useState(false);
  
  const {
    register,
    handleSubmit,    
    reset,
    formState: { errors },
    setError,
  } = useForm({
    mode: 'onTouched', //  onChange | onBlur | onSubmit | onTouched | all
    defaultValues: initFormVals,    
    resolver: yupResolver(stripeSchema),
    delayError: 250
  });
  const [
    { 
      data: getData, 
      loading: getLoading, 
      error: getError
    }
  ] = useAxios(
    {
      url: 'stripe/',
      method: 'GET'
    },
    { manual: false, useCache: false } // no cache for stripe data
  );  
  const [
    { 
      data: postData, 
      loading: postLoading, 
      error: postError
    },
    executePost
  ] = useAxios(
    {
      url: 'stripe/',       
      method: 'POST'
    },
    { manual: true }
  );

  const { SuccessModal, setSuccess } = useSuccess(successMsg);
  const { ErrModal, onError } = useError(setError, defaultErrMsg);
  const { Loading } = useLoading(
    ( postLoading || stripeLoading ), 
    'Loading...'
  );

  const setCcMessage = (data) => {
    if (
      data?.last4 && 
      data?.last4?.length
    ) {
      setLast4(data.last4);
    }    
  };

  const onSubmit = async (data) => {
    try {
      setStripeLoading(true);
      window.Stripe.card.createToken({
        number: data.card_number,
        name: data.card_name,
        exp: data.card_expires,
        address_zip: data.card_zip_code,
      }, async (status, response) => {
        setStripeLoading(false);
        if (response.error) {
          return onError(response.error);
        }
        // onError({message: 'some error message'});
        const token = response.id;      
        await executePost({ data: { stripetoken: token } });
      });
    } catch (err) {
      setStripeLoading(false);
      return onError({ message: 'error' }); // triggers global, default error msg
    }
  };    

 useEffect(() => {
    if (
      stripeLoaded && 
      getData?.stripe_publishable_key
    ) {      
      window.Stripe.setPublishableKey(getData.stripe_publishable_key);
    }
  }, [ stripeLoaded, getData ]);

  useEffect(() => {
    if (window.Stripe) {
      return setStripeLoaded(true); // prevent loading script multiple times
    }
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://js.stripe.com/v2/';
    script.onload = () => {
      if (window.Stripe) {
        setStripeLoaded(true);
      }
    };
    document.body.appendChild(script);
  }, []); 

  useEffect(() => {
    if (      
      postData?.success &&
      postData?.user
    ) {
      reset(initFormVals);
      setSuccess(true);
      ctxt.updateUser(postData.user);  
      setCcMessage(postData);    
      // cache.clear();
    }
    postData && !postData.success && onError(postData);
  }, [ postData, ctxt, reset, onError, setSuccess ]);  

  useEffect(() => {
    getData && !getData.success && onError(getData);
    getData && setCcMessage(getData);
  }, [ getData, onError ]);     

  useEffect(() => {
    const error = getError ? 
      getError?.toJSON() : 
      postError?.toJSON();
    onError(error);
  }, [ getError, postError, onError ]);

  return  (
    <BillingFormStyled onSubmit={handleSubmit(onSubmit /*, onError */ )}>      
      
      { getData && last4 &&
        <Box animation={['fadeIn', 'slideDown']}>
          <Grid 
            margin={{ bottom: 'small' }}
            fill={ false }
            pad="small"        
            rows={['auto']}        
            columns={['auto', '1fr']}        
            className="form-info-box"
            
          >

            <Text size="xsmall">Active Credit Card:</Text>
            <Text size="xsmall" margin={{ left: 'xsmall' }}>
              { last4 ? `**** **** **** ${ last4 }` : 'No Credit Card Added' }
            </Text>

          </Grid>
        </Box>
      }

      <Box 
        as="fieldset" 
        pad="none"
        margin="none"
        disabled={ getLoading || postLoading || !stripeLoaded }
      >      

        { ( ctxt?.user?.stripe_configured === false ) &&
          <FormInfoBox
            title="To-Do"
            content={ <Text size="small" color="white" weight={ 500 }>To activate clients (and messages), you'll first need to add your credit card info below.</Text> }
            background="blue10"
            boxProps={{ margin: { top: 'xsmall', bottom: 'small', left: 'none', right: 'none' } }}
          />
        }

        <InputGroup
          type="text"
          name="card_number"
          label="Credit Card Number"
          errors={errors}
          register={register}
        />  

        <InputGroup
          type="text"
          name="card_name"
          label="Name (as it appears on the card)"
          errors={errors}
          register={register}
        />  

        <InputGroup
          type="text"
          name="card_expires"
          label="Expires on (MM/YY)"
          errors={errors}
          register={register}
        /> 

        <InputGroup
          type="text"
          name="card_zip_code"
          label="Billing Zip Code"
          errors={errors}
          register={register}
        />        

        <Button 
          primary 
          margin={{ vertical: 'small' }}
          size="small" 
          label="Update Credit Card" 
          type="submit"
          alignSelf="start"      
          gap="xsmall"  
          icon={ <CreditCard height={20} width={20} /> }        
        />

      </Box>       

      <SuccessModal />

      <ErrModal />

      <Loading />

    </BillingFormStyled>
  );
};