import styled from 'styled-components';
import { Header } from 'grommet';

export const HeaderStyled = styled(Header)`
  .pp-logo {
    display: flex;
    align-items: center;
    width: unset;
    height: 5rem;
    padding: 0.5rem 0;
    text-decoration: none;
  }
  .pp-logo svg {
    height: 4rem;
    margin-right: 1rem;
  }
  .pp-logo span {
    color: ${({ theme }) => theme.global.colors.cyan9 };
    font-size: 2rem;
    font-weight: bold;
  }

  @media (max-width: ${props => props.theme.global.breakpoints.medium.value}px) { 
    padding-right: ${({ theme }) => theme.global.edgeSize.small};
  }  
`;