import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { normalizeColor } from 'grommet/utils';

const LnkStyles = styled.a`
  display: flex;    
  align-items: center;
  font-size: ${({ theme }) => theme.text.small.size };    
  line-height: ${({ theme }) => theme.text.small.height };
  height: 100%;
  padding: 0 2rem;
  text-decoration: none;
  font-weight: normal;  
  position: relative;
  border: none;
  background: #fff;
  cursor: pointer;
  border-radius: 0.4rem;
  box-shadow: inset 0 0 0 0.1rem ${({ theme }) => normalizeColor('nav_btn_border', theme)};
  background: ${({ theme }) => normalizeColor('nav_btn_bg', theme)};
  color: ${({ theme }) => normalizeColor('nav_btn_text', theme)};  

  &.left-nav {
    height: unset;    
    padding: 0.8rem 1.2rem;    
    margin-right: 0;
  } 

  &.left-nav + .left-nav {
    margin-top: 2rem;
  }
  
  &.lnk-active,
  &:hover {
    box-shadow: inset 0 0 0 0.1rem ${({ theme }) => normalizeColor('nav_btn_border_hover', theme)};
    background: ${({ theme }) => normalizeColor('nav_btn_bg_hover', theme)};
    color: ${({ theme }) => normalizeColor('nav_btn_text_hover', theme)};
  }
  &.log-in-btn {
    margin-right: ${({ theme }) => theme.global.edgeSize.small};
  }

  /* media queries -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
  @media (max-width: ${props => props.theme.global.breakpoints.xsmall.value}px) { 
    &.sign-up-btn,
    &.log-in-btn {
      padding: ${({ theme }) => theme.global.edgeSize.small};
    }
  }
`;

export const BtnLnkStyled = styled(LnkStyles)`

  /* &:hover {
    background: orangered;
    box-shadow: inset 0 0 0 0.1rem orangered;
  } */
`;

const NavLinkCustom = ({ 
  to, 
  className, 
  txt, 
  badge = null, 
  refresh = false 
}) => {
  const { pathname } = useLocation();
  const refreshPage = () => {
    if (refresh && to === pathname) {
      // could also reload data here
      window.location.reload();
    }
  };
  return (   
    <NavLink 
      to={ to }
      state={{
        previousPath: pathname,
      }}
      className={({ isActive }) => {
        return className + ( isActive ? ' lnk-active' : '' ); 
      }}      
      onClick={ refreshPage }      
    >
      { txt }
      { badge }
    </NavLink> 
  );
};

export const LnkStyled = ({ ...props }) => {
  return ( 
    <LnkStyles 
      as={NavLinkCustom}
      { ...props }    
    />
  );
};