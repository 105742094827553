
import { createGlobalStyle } from 'styled-components';
// import styledSanitize from 'styled-sanitize';

import TtNormsLightWoff from './fonts/TTNorms/TTNorms-Light.woff';
import TtNormsRegularWoff from './fonts/TTNorms/TTNorms-Regular.woff';
import TtNormsMediumWoff from './fonts/TTNorms/TTNorms-Medium.woff';
import TtNormsBoldWoff from './fonts/TTNorms/TTNorms-Bold.woff';

import TtNormsLightWoff2 from './fonts/TTNorms/TTNorms-Light.woff2';
import TtNormsRegularWoff2 from './fonts/TTNorms/TTNorms-Regular.woff2';
import TtNormsMediumWoff2 from './fonts/TTNorms/TTNorms-Medium.woff2';
import TtNormsBoldWoff2 from './fonts/TTNorms/TTNorms-Bold.woff2';

// ${styledSanitize}

export default createGlobalStyle`

  /* FONT: TT Norms -=-=-=-=- */
  /*
  @font-face {
    font-family: 'TT-Norms';
    src: url('../fonts/TTNorms-Thin.woff2') format('woff2'),
        url('../fonts/TTNorms-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
  }
  @font-face {
    font-family: 'TT-Norms';
    src: url('../fonts/TTNorms-ExtraLight.woff2') format('woff2'),
        url('../fonts/TTNorms-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: 'TT-Norms';
    src: url('../fonts/TTNorms-ExtraBold.woff2') format('woff2'),
        url('../fonts/TTNorms-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
  }
  @font-face {
    font-family: 'TT-Norms';
    src: url('../fonts/TTNorms-Heavy.woff2') format('woff2'),
        url('../fonts/TTNorms-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
  }
  @font-face {
    font-family: 'TT-Norms';
    src: url('../fonts/TTNorms-Black.woff2') format('woff2'),
        url('../fonts/TTNorms-Black.woff') format('woff');
    font-weight: 1000;
    font-style: normal;
  }
  */
  @font-face {
    font-family: 'TT Norms';
    src: url(${TtNormsLightWoff2}) format('woff2'),
        url(${TtNormsLightWoff}) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'TT Norms';
    src: url(${TtNormsRegularWoff2}) format('woff2'),
        url(${TtNormsRegularWoff}) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'TT Norms';
    src: url(${TtNormsMediumWoff2}) format('woff2'),
        url(${TtNormsMediumWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'TT Norms';
    src: url(${TtNormsBoldWoff2}) format('woff2'),
        url(${TtNormsBoldWoff}) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
  }
  /* end fonts */

  html,
  body,
  #root {
    height: 100%;
  }
  html {
    font-size: 62.5%;
    box-sizing: border-box;    
  }
  body {
    font-size: 1.6rem;    
    line-height: 1.4;
    color: ${({ theme }) => theme.global.colors.text.light }; ;
  }
  body, button, input, select, textarea {
    font-family: 'TT Norms', 'Poppins', 'sans-serif', 'Helvetica', 'Arial', 'sans-serif';
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "pnum";
    font-feature-settings: "pnum";
    font-variant-numeric: proportional-nums;
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    -moz-font-variant-ligatures: none;
    -ms-font-variant-ligatures: none;   
  }

  /* GLOBAL STYLES */
  a, 
  p a {
    font-weight: normal;    
    text-decoration: underline;
    cursor: pointer;
    box-sizing: border-box;
    font-size: inherit;
    line-height: inherit;
    color: ${({ theme }) => {
      return theme.global.colors.blue9;
    }};      
  }
  a:hover, 
  p a:hover {
    color: ${({ theme }) => theme.global.colors.blue11 };  
  }
  .text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .tooltip-text {
    font-size: ${({ theme }) => theme.text.small.size };    
    line-height: ${({ theme }) => theme.text.small.height };
  }
  .disabled-link {
    pointer-events: none;
  }
  .form-info-box {
    background: ${({ theme }) => theme.global.colors.brand_light};
    border-radius: ${({ theme }) => theme.global.edgeSize.xxsmall};
  }
  .delete-btn svg {
    color: ${({ theme }) => theme.global.colors['status-critical']};
  }
  .delete-btn:hover svg {
    color: ${({ theme }) => theme.global.colors.white};
  }  
  .react-tooltip {
    border-radius: ${({ theme }) => theme.global.edgeSize.xsmall} !important;
    background: ${({ theme }) => theme.global.colors.brand_light} !important;
    color: ${({ theme }) => theme.global.colors.black } !important; 
    font-size: ${({ theme }) => theme.text.small.size } !important;  
    line-height: ${({ theme }) => theme.text.small.height } !important; 
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.4);
  }
  .react-tooltip .multi-line {
    line-height: 1.2 !important;
  }
  .react-tooltip.show {
    opacity: 1 !important;
  }
  .place-top::after {
    border-top-color: ${({ theme }) => theme.global.colors.teal9} !important;
    bottom: -7px !important;
  }
  .place-bottom::after {
    border-bottom-color: ${({ theme }) => theme.global.colors.teal9} !important;
    top: -8px !important;
  }  
  .place-left::after {
    border-left-color: ${({ theme }) => theme.global.colors.teal9} !important;   
    transform: translateX(2px);
  }    
  .place-right::after {
    border-right-color: ${({ theme }) => theme.global.colors.teal9} !important;    
  }      
  .collapsible-wrap > div {
    width: 100% !important;
  }
  textarea {
    box-sizing: border-box;
    font-family: inherit;
    border: none;
    -webkit-appearance: none;
    background: transparent;
    color: inherit;
    width: 100%;
    padding: 4px 8px;
    ${'' /* padding: ${({ theme }) => {
      return `5px ${theme.global.edgeSize.small} 5px ${theme.global.edgeSize.small}`;
    }}; */}
    font-weight: normal;
    margin: 0;
    font-size: ${({ theme }) => theme.text.small.size };  
    line-height: ${({ theme }) => theme.text.small.height }; 
    border: 1px solid ${({ theme }) => theme.global.colors.sageA8};
    border-radius: ${({ theme }) => theme.global.edgeSize.xxsmall};
    resize: vertical;
    height: 100%;
    outline: none;
  }
  textarea:focus {
    outline: none;
    box-shadow: 0 0 2px 2px ${({ theme }) => theme.global.colors.teal5};
  }
  .warning-box,
  .warning-box span {
    white-space: normal;
    word-break: break-word;
  }
  body .settings-nav-btn {
    font-size: ${({ theme }) => theme.text.small.size };  
    line-height: ${({ theme }) => theme.text.small.height }; 
    color: ${({ theme }) => theme.global.colors.cyan11 };    
  }  
  body .settings-nav-btn:hover {
    background: ${({ theme }) => theme.global.colors.cyan4 };
  }
  body .settings-nav-btn > div {
    padding: ${({ theme }) => theme.global.edgeSize.small} ${({ theme }) => theme.global.edgeSize.small};
  }
  body .settings-nav-spacer {
    background: red;
    height: 0.2rem;
    border-radius: 0.1rem;
    width: calc(100% - 20px);
    background: ${({ theme }) => theme.global.colors.cyan7 }; 
    margin: ${({ theme }) => theme.global.edgeSize.xsmall} 10px;
  }

  /* media queries -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
  @media (max-width: ${props => props.theme.global.breakpoints.medium.value}px) { 
    .grommet-root { /* GROMMET ROOT ELEMENT */
      width: 100%;
      height: unset;
      overflow: unset;
    }
  }  
  ${'' /* @media (max-width: ${props => props.theme.global.breakpoints.xsmall.value}px) { } */}
`;
