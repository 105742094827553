import React, { createContext, useReducer, useContext } from 'react';

const initState = [];

const orderTemplates = (state) => {
  return state.sort((template1, template2) => { 
    const date1 = Date.parse(template1.due_date_sort);
    const date2 = Date.parse(template2.due_date_sort);
    if (date1 === date2 || !date1 || !date2) {
      return 0;
    }
    // most recent date first, future dates last
    return date1 < date2 ? -1 : 1;
  });
};

/*
const orderTemplates_old_032122 = (state) => {
  return state.sort((template1, template2) => { 
    // master templates dont have default_text
    if ( 
      !template1.default_text && 
      !template2.default_text && (
        !template1.is_enabled || 
        !template2.isEnabled
      )
    ) {
      if (template1.is_enabled === template2.is_enabled) { // both disabled
        return (template1.year_number === template2.year_number) ? 0 :
          template1.year_number < template2.year_number ? -1 : 1;
      }
      return template1.is_enabled? -1 : 1;
    }

    // normal sort, both templates enabled
    if (template1.year_number === template2.year_number) {
      return 0;
    }
    return template1.year_number < template2.year_number ? -1 : 1;
  });
};
*/

export const TemplateContext = createContext();

export const INIT_TEMPLATES = "INIT_TEMPLATES";
export const ADD_TEMPLATE = "ADD_TEMPLATE";
export const ADD_UPDATE_TEMPLATE = "ADD_UPDATE_TEMPLATE";
export const REMOVE_TEMPLATE = "REMOVE_TEMPLATE";

export const INIT_MASTER_TEMPLATES = "INIT_TEMPLATES";
export const ADD_MASTER_TEMPLATE = "ADD_TEMPLATE";
export const ADD_UPDATE_MASTER_TEMPLATE = "ADD_UPDATE_TEMPLATE";
export const REMOVE_MASTER_TEMPLATE = "REMOVE_TEMPLATE";

//export const CLEAR_ALL = "CLEAR_ALL";

/* NORMAL */
export const initTemplates = (templates) => {
  return { type: INIT_TEMPLATES, templates };
}

export const addTemplate = (template) => {
  return { type: ADD_TEMPLATE, template };
}

export const addUpdateTemplate = (template) => {
  return { type: ADD_UPDATE_TEMPLATE, template };
}

export const removeTemplate = (template) => {
  return { type: REMOVE_TEMPLATE, template };
}

/* MASTER */
export const initMasterTemplates = (templates) => {
  return { type: INIT_MASTER_TEMPLATES, templates };
}

export const addMasterTemplate = (template) => {
  return { type: ADD_MASTER_TEMPLATE, template };
}

export const addUpdateMasterTemplate = (template) => {
  return { type: ADD_UPDATE_MASTER_TEMPLATE, template };
}

export const removeMasterTemplate = (template) => {
  return { type: REMOVE_MASTER_TEMPLATE, template };
}



// export const clearAll = () => {
//   return { type: CLEAR_ALL };
// }

export const templateReducer = (state, action) => {
  switch (action.type) {

    case INIT_TEMPLATES:       
      return orderTemplates(action.templates); // is array

    case ADD_TEMPLATE:
      return orderTemplates([ ...state, action.template ]);

    case ADD_UPDATE_TEMPLATE:
      const templateExists = state.find((c) => {
        return c.id === action.template.id;
      });
      if (templateExists) { // update existing
        const newState = state.map((c) => {          
          return c.id === action.template.id ? 
            action.template :
            c;
        });
        return orderTemplates(newState);
      }
      return orderTemplates([ ...state, action.template ]);

    case REMOVE_TEMPLATE:
      const newState = state.filter((c) => {          
        return c.id !== action.template.id;
      });
      return orderTemplates(newState);

    // case CLEAR_ALL:
    //   return [];

    default:
      return state;
  }
}


export const masterTemplateReducer = (state, action) => {
  switch (action.type) {

    case INIT_MASTER_TEMPLATES:       
      return orderTemplates(action.templates); // is array

    case ADD_MASTER_TEMPLATE:
      return orderTemplates([ ...state, action.template ]);

    case ADD_UPDATE_MASTER_TEMPLATE:
      const templateExists = state.find((c) => {
        return c.id === action.template.id;
      });
      if (templateExists) { // update existing
        const newState = state.map((c) => {          
          return c.id === action.template.id ? 
            action.template :
            c;
        });
        return orderTemplates(newState);
      }
      return orderTemplates([ ...state, action.template ]);

    case REMOVE_MASTER_TEMPLATE:
      const newState = state.filter((c) => {          
        return c.id !== action.template.id;
      });
      return orderTemplates(newState);

    // case CLEAR_ALL:
    //   return [];

    default:
      return state;
  }
}

const TemplateProvider = (props) => {
  const [ templates, dispatch ] = useReducer(templateReducer, initState);
  const [ masterTemplates, masterDispatch ] = useReducer(masterTemplateReducer, initState);
  const templateData = { templates, dispatch, masterTemplates, masterDispatch };
  return <TemplateContext.Provider value={ templateData } { ...props } />;
}

const useTemplateContext = () => {
  return useContext(TemplateContext);
}

export { TemplateProvider, useTemplateContext };