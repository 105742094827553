import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, Grid } from 'grommet';
import { yupResolver } from '@hookform/resolvers/yup';
import { clientFilterSchema } from '../validation/validation';
import { useContextVal } from '../auth/Auth.js';
import { InputGroup } from '../ui/InputGroup';
import { SelectGroup } from '../ui/SelectGroup.js';
import { ClientFiltersStyled } from './ClientFilters.css';
import { Search, Cancel} from 'iconoir-react';

const formVals = {
  name: '',
  date_type: '',
  date_range: '',
};

const dateTypeOpts = [
  // {
  //   value: '',
  //   label: 'None'
  // },    
  {
    value: 'start_date',
    label: 'Start Date'
  },  
  {
    value: 'close_date',
    label: 'Close Date'
  },
  {
    value: 'created_at',
    label: 'Created'
  },
  {
    value: 'updated_at',
    label: 'Updated'
  },
  {
    value: 'date_paid',
    label: 'Date Paid'
  },    
];

export const ClientFilters = ({ onGet, loading, size }) => {
  const ctxt = useContextVal();
  const [ isFiltered, setIsFiltered ] = useState(false);  
  const [ dateFilter, setDateFilter ] = useState('')
  const smallSize = size === 'small' || size === 'xsmall' || size === 'xxsmall';
  const {
    control,
    register,
    handleSubmit,   
    reset,     
    formState: { errors, isDirty },
    // setError, - no errors to set for filter form...
    setValue,
    getValues,
    watch,
  } = useForm({
    mode: 'onTouched', //  onChange | onBlur | onSubmit | onTouched | all
    defaultValues: formVals,    
    resolver: yupResolver(clientFilterSchema),
    delayError: 100
  });
  const { name, needs_attention, date_type, date_range } = watch();  
  const onSubmit = async (data) => {
    if (isFiltered) {
      return resetFilters();
    }
    try {
      const url = `clients/?needs_attention=${ needs_attention ? 1 : 0 }&name=${ encodeURIComponent(name) }${dateFilter}`;
      await onGet(url);
      reset(getValues()); // new default
      setIsFiltered(true);
    } catch (err) {
      return;
    }
  };

  const resetFilters = () => {    
    reset(formVals);
    setIsFiltered(false);
  };

  useEffect(() => {
    if (isDirty && isFiltered) {
      setIsFiltered(false);
    }
  }, [ isDirty, isFiltered ] );

  useEffect(() => {
    if (date_type?.length > 0) {
      setDateFilter(date_range?.length ? 
        `&${encodeURIComponent(date_type)}=${encodeURIComponent(date_range)}` :
        ''
      );
    } else {
      setDateFilter('');
      setValue('date_range', '');
    }
  }, [ date_type, date_range, setValue ]);

  return  (
    <ClientFiltersStyled onSubmit={handleSubmit(onSubmit /*, onError */ )}>

      <Box 
        as="fieldset" 
        margin="none"
        pad="none"
        align="start"
        direction="column"        
        round="xsmall"
        disabled={ loading }
      >

        <Grid
          fill="horizontal"
          align="stretch"             
          columns={ 
            size === 'xxsmall' ? 
              ['minmax(100%, 250px)'] :
              ( size === 'small' || size === 'xsmall' ) ? 
                [ '50%', '50%' ] : 
                [ 'minmax(auto, 130px)', 'minmax(auto, 130px)', 'minmax(auto, 130px)', 'auto' ] }
          gap={ smallSize ? 'small' : 'xxsmall' }
          border={ false }
          pad="none"
        >  

          <InputGroup
            type="text"
            name="name"
            label="Client Name"
            placeholder="June Smith"
            errors={ errors }
            register={ register }
            size="small"
          />  

          <SelectGroup        
            control={ control }    
            opts={ dateTypeOpts }
            errors={ errors }  
            name="date_type"
            label="Date Type"            
            size="small"
            clear={ true }
            classNamePrefix="react-select"       
            disabled={ loading }   
            placeholder="Select..."            
          />            

          <SelectGroup        
            control={ control }    
            opts={ ctxt?.settings?.date_range_choices || [] }
            errors={ errors }  
            name="date_range"
            label="Date Range"            
            size="small"
            clear={ true }
            classNamePrefix="react-select"       
            disabled={ loading || !date_type }   
            placeholder="Select" 
            data-tip={ date_type ? null : 'Choose a Date Type first.' }
          />                  

          <Button 
            secondary             
            type="submit"
            alignSelf="end"
            size="small"              
            label={ isFiltered ? 'Clear Search' : 'Search Clients' } 
            icon={ 
              isFiltered ? 
                <Cancel height={20} width={20} /> : 
                <Search height={20} width={20} /> 
            }
            gap="xsmall"
          />               

        </Grid>     

      </Box>

    </ClientFiltersStyled>
  );
};
