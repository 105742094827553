import { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
/* ----- */
import { useAxios } from '../ajax/Ajax.js';
import { useClientContext, addClients, addUpdateClient, removeClient } from '../context/client-context.js';
import { useError } from '../errors/errors.js';
import { useLoading } from '../loading/loading.js';
import { useSuccess } from '../success/success.js';

const deleteSuccessMsg = 'The client has been successfully deleted.';
const defaultErrMsg = 'An error occurred while accessing your clients. Please refresh the page and try again.';
const postSuccessMsg = 'Your client data has been successfully updated.';

export const useClientAjax = (
  ctxt, 
  tx_id, 
  client, 
  setClient, 
  setFormErrors
) => {
  const { dispatch } = useClientContext(); 

  /* GET ALL CLIENTS -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+- */    
  const [
    { 
      data: getAllData, 
      loading: getAllLoading, 
      error: getAllError
    },
    executeGetAll
  ] = useAxios(
    {
      url: 'clients/',
      method: 'GET'
    },
    { manual: false }
  );  

  /* GET ONE CLIENT -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+- */  
  const [
    { 
      data: getOneData, 
      loading: getOneLoading, 
      error: getOneError
    },
    // executeGetOne
  ] = useAxios(
    {
      url: `clients/add/${ tx_id ? tx_id + '/' : '' }`,         
      method: 'GET'
    },
    { manual: !tx_id }
  );

  /* EDIT CLIENT -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
  const [
    { 
      data: postData, 
      loading: postLoading, 
      error: postError
    },
    executePost
  ] = useAxios(
    {
      url: client?.id ? 
        `clients/${ client.id }/` :
        `clients/add/${ client?.tx_id ? client.tx_id + '/' : '' }`,        
      method: 'POST'
    },
    { manual: true }
  );

  /* DELETE CLIENT -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
  const [
    { 
      data: deleteData, 
      loading: deleteLoading, 
      error: deleteError
    },
    executeDelete
  ] = useAxios(
    {
      // url: `clients/${ client?.id }/`,  
      method: 'DELETE'
    },
    // { manual: !client?.id }
    { manual: true }
  );

  /* AJAX STATUS -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
  const { SuccessModal, setSuccess } = useSuccess();
  const combinedLoading = getAllLoading || 
    getOneLoading || 
    deleteLoading ||
    postLoading;
  const { Loading, isLoading } = useLoading(
    combinedLoading, 
    ( deleteLoading ? 'Deleting Client...' : postLoading ? 'Saving Client...' : 'Loading Clients...' )
  );
  const { ErrModal, onError } = useError(null, defaultErrMsg, setFormErrors);

  /* FUNCTION -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
  const resetUrl = () => {
    const currentPath = window.location.pathname;    
    const newPath = currentPath.split('add/');    
    if (newPath.length > 1) {      
      window.history.pushState({}, null, newPath[0]);
    }
  };

  const onPost = async (data) => {
    try {
      // backend needs null instead of empty string
      if (data.close_date?.trim() === '') {
        data.close_date = null;
      }
      if (data.start_date?.trim() === '') {
        data.start_date = null;
      }
      await client.id ? 
        executePost({ data: { ...data, id: client.id } }) :
        executePost({ data: { ...data } });
    } catch (err) {
      return onError({ message: 'error' }); // triggers global, default error msg
    }
  };  

  const onGet = async (url) => {
    try {
      await executeGetAll({
        url: url,
      });
    } catch (err) {
      return onError({ message: 'error' }); // triggers global, default error msg
    }
  };    

  const onDelete = async (client_id) => {
    return await executeDelete({
      url: `clients/${ client_id }/`
    });
  };

  /* USE EFFECT -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+- */

  useEffect(() => {
    if (
      postData?.twilio_phone && 
      (
        !ctxt?.user?.twilio_phone ||
        !ctxt?.user?.twilio_phone?.length
      ) 
    ) {
      ctxt.updateUser({ 
        ...{ ...ctxt.user }, 
        ...{ 'twilio_phone': postData.twilio_phone } 
      });      
    }
  }, [ postData, ctxt ]);

  useEffect(() => {    
    if (
      postData?.success && 
      postData?.client
    ) {
      setClient(null);      
      dispatch(addUpdateClient(postData.client));       
      setSuccess(true, postSuccessMsg);
      // cache.clear();
      if (postData?.errors) { // only place in app where checking for errors when success:true - in case of expired CC, other billing issue
        onError(postData);
      }
    }
    postData && !postData.success && onError(postData);
  }, [ postData, dispatch, onError, setSuccess, setClient ]);

  useEffect(() => {
    if (
      getAllData?.success && 
      getAllData?.clients
    ) {
      dispatch(addClients(getAllData.clients));
    }    
    getAllData && !getAllData.success && onError(getAllData);
  }, [ getAllData, dispatch, onError ]);   

  useEffect(() => {
    if (
      getOneData?.success && 
      getOneData?.client
    ) {
      getOneData.client.is_new = true;
      setClient(getOneData.client);
      resetUrl();
    }
    getOneData && !getOneData.success && onError(getOneData);
  }, [ getOneData, setClient, onError ]);

  useEffect(() => {    
    if (
      deleteData?.success &&
      deleteData?.id &&
      !deleteLoading 
    ) {      
      setClient(null);      
      dispatch(removeClient({ id: parseInt(deleteData?.id, 10) }));
      setSuccess(true, deleteSuccessMsg);      
    }
    if ( 
      deleteData && 
      !deleteData.success 
    ) {    
      onError(deleteData);
    }
  }, [ deleteData, dispatch, deleteLoading, setClient, setSuccess, onError ]);

  useEffect(() => {
    const error = getAllError || getOneError || deleteError || postError;
    onError(error?.toJSON());
    ReactTooltip.hide();
  }, [ getAllError, getOneError, deleteError, postError, onError ]);    

  useEffect(() => {
    ReactTooltip.hide();
  }, [ isLoading ]);

  const AjaxComponents = () => {
    return (
      <>
        <Loading />
        <SuccessModal />
        <ErrModal />
      </>
    );
  };

  return { 
    AjaxComponents: AjaxComponents,
    loading: isLoading,
    onGet: onGet,
    onPost: onPost,
    onDelete: onDelete,
  };
};