import { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Button, Grid, Box, Heading, Paragraph } from 'grommet';
/* ----- */
import { clientSchema } from '../validation/validation.js';
import { InputGroup } from '../ui/InputGroup.js';
import { CloseModalButton } from '../modal/CloseModalButton.js';
import { CheckboxGroup } from '../ui/CheckboxGroup.js';
import { useModal } from '../modal/Modal.js';
import { useWarning } from '../warning/warning.js';
import { useContextVal } from '../auth/Auth.js';
import { 
  getLocalDateTime, 
  startDateTest, 
  startCloseDateDiffTest, 
  getClientName, 
  concatErrors 
} from '../utilities/util.js';
import { ClientFormStyled } from './ClientForm.css.js';
import { Check, Trash } from 'iconoir-react';

export const ClientForm = ({
  client,
  setClient,
  onPost,
  formErrors,  
  mobile,
  deleteClient,
  loading,
}) => {
  const { user, settings } = useContextVal();
  const { 
    Modal: EnableModal, 
    opModal: enableOpModal, 
    clModal: enableClModal, 
    isOpen: enableIsOpen 
  } = useModal();
  const { 
    Modal: WarnModal, 
    opModal: warnOpModal, 
    clModal: warnClModal, 
    // isOpen: warnIsOpen 
  } = useModal();
  const { 
    Modal: EditModal, 
    // opModal: editOpModal, 
    // clModal: editClModal, 
    // isOpen: editIsOpen, 
  } = useModal();  
  const clientFullName = getClientName(client);
  const disableStartDate = client?.sending_status?.length && 
    client.sending_status !== 'not-started';
  const blockActivation = client?.is_enabled === false && 
    user?.stripe_configured === false;
  const formVals = useMemo(() => {    
    return {
      first_name: client?.first_name || '',
      last_name: client?.last_name || '',

      email: client?.email || '',
      phone: client?.phone || '',

      brokerage: client?.brokerage || user?.brokerage || '',
      is_enabled: client?.is_enabled || false,
      sale_price: client?.sale_price || '',

      close_date: client?.close_date || '',
      start_date: client?.start_date || getLocalDateTime(),
      
      address: client?.address || '',
      address2: client?.address2 || '',
      city: client?.city || '',
      state: client?.state || '',
      zip_code: client?.zip_code || '',
    };
  }, [ client, user?.brokerage ]);
  const {
    control,
    // trigger,
    register,    
    handleSubmit,    
    reset,
    getFieldState,
    formState: { errors, isDirty, dirtyFields }, // !!! need dirtyFields here in order to enable getFieldState
    setError,
  } = useForm({
    mode: 'onTouched', //  onChange | onBlur | onSubmit | onTouched | all
    defaultValues: formVals,    
    resolver: yupResolver(clientSchema, { start_close_difference: settings?.start_close_difference }),
    delayError: 50,
    // criteriaMode: 'all'
  });
  // const [startDateVal, closeDateVal] = useWatch({ control, name: ['start_date','close_date'] });
  const startDateVal = useWatch({ control, name: 'start_date' });
  const { Warning: CloseWarning, setWarning: setCloseWarning } = useWarning(null);
  const { Warning: StartWarning, setWarning: setStartWarning } = useWarning(null);

  const submitClientForm = () => {
    enableClModal();
    handleSubmit(onSubmit)();
  };

  const deleteCurrClient = () => {
    deleteClient(client);
  };

  const onSubmit = async (data) => {
    // check for changed is_enabled
    if (
      getFieldState('is_enabled').isDirty && 
      !enableIsOpen
    ) {        
      return enableOpModal();
    }
    await onPost(data);
  };

  const checkStartDate = ({ target: { value }}) => {    
    const dateInPast = startDateTest(value);
    return setStartWarning(dateInPast, 'The Start Date is in the past - this will automatically be set to today if saved.');
  }

  const checkCloseDate = ({ target: { value }}) => {   
    const dateWarning = startCloseDateDiffTest(
      startDateVal,
      value, 
      settings?.start_close_difference
    );
    return setCloseWarning(dateWarning, 'Setting Close Date that far from the Start Date may result in messages being skipped during that period.');
  };

  const closeModal = () => {
    if (isDirty) {
      return warnOpModal();
    }
    setClient(null);
  };  

  const closeAllModals = () => {
    warnClModal();
    setClient(null);
  };

  useEffect(() => {
    reset(formVals);
  }, [ formVals, reset ]);

  useEffect(() => {
    for (const err in formErrors) {        
      const errStr = concatErrors(formErrors[err]);  
      if (err !== '__all__') {          
        setError(err, { type: 'manual', message: errStr });
      }
    }
  }, [ formErrors, setError ]);

  useEffect(() => {
    ReactTooltip.hide();
    setTimeout(() => {      
      ReactTooltip.rebuild();
    }, 1000);  
  }, []);

  return client ? (
    <EditModal
      initOpen={ true }
      content={    
        <>
          <ClientFormStyled 
            className="client-form" 
            onSubmit={ handleSubmit(onSubmit /*, onError */ ) }
          >
            
            <Heading 
              level="2" 
              size="small" 
              pad="large"
              color="h2"
              margin={{ vertical: 'small', horizontal: ( mobile ? 'none' : 'small') }}
            >
              { client?.is_new ? 'Add New Client' : clientFullName }
            </Heading>

            <Grid 
              as="fieldset" 
              fill="horizontal"
              margin="none"        
              rows={['auto', 'auto']}        
              columns={
                mobile ? 
                  ['1fr', '1fr'] :
                  ['1fr', '1fr', '1fr']
              }
              areas={
                mobile ?
                  [
                    ['col1', 'col2'],
                    ['col3', 'col3'],
                    ['btns', 'btns'],
                  ] :          
                  [
                    ['col1', 'col2', 'col3'],
                    ['btns', 'btns', 'btns'],
                  ]
              }
              border={ false }
              pad="none"
              disabled={ loading }
            >      

              <Box 
                margin="none"
                pad={ mobile ? { right: 'small' } : { horizontal: 'small' } }
                direction="column"        
                round="xsmall"     
                gridArea="col1"      
              >  

                <Heading 
                  level="3" 
                  size="xsmall" 
                  color="h3"
                  margin={{ vertical: 'small' }}
                >Personal Info</Heading>

                <InputGroup
                  type="text"
                  name="first_name"
                  label="First Name"
                  errors={errors}
                  register={register}
                />  

                <InputGroup
                  type="text"
                  name="last_name"
                  label="Last Name"
                  errors={errors}
                  register={register}
                />         

                <InputGroup
                  type="email"
                  name="email"
                  label="Email"
                  errors={errors}
                  register={register}
                />    

                <InputGroup
                  type="text"
                  name="phone"
                  label="Cell Phone"
                  errors={errors}
                  register={register}
                />

                <CheckboxGroup
                  control={ control }
                  name="is_enabled"
                  label="Activate Client"
                  msg={ blockActivation ? <Paragraph size="small">Please add a payment method on the <Link to="/billing">Update Credit Card page</Link> before activating clients.</Paragraph> : null }
                  disabled={ blockActivation }
                  errors={ errors }
                  register={ register }
                  tooltip="Turn on automatic messaging for this client."
                />

              </Box>        

              <Box 
                margin="none"          
                pad={ mobile ? { left: 'small' } : { horizontal: 'small' } }
                direction="column"        
                round="xsmall"
                gridArea="col2"  
              >  

                <Heading 
                  level="3" 
                  size="xsmall" 
                  color="h3"
                  margin={{ vertical: 'small' }}
                >Business Info</Heading>

                <InputGroup
                  type="text"
                  name="brokerage"
                  label="Brokerage"
                  errors={errors}
                  register={register}
                />

                <InputGroup
                  type="text"
                  name="sale_price"
                  label="Sale Price"
                  errors={ errors }
                  register={ register }
                />   

                <InputGroup
                  type="date"
                  name="close_date"
                  label="Close Date"       
                  errors={ errors }
                  register={ register }
                  onChangeCb={ checkCloseDate }
                  Warning={ <CloseWarning /> }
                />   

                <InputGroup
                  type="date"
                  name="start_date"
                  label="Start Date"
                  errors={ errors }
                  register={ register }
                  // only allow edit start date if status is "not-started"
                  disabled={ disableStartDate }
                  onChangeCb={ checkStartDate }
                  Warning={ <StartWarning /> }
                />   

              </Box>        

              <Box 
                margin="none"
                pad={ mobile ? 'none' : { horizontal: 'small' } }
                direction="column"        
                round="xsmall"
                gridArea="col3"  
              >        

                <Heading 
                  level="3" 
                  size="xsmall" 
                  color="h3"
                  margin={ mobile ? { top: 'large', bottom: 'small' } : { vertical: 'small' } }
                >Mailing Address</Heading>        

                <InputGroup
                  type="text"
                  name="address"
                  label="Address"
                  errors={ errors }
                  register={ register }
                />  

                <InputGroup
                  type="text"
                  name="address2"
                  label="Address 2"
                  errors={ errors }
                  register={ register }
                />          

                <InputGroup
                  type="text"
                  name="city"
                  label="City"
                  errors={ errors }
                  register={ register }
                />   

                <InputGroup
                  type="text"
                  name="state"
                  label="State (2 Letters)"
                  maxLength="2"
                  errors={ errors }
                  register={ register }
                />   

                <InputGroup
                  type="text"
                  name="zip_code"
                  label="Zip Code"
                  errors={ errors }
                  register={ register }
                />        
              
              </Box>

              <Box 
                margin="none"          
                pad={ mobile ? 
                  { horizontal: 'none', vertical: 'small' } : 
                  { horizontal: 'small', vertical: 'small' }           
                }
                direction="row"   
                justify="between"     
                round="xsmall"
                gridArea="btns" 
              >          
                          
                <Button 
                  secondary 
                  disabled={ !client?.id || client?.date_paid?.length > 0 }
                  size="small"
                  color="warn_btn" 
                  onClick={ deleteCurrClient }            
                  label="Delete Client"   
                  className="delete-btn"
                  gap="xsmall"
                  icon={ <Trash height={20} width={20} /> }           
                />          

                <Button 
                  primary 
                  size="small" 
                  label="Save Changes" 
                  type="submit"  
                  gap="xsmall"
                  icon={ <Check height={20} width={20} /> } 
                />

              </Box>

            </Grid>

            <EnableModal
              content={
                <>
                  <Paragraph size="small" margin={{ top: 'none', right: 'none', bottom: 'small', left: 'none' }}>{ client?.is_enabled ? client?.date_paid ? `Are you sure you want to deactivate ${ process.env.REACT_APP_SHORT_NAME } for ${ clientFullName?.length ?  clientFullName : 'this client' }? You have already paid to have messages sent to them for 8 years. Deactivating ${ process.env.REACT_APP_SHORT_NAME } for the client will discontinue all future messages to them until you reactivate this client.` : `Are you sure you want to deactivate ${ process.env.REACT_APP_SHORT_NAME } for ${ clientFullName?.length ?  clientFullName : 'this client' }? You have already paid to have messages sent to them for 8 years. Disabling ${ process.env.REACT_APP_SHORT_NAME } for the client will discontinue all future messages to them.` : client?.date_paid ? `You have already paid to activate this client previously, so there will be no additional charge to reactivate ${ clientFullName?.length ?  clientFullName : 'this client' }.` : `Are you sure you want to activate ${ process.env.REACT_APP_SHORT_NAME } for ${ clientFullName?.length ? `${ clientFullName }` : 'this client' }? Your payment method will be charged $${ user?.payment_amount } immediately after activating this client.` }</Paragraph>

                  <Box direction="row" justify="between">

                    <Button default size="small" color="cancel_btn" onClick={ enableClModal } a11yTitle="cancel activate/deactivate client" label="Cancel" />             

                    <Button primary size="small" color="submit_btn" onClick={ submitClientForm } a11yTitle="activate/deactivate client" label={ client?.is_enabled ? 'Deactivate Client' : 'Activate Client' } />

                  </Box>
                </>
              }  
            />

            <WarnModal
              content={
                <>
                  <Paragraph size="small" margin={{ top: 'none', right: 'none', bottom: 'small', left: 'none' }}>Are you sure you want to cancel? Your changes will not be saved.</Paragraph>
                  <Box direction="row" justify="between">

                    <Button default size="small" color="cancel_btn" onClick={ closeAllModals } a11yTitle="Cancel" label="Cancel" />             

                    <Button primary size="small" color="submit_btn" onClick={ warnClModal } a11yTitle="Continue Editing Client" label="Continue Editing Client" />

                  </Box>
                </>
              }
            />

          </ClientFormStyled>

          <CloseModalButton 
            closeFunc={ closeModal } 
            disabled={ loading }                 
          />

        </>
      }
    />
  ) : null;
};