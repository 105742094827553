import { Button } from 'grommet';
import { EyeEmpty, EyeOff } from 'iconoir-react';
import styled from 'styled-components';

const ShowPwButtonStyled = styled(Button)`
  position: absolute;
  top: 26px;
  right: 6px;
  background: #fff;
  border-radius: 3px;
`;

export const ShowPwButton = ({ setShowPw, showPw }) => {
  const togglePw = () => {
    setShowPw(show => !show);
  };
  return (
    <ShowPwButtonStyled 
      plain              
      data-tip={ `${ showPw ? 'Hide' : 'Show' } password.` }
      className="toggle-pw-btn"
      size="small" 
      onClick={ togglePw }         
      a11yTitle={`${ showPw ? 'hide' : 'show'} password`}
      icon={ showPw ? <EyeEmpty height={20} width={20} /> : <EyeOff height={20} width={20} /> }              
      margin="none"     
      tabIndex="-1"       
    />
  );
};