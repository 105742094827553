import styled from 'styled-components';
import { normalizeColor } from 'grommet/utils';
import { Nav } from 'grommet';

export const TopNavStyled = styled(Nav)`
  .top-nav-info {        
    font-size: 1.2rem;
    color: ${({ theme }) => normalizeColor('cyan10', theme)};
  }

  /* media queries -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
  @media (max-width: ${props => props.theme.global.breakpoints.xsmall.value}px) { 
    .top-nav-info {
      display: none;
    }
  }
`;