import { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Button, Grid, Box, Heading } from 'grommet';
import { Undo } from 'iconoir-react';

import { useAxios /*, cache */ } from '../ajax/Ajax.js';
import { useTemplateContext, addUpdateTemplate } from '../context/template-context.js';
import { templateSchema } from '../validation/validation.js';
import { CheckboxGroup } from '../ui/CheckboxGroup.js';
import { TextareaGroup } from '../ui/TextareaGroup.js';
import { useError } from '../errors/errors.js';
import { useLoading } from '../loading/loading.js';
import { useImgPreview } from '../archie_card/ArchieCard.js';
import { TemplateTags } from './TemplateTags.js';
import { TemplateFormStyled } from './TemplateForm.css.js';
import { Check } from 'iconoir-react';

const defaultErrMsg = 'An error occurred while updating your message template. Please refresh the page and try again.';
const successMsg = 'Your template has been successfully updated.';

export const TemplateForm = ({ 
  activeTemplate: template,
  setActiveTemplate,
  setLoading,
  setSuccess,
  closeModal,
  ctxt
}) => {  
  const { dispatch } = useTemplateContext();  
  const [ open, setOpen ] = useState(false);  
  const templateTags = ctxt?.settings?.template_tags || [];
  const formVals = useMemo(() => {     
    return {
      text: template.text && template.text.length ? template.text : '',      
      is_enabled: template?.is_enabled || false,
      send_followup: template?.send_followup || false,
      notify_when_sent: template?.notify_when_sent || false,
    };
  }, [ template ]);
  const {  
    control,  
    register,
    resetField,
    handleSubmit,    
    reset,
    formState: { errors, dirtyFields },
    setError,
    watch,
  } = useForm({
    mode: 'onTouched', //  onChange | onBlur | onSubmit | onTouched | all
    defaultValues: formVals,
    resolver: yupResolver(templateSchema),
    delayError: 250
  });
  const [
    { 
      data: postData, 
      loading: postLoading, 
      error: postError
    },
    executePost
  ] = useAxios(
    {
      url: `templates/${template.id}/`,
      method: 'POST'
    },
    { manual: true }
  );

  const { ImgPreviewModal, setShowPreview } = useImgPreview({ 
    ...watch(), 
    ...{ master: false, image: template?.image } 
  });
  const { Loading } = useLoading(postLoading, 'Saving template...');
  const { ErrModal, onError } = useError(setError, defaultErrMsg);

  const onSubmit = async (data) => {
    try {
      await executePost({ data: { ...data } });
    } catch (err) {
      return onError({ message: 'error' }); // triggers global, default error msg
    }      
  };

  const resetTemplate = () => {    
    resetField('text', { defaultValue: template.default_text });
  };

  const toggleTags = () => {
    setOpen(!open);
  };  

  const previewImg = () => {
    setShowPreview(true);
  };  

  useEffect(() => {    
    if (
      postData?.success && 
      postData?.template
    ) {      
      setSuccess(true, successMsg);
      dispatch(addUpdateTemplate(postData.template));
      setTimeout(() => {
        closeModal();
      }, 250);
      // cache.clear();  
    }
    if ( 
      postData && 
      !postData.success 
    ) {    
      onError(postData);
    }
  }, [ postData, dispatch, onError, setSuccess, closeModal ]);      

  useEffect(() => {
    reset(formVals);
  }, [ reset, formVals ]);

  useEffect(() => {    
    onError(postError?.toJSON());
  }, [ postError, onError ]);  

  useEffect(() => {
    setLoading(postLoading);
  }, [ postLoading, setLoading ]);

  return (
    <TemplateFormStyled 
      className="template-form" 
      onSubmit={handleSubmit(onSubmit /*, onError */ )} 
      data-id={ template.id }
    >               
                
      <Grid         
        as="fieldset"
        margin="none"
        fill={true}
        rows={['auto', 'auto']}        
        columns={['50%', '50%']}        
        areas={[
          ['h3', 'h3'],
          ['h2', 'h2'], 
          ['tags', 'tags'],          
          ['textarea', 'textarea'],
          ['extra', 'extra'],
          ['btns', 'btns'],
        ]}
        border={ false }
        pad="none"
        disabled={ postLoading }
      >        

        <Heading 
          level="3" 
          size="xsmall"          
          gridArea="h3"
          color="h3"
          margin={{ top: 'small', right: 'none', bottom: 'xsmall', left: 'none' }}
        >          
          Year #{ ( parseInt(template.year_number) + 1 ) }
        </Heading>             

        <Heading 
          level="2" 
          size="small"          
          color="h2"
          gridArea="h2"
          margin={{ top: 'none', right: 'none', bottom: 'small', left: 'none' }}
        >
          { template.name || 'New Template' }
        </Heading>      

        <TemplateTags tags={ templateTags } gridArea="tags" open={ open } master={ false } />

        <Box           
          gridArea="textarea"
          pad={{ top: 'small', right: 'none', bottom: 'xsmall', left: 'none' }}
        >

          <TextareaGroup
            maxLength={ template?.type === 'sms' ? '300' : '450' }
            name="text"
            label={ template?.type === 'sms' ? 'Text Message' : 'Archie Card Message' }
            errors={ errors }
            register={ register }
          />

          <Box 
            className="textarea-btns"
            direction="row"
            align="center"
            justify="end"
          >

            <Button 
              plain
              className="reset-template"     
              data-tip="Reset template text."
              a11yTitle="Reset Template Text"
              icon={ <Undo height={20} width={20} /> } 
              alignSelf="end"
              tabIndex="-1"
              onClick={ resetTemplate } 
              disabled={ template.is_default && !dirtyFields.text }
            />  

            <Button 
              default 
              size="small" 
              plain={ true }
              margin={{ left: 'xsmall' }}
              className="toggle-tags-btn"
              onClick={ toggleTags } 
              a11yTitle="show / hide template tags" 
              label={`${ open ? 'Hide' : 'Show' } Template Tags`}         
            />             

          </Box> 

        </Box>

        <Box           
          gridArea="extra"
          pad={{ top: 'none', right: 'none', bottom: 'none', left: 'none' }}
          justify="start"
          align="start"
          className="template-chk"
        >

          <CheckboxGroup
            name="is_enabled"
            label="Active" 
            control={ control }
            errors={ errors }
            register={ register }
          />   

          <CheckboxGroup
            name="notify_when_sent"
            label="Notify me when message has been sent" 
            control={ control }
            errors={ errors }
            register={ register }
          />             

        </Box>

        <Box           
          gridArea="btns"
          pad={{ top: `${ template?.type === 'postcard' ? 'small' : 'none' }` }}
          direction="row"
          justify="between"
          align="end"
          className={ `${ template?.type === 'postcard' ? '' : 'bottom-ui'}` }
        >               

          { template?.type === 'postcard' ?
            <Button 
              default 
              size="small" 
              disabled={ template?.type !== 'postcard' }
              onClick={ previewImg } 
              a11yTitle="preview Archie card" 
              label="Preview Archie Card"              
            /> :
            <CheckboxGroup
              name="send_followup"
              label="Send follow up email if no reply" 
              control={ control }
              errors={ errors }
              register={ register }
              disabled={ template?.type !== 'sms' }
              tooltip="Send a follow up “In case you missed it” email <br/>to client if no reply received within 5 days."
            /> 
          }             

          <Button 
            primary 
            type="submit"
            size="small"
            label="Save Changes"
            alignSelf="end"
            gap="xsmall"
            icon={ <Check height={20} width={20} /> } 
          />

        </Box>

      </Grid>

      <ErrModal />

      <Loading />

      <ImgPreviewModal />

    </TemplateFormStyled>
  );
};