import { Link } from 'react-router-dom';
import { Box, Text } from 'grommet';
import { CheckCircledOutline } from 'iconoir-react';

export const ClientToDo = ({ user }) => {
  return (
    <>

      <Text size="small" color="white" weight={ 500 } margin={{ top: 'xsmall' }}>To activate clients (and messages), you'll first need to:</Text>

      <Box direction="row" align="center" margin={{ top: 'small' }}>
        { user?.stripe_configured !== true ?
          <>&bull;<Text size="small" color="white" weight={ 500 } margin={{ left: 'xsmall' }}><Link to="/billing">Add your credit card info&nbsp;&rarr;</Link></Text></> :
          <>&bull;<Text size="small" color="white" weight={ 500 } margin={{ left: 'xsmall' }} style={{ textDecoration: 'line-through' }}>Add your credit card info&nbsp;&rarr;</Text><CheckCircledOutline height={20} width={20} style={{ marginLeft: '1rem' }} /></>
        }
      </Box>

      <Box direction="row" align="center" margin={{ top: 'small' }}>
        { user?.return_address_configured !== true ?
          <>&bull;<Text size="small" color="white" weight={ 500 } margin={{ left: 'xsmall' }}><Link to="/profile">Add your return address&nbsp;&rarr;</Link></Text></> :
          <>&bull;<Text size="small" color="white" weight={ 500 } margin={{ left: 'xsmall' }} style={{ textDecoration: 'line-through' }}>Add your return address&nbsp;&rarr;</Text><CheckCircledOutline height={20} width={20} style={{ marginLeft: '1rem' }} /></>
        }
      </Box>      

    </>
  );
};