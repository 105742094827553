import { Text } from 'grommet';
import { FooterStyled } from './Footer.css';

export const Footer = ({ noSidebarPath }) => {
  return  (
    <FooterStyled
      gridArea="footer"
      align="center"
      direction="row"
      justify="start"
      background="white"
      height="5rem"
      pad={{ left: 'small', right: 'xsmall' }}
      className={ noSidebarPath ? 'no-sidebar-footer' : '' }
    >
      <Text size="small" color="teal_text_2">&copy; {new Date().getFullYear()} <strong>Archie</strong><br/> <span className="gray-footer-text">by Paperless Pipeline</span></Text>
    </FooterStyled>
  )
};