import styled from 'styled-components';
import { Box } from 'grommet';

export const ModalStyled = styled(Box)`
  overflow-y: auto;  
  .close-modal-btn {
    position: absolute;
    border: none;
    padding: 0;
    top: ${props => props.theme.global.edgeSize.small};
    right: ${props => props.theme.global.edgeSize.small};    
  }
  .close-modal-btn:hover {
    background: transparent;
    box-shadow: inset 0 0 0 0.1rem ${props => props.theme.global.colors['status-critical']};
  }
  .close-modal-btn svg {
    color: ${props => props.theme.global.colors['sage8']};
  }
  .close-modal-btn:hover svg {
    color: ${props => props.theme.global.colors['status-critical']};
  }  
  .error-msg {
    max-width: 50rem;
    box-shadow: 0 0 0 0.2rem  ${props => props.theme.global.colors['status-critical']};      
    color:  ${props => props.theme.global.colors['status-critical']};
  }
  .error-msg svg {
    flex: 0 0 auto;
  }
`;