// import { Heading } from 'grommet';
import { AuditLogList } from './AuditLogList.js';
import { AuditLogStyled } from './AuditLog.css.js';

export const AuditLog = () => {
  return  (
    <AuditLogStyled 
      forwardedAs="section"
      width="100%"      
    >
      <AuditLogList />
    </AuditLogStyled>
  )
};