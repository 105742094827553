import styled from 'styled-components';
import { Box } from 'grommet';
import { TableStyled } from '../shared/table.css.js';

export const ClientLogListStyled = styled(Box)`
  ${ TableStyled }
  h1 strong {
    color: ${({ theme }) => theme.global.colors['sage9']};
  }
  .pagination {
    flex: 0 0 auto;    
  }
  .pagination button svg {
    height: 20px;
    width: 20px;
  }
  .log-col {
    overflow: hidden;
    flex: 0 0 auto;
  }
  .client-header {
    display: flex;
    align-items: flex-end;
    line-height: 1;

  }
  .client-header svg {
    stroke-width: 1px;
    color: ${({ theme }) => theme.global.colors.blue11 };   
    margin-right: ${({ theme }) => theme.global.edgeSize.xsmall};       
  }
  .client-header-text {
    font-weight: normal;
    margin-right: ${({ theme }) => theme.global.edgeSize.small};
    color: ${({ theme }) => theme.global.colors['sage10']};
    font-size: ${({ theme }) => theme.text.small.size };    
    line-height: ${({ theme }) => theme.text.small.height }; 
  }
  .client-header-text a {
    font-weight: 500;
    font-size: ${({ theme }) => theme.text.large.size };    
    line-height: ${({ theme }) => theme.text.large.height };    
  }
  .client-header-text strong {
    font-weight: normal;
    color: ${({ theme }) => theme.global.colors.sage12 };   
  }
  .cell-client a {
    font-weight: inherit;
    font-size: ${({ theme }) => theme.text.small.size };    
    line-height: ${({ theme }) => theme.text.small.height };
  }
  .log-msg-link {
    color: inherit;
  }

  /* media queries -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
  @media (max-width: ${props => props.theme.global.breakpoints.medium.value}px) { 
    .template-start-cell,
    .template-close-cell {
      display: none;
    }
  }
  @media (max-width: ${props => props.theme.global.breakpoints.xsmall.value}px) { 
    .client-header {
      flex-flow: column nowrap;
      align-items: flex-start;      
    }
    .client-header-text a {      
      font-size: ${({ theme }) => theme.text.small.size };    
      line-height: ${({ theme }) => theme.text.small.height };    
    }    
    .client-header svg,
    .template-type-cell,
    .template-desc-cell,
    .all-clients-view .template-scheduled-cell {
      display: none;
    }
  }  
  @media (max-width: ${props => props.theme.global.breakpoints.xxsmall.value}px) { 
    .table-top-ui-wrap {
      flex-flow: column nowrap;
    }
    .pagination {
      order: -1;
      margin-bottom: ${({ theme }) => theme.global.edgeSize.small};
    }
  }
`;