import { Box, Text } from 'grommet';
import TextareaAutosize from 'react-textarea-autosize';
import { formErr /*, onError */ } from '../errors/errors.js';

// const MIN_TEXTAREA_HEIGHT = 32;

export const TextareaGroup = ({ name, label, register, errors, minrows = 3, ...rest }) => {
  return (
    <>
      <Box         
        className="textarea-wrap"        
        direction="column"
      >
        <Text as="label" size="small">{ label }</Text>
        <TextareaAutosize         
          // maxRows={ 25 }  
          // onHeightChange={}
          minRows={ minrows }
          autoComplete="off" 
          resize="vertical"
          { ...rest }  
          { ...register(name) }   
        />
      </Box>  

      { formErr(errors, name) }
    </>
  )
};