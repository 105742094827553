import React, { createContext, useReducer, useContext } from 'react';

const initState = [];

const orderClients = (state) => {
  return state.sort((client1, client2) => { 
    const date1 = Date.parse(client1.created_at);
    const date2 = Date.parse(client2.created_at);
    if (date1 === date2 || !date1 || !date2) {
      return 0;
    }
    // recently created first
    return date1 > date2 ? -1 : 1;
  });
};

export const ClientContext = createContext();

export const ADD_CLIENTS = "ADD_CLIENTS";
export const ADD_UPDATE_CLIENT = "ADD_UPDATE_CLIENT";
export const REMOVE_CLIENT = "REMOVE_CLIENT";
export const CLEAR_ALL = "CLEAR_ALL";

export const addClients = (clients) => {
  return { type: ADD_CLIENTS, clients };
}

export const addUpdateClient = (client) => {
  return { type: ADD_UPDATE_CLIENT, client };
}

export const removeClient = (client) => {
  return { type: REMOVE_CLIENT, client };
}

export const clearAll = () => {
  return { type: CLEAR_ALL };
}

export const clientReducer = (state, action) => {
  switch (action.type) {

    case ADD_CLIENTS: // no check if clients exist already in state
      return orderClients(action.clients);

    case ADD_UPDATE_CLIENT:
      const clientExists = state.find((c) => {
        return c.id === action.client.id;
      });
      if (clientExists) { // update existing
        const newState = state.map((c) => {          
          return c.id === action.client.id ? 
            action.client :
            c;
        });
        return orderClients(newState);
      }
      return orderClients([ ...state, action.client ]);

    case REMOVE_CLIENT:     
      const newState = state.filter((c) => {                
        return c.id !== action.client.id;
      });
      return orderClients(newState);

    case CLEAR_ALL:
      return [];
      
    default:
      return state;
  }
}

const ClientProvider = (props) => {
  const [ clients, dispatch ] = useReducer(clientReducer, initState);
  const clientData = { clients, dispatch };
  return <ClientContext.Provider value={ clientData } { ...props } />;
};

const useClientContext = () => {
  return useContext(ClientContext);
};

export { ClientProvider, useClientContext };