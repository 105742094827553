import { useState, useEffect, useCallback } from 'react';
import { Box, Text, Layer } from 'grommet';

const SHOW_SUCCESS_TIMER = 3000;

export const useSuccess = (
  successMsg = 'Your request was successful.'
) => {
  const [ success, setSuccess ] = useState(false);
  const [ msg, setMsg ] = useState(null);

  const showSuccessMessage = useCallback((set, setText = null) => {
    if (setText) {
      setMsg(setText);
    }
    setSuccess(set);
  }, []);

  const closeSuccess = useCallback(() => {
    setMsg(null);
    setSuccess(false);
  }, []);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        closeSuccess();
      }, SHOW_SUCCESS_TIMER);
    }
  }, [ success, closeSuccess ]);  

  const SuccessModal = useCallback(() => {
    return success &&
      <Layer
        position="top"
        modal={ false }
        margin={{ vertical: 'medium', horizontal: 'medium' }}
        onEsc={ closeSuccess }
        onClickOutside={ closeSuccess }
        responsive={ false }
        plain
      >
        <Box 
          size="small"
          pad={{ vertical: 'xsmall', horizontal: 'small' }}
          margin={{ top: '-1rem', bottom: '1rem'}}
          round="xxsmall"
          background="status-ok"
        >
          <Text
            dangerouslySetInnerHTML={{ __html: msg || successMsg }} 
            a11yTitle={ msg || successMsg }
            size="small"
            wordBreak="break-all"
            color="white"
            corner="xsmall"
          />
        </Box>
      </Layer>;
  }, [ success, closeSuccess, msg, successMsg ]);

  return { 
    SuccessModal: SuccessModal,
    setSuccess: showSuccessMessage 
  };
};