import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Paragraph } from 'grommet';

import { changeSchema, resetSchema } from '../validation/validation.js';
import { useAxios } from '../ajax/Ajax.js';
import { InputGroup } from '../ui/InputGroup.js';
import { useError } from '../errors/errors.js';
import { useModal } from '../modal/Modal.js';
import { useLoading } from '../loading/loading.js';
import { ShowPwButton } from '../ui/ShowPwButton.js';
import { ResetPasswordFormStyled } from './ResetPasswordForm.css.js';

const defaultResetErrMsg = 'An error occurred while resetting your password. Please refresh the page and try again.';
const defaultChangeErrMsg = 'An error occurred while changing your password. Please refresh the page and try again.';
const invalidLinkErrMsg = <>This password reset link is invalid, probably because it has already been used. Please <Link to="/resetpw">request a new password reset</Link>.</>;

const defaultVals = {
  email: '',
}

// const defaultValsTest = {
//   email: 'kristiankeane@gmail.com',
// }

export const ResetPasswordForm = () => {
  const { Modal, opModal } = useModal();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setFocus,
  } = useForm({
    mode: 'onTouched', //  onChange | onBlur | onSubmit | onTouched | all
    defaultValues: defaultVals,
    resolver: yupResolver(resetSchema),
    delayError: 250
  });    
  const [
    { 
      data: postData, 
      loading: postLoading, 
      error: postError
    },
    executePost
  ] = useAxios(
    {
      url: 'resetpw/',
      method: 'POST'
    },
    { manual: true }
  );

  const { ErrModal, onError } = useError(setError, defaultResetErrMsg);
  const { Loading } = useLoading(
    postLoading, 
    'Loading...'
  );   

  const onSubmit = async (data) => {
    try {
      await executePost({ data: data });
    } catch (err) {
      return onError({ message: 'error' }); // triggers global, default error msg
    }    
  };

  useEffect(() => {
    postData?.success && opModal();
    if ( 
      postData && 
      !postData.success 
    ) {    
      onError(postData);
    }
  }, [ postData, opModal, onError ]);   

  useEffect(() => {    
    onError(postError?.toJSON());
  }, [ postError, onError ]);  

  useEffect(() => {
    setFocus('email');
  }, [setFocus]);

  return  (
    <ResetPasswordFormStyled onSubmit={handleSubmit(onSubmit /*, onError */ )}>

      <Box 
        as="fieldset"        
        pad="medium"
        margin="none"
        round="small"        
        border={{
          color: 'light_border_login',
          size: 'xsmall',
          style: 'solid',
          side: 'all'
        }}       
        disabled={ postLoading }
      >

        <Box
          pad={{ vertical: "xsmall", horizontal: 'small' }}
          round="xsmall"
          margin={{ bottom: 'small' }}
          background="brand_light"
        >
          <Paragraph size="small" margin="none">
            Enter your email here and click Reset Password - you'll then be emailed a link that will allow you to change &amp; reset your current password.
          </Paragraph>
        </Box>      

        <InputGroup
          type="email"
          name="email"
          label="Email"
          errors={errors}
          register={register}
        />        

        <Button 
          primary 
          size="small" 
          label="Reset Password" 
          type="submit" 
          margin={{ top: 'xsmall' }}           
        />

      </Box>      

      <Modal 
        content={
          <Paragraph size="small" className="ui-msg-xxx success-msg-xxx">You'll receive an email shortly containing a special link to change your password. <Link to="/login">Log In here &rarr;</Link></Paragraph>
        }
      />

      <Loading />

      <ErrModal />

    </ResetPasswordFormStyled>
  )
};

/* -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+- */

export const ChangePasswordForm = () => {
  const { sub_id = '_', id = '_' } = useParams();
  const [ showPw, setShowPw ] = useState(false);
  const { Modal, opModal } = useModal();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setFocus,
  } = useForm({
    mode: 'onTouched', //  onChange | onBlur | onSubmit | onTouched | all
    defaultValues: { password: '', confirm_password: ''},
    resolver: yupResolver(changeSchema),
    delayError: 250
  });  
  const [
    { 
      data: postData, 
      loading: postLoading, 
      error: postError
    },
    executePost
  ] = useAxios(
    {
      url: `changepw/${sub_id}/${id}/`,
      method: 'POST'
    },
    { manual: true }
  );
  const [
    { 
      data: getData, 
      loading: getLoading, 
      error: getError
    }
  ] = useAxios(
    {
      url: `changepw/${sub_id}/${id}/`,
      method: 'GET'
    },
    { manual: !sub_id || !id }
  );  

  const { ErrModal, onError } = useError(setError, defaultChangeErrMsg);
  const { Loading } = useLoading(
    postLoading, 
    'Loading...'
  );     

  const onSubmit = async (data) => {
    try {
      await executePost({ data: data });
    } catch (err) {
      return onError({ message: 'error' }); // triggers global, default error msg
    }      
  };  

  useEffect(() => {
    if (!sub_id || !id) {
      onError(invalidLinkErrMsg);
    }
  }, [ sub_id, id, onError ]);

  useEffect(() => {
    postData?.success && opModal();      
    postData && !postData.success && onError(postData);    
  }, [ postData, opModal, onError ]); 

  useEffect(() => {
    getData && !getData.success && onError(invalidLinkErrMsg);
  }, [ getData, onError ]);     

  useEffect(() => {
    const error = postError ? 
      postError?.toJSON() : 
      getError?.toJSON();
    onError(error);
  }, [ postError, getError, onError ]);    

  useEffect(() => {
    setFocus('password');
  }, [ setFocus ]);

  return  (
    <ResetPasswordFormStyled onSubmit={handleSubmit(onSubmit /*, onError */ )}>

      <Box 
        as="fieldset"        
        pad="medium"
        margin="none"
        round="small"
        border={{
          color: 'light_border_login',
          size: 'xsmall',
          style: 'solid',
          side: 'all'
        }}       
        disabled={ getLoading || postLoading }
      >      

        <Box
          pad={{ vertical: "xsmall", horizontal: 'small' }}
          round="xsmall"
          margin={{ bottom: 'small' }}
          background="brand_light"
        >
          <Paragraph size="small" margin="none">
            Enter &amp; confirm your new password.
          </Paragraph>
        </Box> 

        <InputGroup
          type={ showPw ? 'text' : 'password' }
          name="password"
          label="Password"
          autocomplete="new-password"
          errors={ errors }
          register={ register }
          InputButton={ <ShowPwButton showPw={ showPw } setShowPw={ setShowPw } /> }
        />  

        <InputGroup          
          type={ showPw ? 'text' : 'password' }
          name="confirm_password"
          label="Confirm New Password"
          autocomplete="new-password"
          errors={ errors }
          register={ register }
          InputButton={ <ShowPwButton showPw={ showPw } setShowPw={ setShowPw } /> }
        />  

        <Button 
          primary 
          size="small" 
          label="Change Password" 
          type="submit" 
          margin={{ top: 'xsmall' }}           
        />

      </Box>

      <Modal 
        content={
          <Paragraph size="small" className="ui-msg-xxx success-msg-xxx">Your password has been reset. Please <Link to="/login">Log In</Link>.</Paragraph>
        }
      />

      <ErrModal />

      <Loading />

    </ResetPasswordFormStyled>
  )
};