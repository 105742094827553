import { Heading } from 'grommet';
import { useContextVal } from '../auth/Auth';
import { BillingForm } from './BillingForm';
import { BillingStyled } from './Billing.css';

export const Billing = () => {
  const ctxt = useContextVal();
  return  (
    <BillingStyled 
      forwardedAs="section"      
    >
      <Heading 
        level="1" 
        size="small" 
        color="h1"
        margin={{ vertical: 'small' }}
      >
        { ctxt?.user?.stripe_configured === false ? 'Add' : 'Update' } Credit Card
      </Heading>
      <BillingForm />
    </BillingStyled>
  )
};