import styled from 'styled-components';
import { Box } from 'grommet';
import { TableStyled } from '../shared/table.css.js';

export const AuditLogListStyled = styled(Box)`
  ${ TableStyled }
  .note-text {
    /* line-height: 23px;
    max-width: 100%;
    white-space: pre-line;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis; */
  }
  .pagination,
  .filter-wrap {
    flex: 0 0 auto;
    /* background: orange; */
  }
  .pagination button svg {
    height: 20px;
    width: 20px;
  }
  .log-col {
    overflow: hidden;
    flex: 0 0 auto;
  }
  .row-details {
    width: 100%;
    max-height: 0;
    overflow: hidden;
  }
  .row-details-show {
    max-height: unset;
  }
  /* media queries -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
  /* @media (max-width: ${props => props.theme.global.breakpoints.small.value}px) { } */
`;