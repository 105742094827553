import { useMemo, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { clientLogFiltersSchema } from '../validation/validation.js';
import { SelectGroup } from '../ui/SelectGroup.js';
import { ClientLogFiltersStyled } from './ClientLogFilters.css.js';

const opts = [
  { value: 'pending', label: 'Pending' },
  { value: 'sent', label: 'Sent' },
];

export const ClientLogFilters = ({ 
  view,
  getLoading,
}) => {    
  const [ searchParams ] = useSearchParams();
  const navigate = useNavigate();
  const formVals = useMemo(() => { 
    return {
      client_message_filter: view?.length ? view : 'pending',
    };
  }, [ view ])
  const {
    setValue,
    control,     
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    defaultValues: formVals,    
    resolver: yupResolver(clientLogFiltersSchema),
    delayError: 100
  });
  
  const setFilters = (val) => {     
    navigate({
      pathname: `/client-messages/${ val.value }/`,
      search: searchParams.toString(),
    });
  };

  useEffect(() => {
    setValue('client_message_filter', view);
  }, [ view, setValue ]);

  return  (
    <ClientLogFiltersStyled>

      <SelectGroup          
        control={ control }
        opts={ opts }
        name="client_message_filter"
        label="Filter Client Messages"
        errors={ errors }  
        size="small"
        clear={ false }
        classNamePrefix="react-select"       
        disabled={ getLoading }
        onChangeCb={ setFilters }
      />    
  
    </ClientLogFiltersStyled>
  );
};