import { Heading } from 'grommet';
import { ClientList } from './ClientList';
import { ClientsStyled } from './Clients.css';

export const Clients = () => {
  return  (
    <ClientsStyled 
      forwardedAs="section"      
    >
      <Heading 
        level="1" 
        size="small" 
        color="h1"
        margin={{ vertical: 'small' }}
      >
        Manage Clients
      </Heading>
      <ClientList />
    </ClientsStyled>
  );
};