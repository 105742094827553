import { Link } from 'react-router-dom';
import { Image } from 'grommet'; 
import { HeaderStyled } from './Header.css';
import { TopNav } from '../nav/TopNav';
// import { ReactComponent as PPLogo } from '../../svg/pp-logo.svg';
import headerImg1x from '../../img/archie-header-img@1x.png';
import headerImg2x from '../../img/archie-header-img@2x.png';

export const Header = () => {
  return  (
    <HeaderStyled
      height="5rem"
      background="#ffffff"
      gridArea="header"
      align="center"      
      direction="row"
      justify="between"
      pad={{ left: 'small', right: 'medium' }}
    >

      <Link to="/" className="pp-logo">
        <Image
          srcSet={ `${headerImg1x}, ${headerImg2x} 1.5x, ${headerImg2x} 2x` }
          src={ headerImg1x }
          alt={ `${ process.env.REACT_APP_NAME } logo` }
        />        
      </Link>

      <TopNav />

    </HeaderStyled>
  )
};