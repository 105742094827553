import { Heading } from 'grommet';
import { TemplateList } from './TemplateList';
import { TemplatesStyled } from './Templates.css';

export const TemplatesMaster = () => {
  return  (
    <TemplatesStyled
      forwardedAs="section"
      width="100%"      
    >
      <Heading 
        level="1" 
        size="small" 
        color="h1"
        margin={{ vertical: 'small' }}
      >
        Master Templates
      </Heading>
      <TemplateList master={ true } />
    </TemplatesStyled>
  )
};