/* DATE UTILITIES -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
export const getLocalDateTime = (date = null) => {
  let newDate = date ? new Date(date) : new Date();
  const offset = newDate.getTimezoneOffset();
  newDate = new Date(newDate.getTime() - (offset*60*1000))
  return newDate.toISOString().split('T')[0]
};

const formatTime = (dateObj) => {
  const hours = dateObj.getHours();
  const formatHours = ((hours + 11) % 12 + 1);
  const min = dateObj.getMinutes().toString().padStart(2, '0');
  // const localeTest = dateObj.toLocaleTimeString('en-US', { timeStyle: 'short' });
  return `${ formatHours }:${ min } ${ hours >= 12 ? 'PM' : 'AM' }`;
};

export const formatDate = (date, includeTime = false) => {
  // if no time, add time: T00:00:00 - fixes issues with timezone where "2022-03-01" would be parsed as "2/28/22"
  if (!date.split('T')[1]) {
    date = date + 'T00:00:00';
  }

  const dateObj = new Date(date);
  const time =  includeTime ? formatTime(dateObj) : null;
  // const time = includeTime ? dateObj.toLocaleTimeString('en-US', { timeStyle: 'short' }) : null;  
  // const offsetDate = new Date( dateObj.getTime() - dateObj.getTimezoneOffset() * -60000 );

  const year = dateObj.getFullYear().toString().substr(-2);
  const month = (1 + dateObj.getMonth()).toString().padStart(2, '0');
  const day = dateObj.getDate().toString().padStart(2, '0');

  // console.log('-------------------')
  // var date1 = '2022-03-01T00:00:00';
  // var date1_iso = new Date(date1).toISOString();
  // var date1_local = new Date(date1).toLocaleDateString('en-US', { dateStyle: 'short' })
  // var date2 = '03-01-2022'; 
  // var date3 = '2022-03-01T19:49:31';

  // console.log(new Date(date1));
  // console.log(date1_iso);
  // console.log(date1_local);
  // console.log(new Date(date2));
  // console.log(new Date(date3));

  // var test = `${month}/${day}/${year}`;
  // console.log(date)
  // console.log(dateObj);
  // console.log(test);

  return includeTime ? 
    `${month}/${day}/${year}, ${time}` : 
    `${month}/${day}/${year}`;
};

export const dateIsInThePast = (d1, d2) => {
  return d1.getFullYear() >= d2.getFullYear() &&
    d1.getDate() > d2.getDate() && // same day is ok
    d1.getMonth() >= d2.getMonth();
};

export const getTs = () => {
  return new Date().getTime();
};

export const startDateTest = (date) => {
  if (!date || date === undefined) {     
    return false;
  }
  const today = new Date();    
  today.setHours(0,0,0,0);
  const startDate = new Date(date + 'T00:00:00');
  startDate.setHours(0,0,0,0);
  return dateIsInThePast(today, startDate) ? true : false;
};

export const startCloseDateDiffTest = (
  start_date, 
  close_date, 
  start_close_difference
) => {
  if (
    !start_date ||
    !close_date ||
    !start_close_difference
  ) {           
    return false;
  }  
  const startDate = new Date(start_date + 'T00:00:00');
  startDate.setHours(0,0,0,0);    
  const closeDate = new Date(close_date + 'T00:00:00');    
  closeDate.setHours(0,0,0,0);
  const diff = startDate.getTime() - closeDate.getTime();
  const diffToDays = Math.abs( Math.round( diff / (1000 * 3600 * 24) ) );  
  return start_close_difference < diffToDays ? true : false;
};

/* LOCALSTORAGE UTILITIES -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
export const saveToLs = (key, val) => {
  localStorage.setItem(key, JSON.stringify(val));
};

export const getFromLs = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

/* ETC UTILITIES -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
export const isBool = (variable) => {
  return variable === true || variable === false;
};

export const getClientParams = (client, clientName) => {
  if (!client) {
    return '';
  }
  return `?client_id=${ client?.id ? client.id : '' }${ clientName ? `&client_name=${ encodeURIComponent(clientName) }` : '' }${ client?.start_date ? `&client_start_date=${ encodeURIComponent(client.start_date) }` : '' }${ client?.close_date ? `&client_close_date=${ encodeURIComponent(client.close_date) }` : '' }`;
};

export const getClientName = (client) => {
  return `${ client?.first_name ? ( client?.first_name + ' ' ) : '' }${ client?.last_name || '' }`;
};

export const concatErrors = (errArr) => {
  const errStr = errArr.reduce((acc, err) => {
    return `${acc}${acc.length ? ' ' : ''}${err}`;
  }, '');      
  return errStr;
};
