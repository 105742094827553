import styled from 'styled-components';
import { Box } from 'grommet';
import { TableStyled } from '../shared/table.css.js';

export const ClientListStyled = styled(Box)`
  ${ TableStyled }

  .delete-client-btn {    
    background: ${({ theme }) => theme.global.colors['status-critical'] }; 
    border-radius: ${({ theme }) => theme.global.edgeSize.xxsmall };
    transition: box-shadow 250ms linear;
  }
  .delete-client-btn:hover {
    box-shadow: 0 0 0 0.3rem hsla(10, 82%, 44%, 0.3);
  }
  .delete-client-btn svg,
  .delete-client-btn:hover svg {
    color: ${({ theme }) => theme.global.colors.white }; 
  }
`;