import {
  teal,
  tealA,  
  sage,
  sageA,
  tomato,
  green,
  yellow,
  amber,
  orange,
  blue,
  cyan,
} from '@radix-ui/colors';

// console.log(teal);
//console.log(sageA);

/* Grommet color overrides */
const brandColor = teal.teal11;

const accentColors = [blue.teal3, blue.teal5, blue.teal7, blue.teal9];

const neutralColors = [teal.teal3, teal.teal5, teal.teal7, teal.teal9];

const statusColors = {
  critical: tomato.tomato11,
  error: tomato.tomato11,
  warning: amber.amber10,
  ok: green.green9,
  unknown: sage.sage11,
  disabled: sage.sage11,
};

const darkColors = [
  sage.sage4,
  sage.sage5,
  sage.sage6,
  sage.sage7,
  sage.sage8,
  sage.sage9,
];

const lightColors = [
  sage.sage2,
  sage.sage3,
  sage.sage4,
  sage.sage5,
  sage.sage6,
  sage.sage7,
];

//const focusColor = accentColors[1];
const focusColor = 'teal5';

export const colors = {
  ...teal,
  ...tealA,
  ...sage,
  ...sageA,  
  ...tomato,
  ...orange,
  ...green,
  ...amber,
  ...yellow,
  ...blue,
  ...cyan,
  active: tealA.tealA7,
  'active-background': 'background-contrast',
  'active-text': 'text-strong',
  black: sage.sage12,
  border: {
    // dark: sageA.sageA5,
    light: sageA.sageA8,
  },
  brand: brandColor,
  control: {
    // dark: 'accent-1',
    light: 'brand',
  },
  focus: focusColor,
  placeholder: sage.sage9,
  selected: 'brand',
  text: {
    dark: sage.sage3,
    light: sage.sage12,
  },
  'text-strong': {
    // dark: '#FFFFFF',
    light: sage.sage2,
  },
  'text-weak': {
    // dark: '#CCCCCC',
    light: sage.sage8,
  },
  'text-xweak': {
    // dark: '#BBBBBB',
    light:  sage.sage9,
  },
  icon: {
    // dark: '#f8f8f8',
    light: sage.sage9,
  },
  // 'selected-background': 'brand',
  // 'selected-text': 'text-strong',
  'selected-background': blue.blue11,
  'selected-text': blue.blue11,
  white: '#FFFFFF',
  
  /* custom KBK color vars */
  info_dark: blue.blue11,
  info_light: blue.blue4,
  brand_light: teal.teal4,
  brand_lighter: teal.teal3,
  cancel_btn: sage.sage8,
  cancel_btn_hover: sage.sage9,
  // headers
  teal_text_1: teal.teal7,
  teal_text_2: teal.teal8,
  teal_text_3: teal.teal9,
  h1: sage.sage8,
  h2: sage.sage9,
  h3: sage.sage9,
  // nav btns
  nav_btn_text: cyan.cyan11,
  nav_btn_text_hover: cyan.cyan2,
  nav_btn_border: cyan.cyan7,
  nav_btn_border_hover: cyan.cyan8,
  nav_btn_bg: cyan.cyan2,
  nav_btn_bg_hover: cyan.cyan8,
  // other UI
  light_border_login: cyan.cyan6,
  light_border_main: teal.teal6
};

const colorArray = (array, prefix) => {
  array.forEach((color, index) => {
    colors[`${prefix}-${index + 1}`] = color;
  });
};

colorArray(accentColors, 'accent');
colorArray(darkColors, 'dark');
colorArray(lightColors, 'light');
colorArray(neutralColors, 'neutral');

Object.keys(statusColors).forEach((color) => {
  colors[`status-${color}`] = statusColors[color];
});