import { Select, Text, Box } from 'grommet';
import { useController } from 'react-hook-form';
import { formErr /*, onError */ } from '../errors/errors';

export const findOpt = (val, opts) => {
  const defOpt = opts.filter(opt => {
    return opt.value === val;
  });
  return defOpt[0] ? defOpt[0] : null;
};

export const SelectMultipleGroup = ({ 
  opts, 
  name: selectName, 
  label, 
  selectValue: value,
  setSelectValue: setValue,
  ...rest
}) => {
  return (
    <>
      <Box         
        className="select-wrap"        
        direction="column"
      >
        <Text as="label" size="small">{ label }</Text>
        
        <Select 
          multiple={ true }
          closeOnChange={ false }
          // a11yTitle={ label }
          size="small"
          placeholder="Select"
          valueKey={{ key: 'value', reduce: true }}
          labelKey="label"                    
          options={ opts }               
          value={ value }
          onChange={({ value: nextValue }) => setValue(nextValue)}
          { ...rest }
        />
      </Box>

    </>
  )
};

export const SelectGroup = ({ 
  control,
  opts, 
  name: selectName, 
  label, 
  errors,
  onChangeCb = null,
  ...rest
}) => {
  const {
    field: { onChange, /* onBlur, */ name, value, ref },
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields }
  } = useController({
    name: selectName,
    control: control,
    // rules: { required: true },
    // defaultValue: defaultValue,
  });
  return (
    <>
      <Box         
        className="select-wrap"        
        direction="column"
      >
        <Text as="label" size="small">{ label }</Text>
        <Select 
          a11yTitle={ label }
          size="small"
          valueKey="value"
          labelKey="label"          
          options={ opts }
          value={ 
            opts.find(c => {
            return c.value === value;
            }) || '' 
          }         
          onChange={ ({ value: val /*, option */ }) => {
            if (onChangeCb) {
              onChangeCb(val);
            }
            return onChange(val?.value || ''); 
          }}
          inputRef={ ref }
          // dropProps={{
          //   // background: 'orange'
          // }}
          // children={(option, index, options, { /* active, disabled, */ selected }) => {
          //   console.log(option)
          //   console.log(options)
          //   return <Box pad="small">{option.value}</Box>;
          // }}
          { ...rest }
        />
      </Box>

      { formErr(errors, name) }
    </>
  )
};