import styled from 'styled-components';

export const LogInFormStyled = styled.form`
  width: 100%;
  max-width: 28rem;

  .text-input-wrap {
    margin-bottom: ${props => props.theme.global.edgeSize.small};
  }
  .textarea-wrap label {
    padding-left: ${props => props.theme.global.edgeSize.xxsmall};
  }  
`;