import styled from 'styled-components';

export const TemplateFormStyled = styled.form`  
  width: 46rem;  
  fieldset {
    border: none;
  }
  .textarea-wrap label,
  .text-input-wrap label,
  .select-wrap label {
    padding-left: ${({ theme }) => theme.global.edgeSize.xxsmall};
  }
  .textarea-btns {
    position: absolute;
    top: ${({ theme }) => theme.global.edgeSize.xsmall}; 
    /* right: ${({ theme }) => theme.global.edgeSize.small};  */
    right: 0;
    transform: translateY(3px);
  }
  .textarea-btns-master {
    transform: none;
    right: ${({ theme }) => theme.global.edgeSize.xsmall}; 
  }
  .reset-template {
    transform: translateY(-1px);
  }
  .reset-template:disabled {
    opacity: 0;
    pointer-events: none;
  }
  .reset-template svg {
    color: ${({ theme }) => theme.global.colors['sage8']};
  }
  .reset-template:hover svg {
    color: ${({ theme }) => theme.global.colors['teal8']};
  }  
  .toggle-tags-btn {
    font-size: ${({ theme }) => theme.text.xsmall.size};
    color: ${({ theme }) => theme.global.colors['sage9']};
    line-height: 1;
    padding: 3px 5px;
    border-radius: 3px;
    outline: none;
    border: none;
    box-shadow: inset 0 0 0 0.1rem ${({ theme }) => theme.global.colors['sage8']};
  }
  .toggle-tags-btn:hover {
    background: ${props => props.theme.global.colors['teal8']};
    box-shadow: inset 0 0 0 0.1rem ${({ theme }) => theme.global.colors['teal8']};
    color: ${({ theme }) => theme.global.colors['white']};
  }
  .template-chk .checkbox-wrap {
    width: 100%;
    margin-top:  ${({ theme }) => theme.global.edgeSize.xsmall};
  }

  /* media queries -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
  @media (max-width: ${props => props.theme.global.breakpoints.small.value}px) { 
    width: 100%;
    .textarea-btns {   
      transform: translateY(-2px);
    }
  }
  @media (max-width: ${props => props.theme.global.breakpoints.xsmall.value}px) { 
    width: 100%;
    .textarea-btns {   
      transform: translateY(-2px);
    }
    .bottom-ui {
      flex-flow: column nowrap;
      align-items: flex-start;
      padding-top:  ${({ theme }) => theme.global.edgeSize.xsmall};
    }
    button[type="submit"] {
      margin-top:  ${({ theme }) => theme.global.edgeSize.xsmall};
    }    
  }    
`;