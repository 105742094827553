import axios from 'axios';
// import LRU from 'lru-cache';
import { makeUseAxios } from 'axios-hooks'
import { sessionStorage } from '../storage/session-storage';
const isDev = process.env.NODE_ENV === 'development';

const serverPath = isDev ? process.env.REACT_APP_DEV_SERVER_PATH : '';
//const serverPath = 'https://dev.paperlesspipeline.com'; // figure out how to proxy to dev api...
const subPath = '/archie/api';

// https://github.com/isaacs/node-lru-cache
// const cacheOpts = {
//   max: 500,  
//   ttl: 1000 * 60 * 3, // 3 min, ms
// };

// export const cache = new LRU(cacheOpts);

export const useAxios = makeUseAxios({
  axios: axios.create({ 
    baseURL: `${serverPath}${subPath}`,
    withCredentials: true,    
    transformRequest: axios.defaults.transformRequest.concat((data, headers) => {  
      headers['X-CSRFTOKEN'] = sessionStorage.get('csrf', '');      
      return data;
    }),
    transformResponse: axios.defaults.transformResponse.concat((data, headers) => {      
      if (data && data.csrf) {        
        sessionStorage.set('csrf', data.csrf);
      } else if (headers && headers.csrf) {        
        sessionStorage.set('csrf', headers.csrf);
      }
      return data;
    }),
  }),
  // cache: cache,
  cache: false,
  // defaultOptions: { useCache: true /* manual: false, ssr: true, autoCancel: true */ }
});