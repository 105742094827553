import { useEffect, useState } from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Text } from 'grommet';

import { useContextVal } from '../auth/Auth.js';
import { useAxios } from '../ajax/Ajax.js';
import { signUpSchema } from '../validation/validation.js';
import { InputGroup } from '../ui/InputGroup.js';
import { CheckboxGroup } from '../ui/CheckboxGroup';
import { useError } from '../errors/errors.js';
import { ShowPwButton } from '../ui/ShowPwButton.js';
import { SignUpFormStyled } from './SignUpForm.css.js';

const defaultErrMsg = 'An error occurred while signing up. Please refresh the page and try again.';

const defaultVals = {
  first_name: '',
  last_name: '',
  email: '',
  backup_email: '',
  phone: '',
  brokerage: '',
  password: '',
  confirm_password: '',
  tos: false,
};

// const defaultValsTest = {
//   backup_email: 'agent_backup_email@pp.com',
//   brokerage: 'Best Brokerage',
//   phone: '123-123-1234',
//   email: 'janagent@pp.com',
//   first_name: 'Jan',
//   last_name: 'Agent',
//   password: '12345',
//   confirm_password: '12345',
// };

export const SignUpForm = () => {
  const signIn = useContextVal().signIn;
  const navigate = useNavigate();
  const [ showPw, setShowPw ] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setFocus,
  } = useForm({
    mode: 'onTouched', //  onChange | onBlur | onSubmit | onTouched | all
    defaultValues: defaultVals,
    resolver: yupResolver(signUpSchema),
    delayError: 250
  });
  const [
    { 
      data: postData, 
      loading: postLoading, 
      error: postError
    },
    executePost
  ] = useAxios(
    {
      url: 'signup/',
      method: 'POST'
    },
    { manual: true }
  );

  const { ErrModal, onError } = useError(setError, defaultErrMsg);

  const onSubmit = async (data) => {
    try {
      await executePost({ data: data }); 
    } catch (err) {
      return onError({ message: 'error' }); // triggers global, default error msg
    }    
  };  

  useEffect(() => {
    if (
      postData?.success && 
      postData?.user
    ) {
      signIn(postData, () => {      
        navigate('/clients', { replace: true });
      });
    }
    if ( 
      postData && 
      !postData.success 
    ) {    
      onError(postData);
    }
  }, [ postData, navigate, signIn, onError ]);    

  useEffect(() => {
    if (postError) {
      onError(postError);
    }
  }, [ postError, onError ]);  

  useEffect(() => {
    setFocus('first_name');
  }, [ setFocus ]);  

  return  (
    <SignUpFormStyled onSubmit={ handleSubmit(onSubmit /*, onError */ ) }>

      <Box 
        as="fieldset"
        disabled={postLoading}
        pad="medium"
        margin="none"
        round="small"        
        border={{
          color: 'light_border_login',
          size: 'xsmall',
          style: 'solid',
          side: 'all'
        }}       
      >

        <InputGroup
          type="text"
          name="first_name"
          label="First Name"
          errors={errors}
          register={register}
        />  

        <InputGroup
          type="text"
          name="last_name"
          label="Last Name"
          errors={errors}
          register={register}
        />              

        <InputGroup
          type="email"
          name="email"
          label="Email"
          errors={errors}
          register={register}
        />  

        <InputGroup
          type="email"
          name="backup_email"
          label="Backup Email"
          errors={errors}
          register={register}
        />    

        <InputGroup
          type="text"
          name="phone"
          label="Cell Phone"
          errors={errors}
          register={register}
        />

        <InputGroup
          type="text"
          name="brokerage"
          label="Brokerage"
          errors={errors}
          register={register}
        />    

        <InputGroup
          type={ showPw ? 'text' : 'password' }
          name="password"
          label="Password"
          autocomplete="new-password"
          errors={ errors }
          register={ register }
          InputButton={ <ShowPwButton showPw={ showPw } setShowPw={ setShowPw } /> }
        />  

        <InputGroup          
          type={ showPw ? 'text' : 'password' }
          name="confirm_password"
          label="Confirm New Password"
          autocomplete="new-password"
          errors={ errors }
          register={ register }
          InputButton={ <ShowPwButton showPw={ showPw } setShowPw={ setShowPw } /> }
        />  

        <CheckboxGroup
          name="tos"
          label={
            <Text size="small" margin="none">By checking this box, you confirm your agreement with these <a href="https://www.paperlesspipeline.com/terms-of-service/" rel="noreferrer" target="_blank">Terms of Service&nbsp;&rarr;</a>.</Text>
          } 
          a11yTitle="By checking this box, you confirm your agreement with these terms of service."
          control= { control }
          errors={ errors }
          register={ register }
        />   

        <Button 
          primary 
          size="small" 
          label="Create Account" 
          type="submit" 
          margin={{ top: 'small' }}          
        />

      </Box>

      <ErrModal />

    </SignUpFormStyled>
  )
};