import { Nav, Box, Tag } from 'grommet';
import { Lnk } from './Lnk';
import { useContextVal } from '../auth/Auth';
import { LeftSidebarStyled } from './LeftSidebar.css';

const Badge = () => {
  return (
    <Tag 
      className="nav-badge" 
      name="To-Do" 
      size="xsmall"
    />
  );
};

const Spacer = () => {
  return (
    <Box 
      className="spacer" 
      height="4px" 
      width="calc(100% - 20px)" 
      round="small" 
      background="tealA5" 
      margin={{ vertical: 'small', horizontal: '10px' }}       
    /> 
  );
};

export const LeftSidebar = ({ noSidebarPath }) => {
  const ctxt = useContextVal();
  const isStaff = ctxt?.user?.is_staff;
  const isConnected = Number.isInteger(ctxt?.user?.pp_user_id);

  if (noSidebarPath) {
    return null;
  }

  if (!ctxt.user) { // log in, sign up pages
    return (
      <LeftSidebarStyled 
        background="cyan3" 
        pad="small"
        round={{ size: 'xsmall', corner: 'right' }}
        margin={{ right: 'medium' }}
        isEmpty={ true }
      />
    );
  }

  return  (
    <LeftSidebarStyled 
      background="cyan3" 
      pad="small"
      round={{ size: 'xsmall', corner: 'right' }}
      margin={{ right: 'medium' }}
      isEmpty={ false }
    >
      <Nav 
        fill={ true }
        border={ false }        
        direction="column" 
        gap="small" 
        pad="none"            
        flex={ false }       
      >
        <Lnk to="/clients" txt="Manage Clients" className="left-nav" refresh="true" />
        <Lnk to="/client-messages/pending" txt="Client Messages" className="left-nav" refresh="true" />
        <Lnk to="/templates" txt="Message Template Setup" className="left-nav" refresh="true" />
        { ctxt?.user?.return_address_configured === false &&
          <Lnk 
            to="/profile" 
            txt="User Profile" 
            className="left-nav" 
            refresh="true"
            badge={ ctxt?.user?.return_address_configured === false ? <Badge /> : null }           
          />
        }
        { ctxt?.user?.stripe_configured === false &&
          <Lnk 
            to="/billing" 
            txt={`${ ctxt?.user?.stripe_configured === false ? 'Add' : 'Update' } Credit Card`}
            className="left-nav" 
            refresh="true"
            badge={ ctxt?.user?.stripe_configured === false ? <Badge /> : null }           
          />
        }
        { !isConnected &&
          <Lnk 
            to="/connect" 
            txt={`${ isConnected ? 'Disconnect' : 'Connect to' } Paperless Pipeline`} 
            className="left-nav" 
            refresh="true"         
          />   
        }
        { isStaff && 
          <Spacer />
        }
        { isStaff && <Lnk to="/templates-master" txt="Master Templates" className="left-nav" refresh="true" /> }
        { isStaff && <Lnk to="/audit-log" txt="Audit Log" className="left-nav" refresh="true" /> } 
      </Nav>
    </LeftSidebarStyled>
  )
};

