import { Heading } from 'grommet';
import { ResetPasswordForm, ChangePasswordForm } from './ResetPasswordForm';
import { ResetPasswordStyled } from './ResetPassword.css';

export const ResetPassword = () => {
  return  (
    <ResetPasswordStyled
      forwardedAs="section"
      justify="center"
      align="center"
      pad="medium"
    >

      <Heading 
        level="1" 
        size="small" 
        color="h1"
        margin={{ vertical: 'small' /*, left: 'medium' */ }}        
      >
        Reset Password
      </Heading>

      <ResetPasswordForm />

    </ResetPasswordStyled>
  )
};

export const ChangePassword = () => {
  return  (
    <ResetPasswordStyled
      forwardedAs="section"
      justify="center"
      align="center"
      pad="medium"
    >

      <Heading 
        level="1" 
        size="small" 
        color="h1"
        margin={{ vertical: 'small' /*, left: 'medium' */ }}        
      >
        Change Password
      </Heading>
      
      <ChangePasswordForm />

    </ResetPasswordStyled>
  )
};