import { Button } from 'grommet';
import { Cancel } from 'iconoir-react';

export const CloseModalButton = ({ closeFunc, disabled }) => {
  return (
    <Button 
      default              
      className="close-modal-btn"
      size="medium" 
      onClick={ closeFunc } 
      a11yTitle="close this"
      icon={ <Cancel height={36} width={36} /> }              
      margin="none" 
      disabled={ disabled }             
    />
  );
};