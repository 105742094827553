import styled from 'styled-components';
import { normalizeColor } from 'grommet/utils';

export const ClientLogFiltersStyled = styled.form`
  /* fieldset {
    border: 0.1rem solid ${({ theme }) => normalizeColor('light_border_main', theme)};
  } */
  label {
    padding-left: ${({ theme }) => theme.global.edgeSize.xxsmall };
    font-size: ${({ theme }) => theme.text.xsmall.size };    
    line-height: ${({ theme }) => theme.text.xsmall.height };
  }
`;