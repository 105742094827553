import { useState, useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Box, Grid, Button, Paragraph, Heading } from 'grommet';

import { useAxios /*, cache */ } from '../ajax/Ajax.js';
import { useTemplateContext, addUpdateMasterTemplate, removeMasterTemplate } from '../context/template-context.js';
import { templateMasterSchema } from '../validation/validation.js';
import { SelectGroup } from '../ui/SelectGroup.js';
import { CheckboxGroup } from '../ui/CheckboxGroup.js';
import { InputGroup } from '../ui/InputGroup.js';
import { TextareaGroup } from '../ui/TextareaGroup.js';
import { useModal } from '../modal/Modal.js';
import { useError } from '../errors/errors.js';
import { useLoading } from '../loading/loading.js';
import { TemplateTags } from './TemplateTags.js';
import { useImgPreview } from '../archie_card/ArchieCard.js';
import { TemplateFormStyled } from './TemplateForm.css.js';
import { Check, Trash } from 'iconoir-react';

const defaultErrMsg = 'An error occurred while updating your message template. Please refresh the page and try again.';
const successMsg = 'The master template has been successfully updated.';
const deleteSuccessMsg = 'The master template has been successfully deleted.';

const findType = (type, typeOpts) => {
  if (!type || !typeOpts) {
    return { value: '', label: '' };
  }
  return typeOpts.find(c => c.value === type) || typeOpts[2];
};

export const TemplateMasterForm = ({ 
  activeTemplate: template,
  setLoading,
  setSuccess,
  closeModal,
  ctxt,  
}) => {  
  const { masterDispatch } = useTemplateContext();  
  const templateTags = ctxt?.settings?.template_tags || [];  
  const [ open, setOpen ] = useState(false);
  const [ type, setType ] = useState(null);
  const { Modal, opModal, clModal } = useModal();
  const defaultImg = ctxt?.settings?.image_choices[0].value || '';
  const formVals = useMemo(() => { 
    return {
      year_number: template?.year_number || 0,
      name: template.name && template.name.length ? template.name : '', 
      text: template.text && template.text.length ? template.text : '',       
      type: template.type && template.type.length ? template.type : 'sms',
      is_enabled: template?.is_enabled || false,
      trigger: template?.trigger || 'start-date',      
      offset_days: template?.offset_days || 0,      
      send_followup: template?.send_followup || false,
      notify_when_sent: template?.notify_when_sent || false,
      image: template?.type === 'sms' ? '' : 
        template?.image ? template.image : defaultImg,
    };
  }, [ template, defaultImg ]);  
  const {
    control,
    register,
    handleSubmit,    
    reset,
    formState: { errors, /* dirtyFields, */ },
    setError,
    setValue,
    watch,
  } = useForm({
    mode: 'onTouched', //  onChange | onBlur | onSubmit | onTouched | all
    defaultValues: formVals,
    resolver: yupResolver(templateMasterSchema),
    delayError: 250
  });
  const [
    { 
      data: postData, 
      loading: postLoading, 
      error: postError
    },
    executePost
  ] = useAxios(
    {
      url: `master-templates/${template.id ? `${template.id}/` : ''}`,
      method: 'POST'
    },
    { manual: true }
  );  
  const [
    { 
      data: deleteData, 
      loading: deleteLoading, 
      error: deleteError
    },
    executeDelete
  ] = useAxios(
    {
      url: `master-templates/${ template?.id }/`,  
      method: 'DELETE'
    },
    { manual: true }
  );
  
  const { ImgPreviewModal, setShowPreview } = useImgPreview({ ...watch(), ...{ master: true } });  
  const { ErrModal, onError } = useError(setError, defaultErrMsg);
  const { Loading } = useLoading(
    postLoading || deleteLoading, 
    postLoading ? 'Saving master template...' : 'Deleting master template...'
  );

  const deleteTemplate = async () => {
    try {      
      await executeDelete();
      clModal();
    } catch (err) {
      return onError({ message: 'error' }); // triggers global, default error msg
    }      
  };

  const onSubmit = async (data) => {
    try {
      await executePost({ data: { ...data } });
    } catch (err) {
      return onError({ message: 'error' }); // triggers global, default error msg
    }      
  };

  const typeChange = (opt) => {
    setType(opt)
  };

  const toggleTags = () => {
    setOpen(!open);
  };

  const previewImg = () => {
    setShowPreview(true);
  };

  useEffect(() => {
    if (
      postData?.success && 
      postData?.master_template
    ) {
      setSuccess(true, successMsg);
      masterDispatch(addUpdateMasterTemplate(postData.master_template));      
      setTimeout(() => {
        closeModal();
      }, 250);   
      // cache.clear();            
    }
    if ( 
      postData && 
      !postData.success 
    ) {    
      onError(postData);
    }
  }, [ postData, masterDispatch, onError, setSuccess, closeModal ]);      

  useEffect(() => {    
    if (
      deleteData?.success
    ) {
      setSuccess(true, deleteSuccessMsg);
      masterDispatch(removeMasterTemplate(template));
      setTimeout(() => {
        closeModal();
      }, 250);   
      // cache.clear();
    }
    if ( 
      deleteData && 
      !deleteData.success 
    ) {    
      onError(deleteData);
    }
  }, [ deleteData, masterDispatch, onError, template, closeModal, setSuccess ]);  

  useEffect(() => {
    reset(formVals);
  }, [ reset, formVals ]);

  useEffect(() => {    
    const error = postError ? 
      postError?.toJSON() : 
      deleteError?.toJSON();    
    onError(error);
  }, [ postError, deleteError, onError ]);

  useEffect(() => {    
    setType(
      findType(
        ( template.type || formVals.type ),
        ( ctxt?.settings?.type_choices || [] )
      )
    );
  }, [ template.type, formVals.type, ctxt?.settings?.type_choices ]);

  useEffect(() => {
    if (postLoading || deleteLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }    
  }, [ postLoading, deleteLoading, setLoading ]);

  useEffect(() => {    
    if (type?.value === 'sms') {     
      setValue('image', '');
    } 
    if (type?.value === 'postcard') {      
      setValue('send_followup', false);
    }
  }, [ type, setValue ]);

  return (
    <TemplateFormStyled 
      className="template-form" 
      onSubmit={ handleSubmit(onSubmit /*, onError */ ) } 
      data-id={ template.id }
    >

      <Grid         
        as="fieldset"
        fill={true}
        rows={['auto']}        
        columns={['1fr', '1fr', '1fr', '1fr']}        
        areas={[
          ['h2', 'h2', 'h2', 'h2'],            
          ['name', 'name', 'name', 'name'],        
          ['offset', 'offset', 'year', 'year'],
          ['trig', 'trig', 'type', 'type'],
          ['imgselect', 'imgselect', 'imgpreview', 'imgpreview'],
          ['tags', 'tags', 'tags', 'tags'],
          ['textarea', 'textarea', 'textarea', 'textarea'],
          ['check', 'check', 'check', 'check'],
          ['bottom', 'bottom', 'bottom', 'bottom'],
        ]}
        margin="none"
        border={ false }
        pad="none"
        disabled={ postLoading || deleteLoading }
      >

        <Heading 
          level="2" 
          size="small"          
          color="h2"
          gridArea="h2"          
          margin="small"
        >
          Edit Master Template
        </Heading> 
          
        <Box gridArea="name" pad="xsmall">

          <InputGroup
            type="text"
            name="name"
            label="Template Name"
            errors={ errors }
            register={ register }
          />     

        </Box>

        <Box gridArea="offset" pad="xsmall">

          <InputGroup
            type="number"
            step="1"
            name="offset_days"
            label="Offset Days"
            errors={ errors }
            register={ register }
          />     

        </Box>

        <Box gridArea="year" pad="xsmall">

          <SelectGroup          
            // onChangeCb={ }
            control={ control }
            opts={ ctxt?.settings?.year_number_choices || [] }
            name="year_number"
            label="Year"
            errors={ errors }  
            classNamePrefix="react-select"            
          /> 
          
        </Box>        


        <Box gridArea="trig" pad="xsmall">

          <SelectGroup
            // onChangeCb={ }
            control={ control }
            opts={ ctxt?.settings?.trigger_choices || [] }
            name="trigger"
            label="Trigger"
            errors={ errors }  
            classNamePrefix="react-select"            
          /> 

        </Box>         

        <Box gridArea="type" pad="xsmall">

          <SelectGroup
            onChangeCb={ typeChange }
            control={ control }
            opts={ ctxt?.settings?.type_choices || [] }
            name="type"
            label="Type"
            errors={ errors }  
            classNamePrefix="react-select"
          />

        </Box>     

        <Box gridArea="imgselect" pad={{ top: 'xsmall', right: 'xsmall', bottom: 'medium', left: 'xsmall'}}>

          <SelectGroup
            // onChangeCb={ }
            control={ control }
            opts={ ctxt?.settings?.image_choices || [] }
            name="image"
            label="Archie Card Image"
            errors={ errors }  
            classNamePrefix="react-select"
            disabled={type?.value !== 'postcard'}
          />

        </Box>

        <Box gridArea="imgpreview" pad={{ top: 'xsmall', right: 'xsmall', bottom: 'medium', left: 'xsmall'}} justify="end">

          <Button 
            default 
            size="small" 
            disabled={type?.value !== 'postcard'}
            onClick={ previewImg } 
            a11yTitle="preview Archie card" 
            label="Preview Archie Card"      
          />          

        </Box>        

        <TemplateTags tags={ templateTags } gridArea="tags" open={ open } master={ true } />     

        <Box gridArea="textarea" pad="xsmall">

          <TextareaGroup
            maxLength={ type?.value === 'sms' ? '300' : '450' }
            name="text"
            label={ type?.value === 'sms' ? 'Text Message' : 'Archie Card Message' }
            errors={ errors }
            register={ register }
          />          

          <Box 
            className="textarea-btns textarea-btns-master"
            direction="row"
            align="center"
            justify="end"
          >

            <Button 
              default 
              size="small" 
              plain={ true }
              //margin={{ left: 'xsmall' }}
              className="toggle-tags-btn"
              onClick={ toggleTags } 
              a11yTitle="show / hide template tags" 
              label={`${ open ? 'Hide' : 'Show' } Template Tags`}         
            />

          </Box>

        </Box>

        <Box 
          gridArea="check" 
          gap="small"
          pad={{ top: 'xsmall', right: 'xsmall', bottom: 'medium', left: 'xsmall' }} 
          direction="column" 
          justify="between" 
          align="start"
          className="template-master-chk"
        >

          <CheckboxGroup
            name="is_enabled"
            label="Active" 
            control={ control }
            errors={ errors }
            register={ register }            
          />

          <CheckboxGroup
            name="notify_when_sent"
            label="Notify me when message has been sent" 
            control={ control }
            errors={ errors }
            register={ register }
          />    

          { type?.value === 'sms' &&
            <CheckboxGroup
              name="send_followup"
              label="Send Follow Up Email If No Reply" 
              control={ control }
              errors={ errors }
              register={ register }
              tooltip="Send a follow up “In case you missed it” email <br/>to client if no reply received within 5 days."
            />
          }

        </Box>

        <Box 
          gridArea="bottom" 
          pad="xsmall" 
          direction="row" 
          justify="between"
        >
          
          <Button 
            secondary 
            size="small"
            color="warn_btn" 
            className="delete-btn"
            onClick={ opModal }            
            label="Delete Template"
            disabled={ !template?.id }    
            gap="xsmall"
            icon={ <Trash height={20} width={20} /> }        
          />            

          <Button 
            primary 
            type="submit"
            size="small"
            label="Save Changes"
            alignSelf="end"
            gap="xsmall"
            icon={ <Check height={20} width={20} /> } 
          />

        </Box>

      </Grid>

      <Modal    
        id="1"    
        content={
          <>
            <Paragraph size="small" margin={{ top: 'none', right: 'none', bottom: 'small', left: 'none' }}>Are you sure you want to permanently delete { template.name }?</Paragraph>
            <Box direction="row" justify="between">
              <Button 
                default 
                size="small"
                label="Cancel"                
                onClick={ clModal }
              />
              <Button 
                secondary
                color="warn_btn" 
                size="small"
                label="Delete Template"                
                onClick={ deleteTemplate }
              />
            </Box>
          </>
        }  
      />     

      <ErrModal />

      <Loading />

      <ImgPreviewModal />

    </TemplateFormStyled>
  );
};