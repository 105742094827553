import { useEffect, useState, useMemo, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Box, Heading, Button, Text, ResponsiveContext } from 'grommet';

import { useContextVal } from '../auth/Auth.js';
import { useAxios /*, cache */ } from '../ajax/Ajax';
import { profileSchema } from '../validation/validation.js';
import { InputGroup } from '../ui/InputGroup.js';
import { useError } from '../errors/errors.js';
import { useSuccess } from '../success/success.js';
import { useLoading } from '../loading/loading.js';
import { ProfileFormStyled } from './ProfileForm.css.js';
import { ShowPwButton } from '../ui/ShowPwButton.js';
import { FormInfoBox } from '../ui/FormInfoBox.js';
import { Check } from 'iconoir-react';

const defaultGetErrMsg = 'An error occurred while retrieving your profile. Please refresh the page and try again.';
const defaultPostErrMsg = 'An error occurred while updating your profile. Please refresh the page and try again.';
const successMsg = 'Your profile has been successfully updated.';

export const ProfileForm = () => {
  const ctxt = useContextVal();  
  const [ showPw, setShowPw ] = useState(false);
  const size = useContext(ResponsiveContext);
  const miniMobile = size === 'xxsmall';
  // const isConnected = Number.isInteger(ctxt?.user?.pp_user_id);
  const formVals = useMemo(() => {     
    return {
      // names
      first_name: ctxt?.user?.first_name || '',
      last_name: ctxt?.user?.last_name || '',
      // misc
      agent_return_name: ctxt?.user?.agent_return_name || '',
      email: ctxt?.user?.email || '',
      backup_email: ctxt?.user?.backup_email || '',
      phone: ctxt?.user?.phone || '',
      brokerage: ctxt?.user?.brokerage || '',
      // passwords
      password: '',
      confirm_password: '',
      // address
      address: ctxt?.user?.address || '',
      address2: ctxt?.user?.address2 || '',
      city: ctxt?.user?.city || '',
      state: ctxt?.user?.state || '',
      zip_code: ctxt?.user?.zip_code || '',
    };
  }, [ ctxt.user ]);    
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm({
    mode: 'onTouched', //  onChange | onBlur | onSubmit | onTouched | all    
    defaultValues: formVals,
    resolver: yupResolver(profileSchema),
    delayError: 250
  });
  const [
    { 
      data: postData, 
      loading: postLoading, 
      error: postError
    },
    executePost
  ] = useAxios(
    {
      url: 'profile/',
      method: 'POST'
    },
    { manual: true }
  );
  const [
    { 
      data: getData, 
      loading: getLoading, 
      error: getError
    },    
  ] = useAxios(
    {
      url: 'profile/',
      method: 'GET'
    },
    { manual: false }
  );  

  const { SuccessModal, setSuccess } = useSuccess(successMsg);    
  const { ErrModal, onError } = useError(setError, defaultPostErrMsg);
  const { ErrModal: ErrModalGet, onError: onErrorGet } = useError(setError, defaultGetErrMsg);
  const { Loading } = useLoading(
    ( getLoading || postLoading ), 
    getLoading ? 'Loading Profile...' : 'Saving Profile...'
  );  

  const onSubmit = async (data) => {
    try {
      await executePost({ data: data }); 
    } catch (err) {
      return onError({ message: 'error' }); // triggers global, default error msg
    }      
  };

  useEffect(() => {
    if (
      postData?.success && 
      postData?.user
    ) {            
      ctxt.updateUser(postData.user);
      setSuccess(true);
      // cache.clear();
    }
    postData && !postData.success && onError(postData);
  }, [ postData, ctxt, onError, setSuccess ]);     

  useEffect(() => {    
    reset(formVals);
  }, [ formVals, reset ]);

  useEffect(() => {
    if (
      !ctxt?.user?.address && // auth user has no address
      getData?.success && 
      getData?.user
    ) {
      ctxt.updateUser(getData.user);
    }    
    getData && !getData.success && onErrorGet(getData);
  }, [ getData, ctxt, onErrorGet ]);  

  useEffect(() => {
    getError ? 
      onErrorGet(getError?.toJSON()) :
      onError(postError?.toJSON());
  }, [ getError, postError, onError, onErrorGet ]);

  return  (
    <ProfileFormStyled onSubmit={handleSubmit(onSubmit /*, onError */ )}>
   
      <Grid 
        as="fieldset"
        fill="horizontal"
        rows={['auto', 'auto']}        
        columns={ miniMobile ? ['100%'] : ['1fr', '1fr'] }         
        areas={ miniMobile ?
          [
            ['col1'],
            ['col2'],
            ['btns'],
          ] : [
            ['col1', 'col2'],
            ['btns', 'btns'],
          ]
        }
        border={ false }
        margin="none"
        pad="none"
        disabled={ postLoading || getLoading }
      >            

        <Box 
          margin="none"
          pad={{ right: `${ miniMobile ? 'none' : 'medium'}` }}
          direction="column"           
          gridArea="col1"      
        >  

          <Heading 
            level="3" 
            size="xsmall" 
            color="h3"
            margin={{ vertical: 'small' }}
          >Personal Info</Heading>

          <InputGroup
            type="text"
            name="first_name"
            label="First Name"
            errors={errors}
            register={register}
          />  

          <InputGroup
            type="text"
            name="last_name"
            label="Last Name"
            errors={errors}
            register={register}
          />      

          <InputGroup
            type="text"
            name="agent_return_name"
            label="Agent From Name"
            errors={ errors }
            register={ register }
          />        

          <InputGroup
            type="email"
            name="email"
            label="Email"
            errors={errors}
            register={register}
          />  

          <InputGroup
            type="email"
            name="backup_email"
            label="Backup Email"
            errors={errors}
            register={register}
          />    

          <InputGroup
            type="text"
            name="phone"
            label="Cell Phone"
            errors={errors}
            register={register}
          />

          <InputGroup
            type="text"
            name="brokerage"
            label="Brokerage"
            errors={errors}
            register={register}
          />    

          <InputGroup
            type={ showPw ? 'text' : 'password' }
            name="password"
            label="Password"
            autocomplete="new-password"
            errors={ errors }
            register={ register }
            InputButton={ <ShowPwButton showPw={ showPw } setShowPw={ setShowPw } /> }
          />  

          <InputGroup
            type={ showPw ? 'text' : 'password' }
            name="confirm_password"
            label="Confirm New Password"
            autocomplete="new-password"
            errors={ errors }
            register={ register }
            InputButton={ <ShowPwButton showPw={ showPw } setShowPw={ setShowPw } /> }
          />

        </Box>

        <Box 
          margin="none"
          pad={{ left: `${ miniMobile ? 'none' : 'medium'}` }}
          direction="column"          
          gridArea="col2"      
        >  

          <Heading 
            level="3" 
            size="xsmall" 
            color="h3"
            margin={{ vertical: 'small' }}
          >Return Address</Heading>

          { ctxt?.user?.return_address_configured === false &&
            <FormInfoBox
              content={ <Text size="small" color="white" weight={ 500 }>To activate clients (and messages), you'll first need to add your return address below.</Text> }
              title="To-Do"
              background="blue10"
            />
          }

          <InputGroup
            type="text"
            name="address"
            label="Address"
            errors={ errors }
            register={ register }
          />  

          <InputGroup
            type="text"
            name="address2"
            label="Address 2"
            errors={ errors }
            register={ register }
          />   

          <InputGroup
            type="text"
            name="city"
            label="City"
            errors={ errors }
            register={ register }
          />   

          <InputGroup
            type="text"
            name="state"
            label="State (2 Letters)"
            maxLength="2"
            errors={ errors }
            register={ register }
          />   

          <InputGroup
            type="text"
            name="zip_code"
            label="Zip Code"
            errors={ errors }
            register={ register }
          />                      

        </Box>   

        <Box 
          margin="none"
          pad={{ vertical: 'small' }}
          direction="row"
          gridArea="btns"      
        >                                                   

          <Button 
            primary 
            size="small" 
            label="Save Changes" 
            type="submit"
            alignSelf="end"           
            gap="xsmall"
            icon={ <Check height={20} width={20} /> } 
          />

        </Box>

      </Grid>

      <SuccessModal />

      <ErrModal />

      <ErrModalGet />

      <Loading />

    </ProfileFormStyled>
  )
};