import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button, Box, Text, Collapsible, Grid, Heading, Paragraph } from 'grommet';
import { EyeEmpty, ArrowUp, WarningCircledOutline, Prohibition, Calendar /* InfoEmpty */ } from 'iconoir-react';
import { formatDate, getClientParams, getClientName } from '../utilities/util.js';

const headerArr = (mobile) => {   
  return [
    { text: 'Message Name', opts: {} },  
    { text: 'Type', opts: { cellClass: 'template-type-cell' } },
    { text: 'Client', opts: {} },  
    { text: 'Scheduled', opts: { cellClass: 'template-scheduled-cell' } },  
    { text: 'Start Date', opts: { cellClass: 'template-start-cell' } },  
    { text: 'Close Date', opts: { cellClass: 'template-close-cell' } },  
    { text: '', opts: {} },  
    { text: '', opts: {} },  
  ];
};

const clientViewHeaderArr = () => {
  return [
    { text: 'Message Name', opts: {} },  
    { text: 'Description', opts: { cellClass: 'template-desc-cell' } },
    { text: 'Type', opts: { cellClass: 'template-type-cell' } },
    { text: 'Scheduled', opts: { cellClass: 'template-scheduled-cell' } }, 
    { text: '', opts: {} },  
  ];
};

// 'Pending', 'Success', 'Failed (Retry)', 'Failed (Permanent)', 'Error', 'Skipped'
const errStrArray = [
  'error',  
  'retry',
  'permanent',
  //'failed',
];

const StatusMsg = ({ clientLog, clientName }) => {
  const isError = errStrArray.find( str => clientLog.delivery_status.toLowerCase().includes(str) );
  const isSkipped = clientLog.delivery_status.toLowerCase().includes('skipped');
  let msg = '';

  if (!isError && !isSkipped) {
    return (
      <Text className="note-text" size="small" margin={{ bottom: 'xxsmall' }}>
        Delivery Status: <strong>{ clientLog?.delivery_status || '' }</strong>
      </Text>
    );
  }

  switch (isError) {
    case 'error':
      msg = 'Error - some error msg TBD.'
      break;
    case 'retry':
      msg = <><strong>Delivery Failed</strong>: Message delivery failed{ clientLog?.updated_at ? ` on ${formatDate(clientLog?.updated_at, false)}` : '' }, system will try sending the message again. Double check <Link to={`/clients/?client_id=${ clientLog?.client?.id ? clientLog.client.id : '' }`} className="log-msg-link">{ clientName }'s info</Link> to make sure it's accurate.</>
      break
    case 'permanent':
      msg = <><strong>Delivery Failed</strong>: Message delivery failed multiple times. No further attempts will be made. Double check <Link to={`/clients/?client_id=${ clientLog?.client?.id ? clientLog.client.id : '' }`} className="log-msg-link">{ clientName }'s info</Link> to make sure it's accurate.</>;
      break;
    default:
      msg = <><strong>Delivery Skipped</strong>: Message skipped due to client dates or <Link to={`/clients/?client_id=${ clientLog?.client?.id ? clientLog.client.id : '' }`} className="log-msg-link">other settings</Link>.</>
  }

  return (
    <Box 
      round="xsmall"
      background={ isError ? 'orange4' : 'teal4'} 
      pad="small"
      margin={{ top: 'xsmall' }}
      alignSelf="start"      
    > 
      <Paragraph size="small" margin="none" color={ isError ? 'orange12' : 'teal12'}>{ msg }</Paragraph>
    </Box>
  );
};

const RowBadge = ({ clientLog }) => {
  const statusText = clientLog?.delivery_status?.length > 0 ? 
    clientLog.delivery_status : 
    '';

  const isError = errStrArray.find( str => statusText.toLowerCase().includes(str) );
  const isSkipped = statusText.toLowerCase().includes('skipped');
  if (
    statusText?.length && 
    ( isError || isSkipped ) 
  ) {
    return (
      <Box 
        round="medium" 
        className={ `badge badge-${ isError ? 'error' : 'skipped' }` }
        data-tip={ `Delivery Status: ${ statusText }` }
      > 
        { isError ?
          <WarningCircledOutline height={20} width={20} /> :
          <Prohibition height={20} width={20} />
        }
      </Box>
    );
  }
  return null;
};

const Cell = ({ isHeader = false, content, cellClass = '', dataTip = null }) => {
  return (
    <Box 
      className={`${ isHeader ? 'cell-header' : 'cell' } cell-client ${ cellClass }`}
      direction="row" 
      pad={{ horizontal: 'small', vertical: `${ isHeader ? 'xsmall' : 'small' }` }}
      align="center"
      justfiy="start"
      data-tip= { dataTip }
    >
      { typeof content === 'object' ?
        content :
        <Text size="small" className="cell-text">
          { content }
        </Text>
      }
    </Box>
  );
};

export const ClientLogListHeader = ({ singleClientView, mobile }) => {  
  const arr = singleClientView ? 
    clientViewHeaderArr(mobile) :
    headerArr(mobile);    
  return (
    <div className="row">
      {
        arr.map(({ text, opts }, key) => {                    
          return (
            <Cell 
              content={ text }
              isHeader={ true }
              key={`header-text-${key}`}
              { ...opts }           
            />
          );
        })
      }
    </div>
  );        
};

export const ClientLogListItem = ({ 
  clientLog, 
  index, 
  singleClientView, 
  size
}) => {  
  const navigate = useNavigate();
  const [ showDetails, setShowDetails ] = useState(false);
  const clientName = getClientName(clientLog?.client);
  const viewDetails = () => {
    setShowDetails(d => !d);
  };
  const viewClientMessages = () => {
    navigate({
      pathname: '/client-messages/pending/',
      search: getClientParams(clientLog?.client, clientName),
    });
  };
  return [      
      <Box         
        className={`row ${ showDetails ? 'row-show-details' : ''}`}
        key={`client-log-list-item-top-${index}`}        
      >

        <Cell 
          content={ 
            <Text 
              size="small" 
              className="cell-text cell-text-with-badge" 
              weight={ 500 }
            >

              { <RowBadge clientLog={ clientLog } /> }
            
              <Link 
                className="table-link"      
                to={`/templates/?template_id=${ clientLog?.template?.id || '' }`}
              >
                { clientLog?.template?.name || 'No Name' }
              </Link>
              
            </Text> 
          }                                   
        />

        { singleClientView &&
          <Cell 
            cellClass="template-desc-cell"
            content={ 
              clientLog?.template?.description || ''
            }                          
          />    
        }

        <Cell 
          cellClass="template-type-cell"
          content={ 
            clientLog?.template?.type === 'sms' ? 
              'Text Message' : 'Archie Card'
          }
          dataTip={ 
            clientLog?.template?.type === 'sms' ? 
              null : 
              'Folded note card with picturesque image on front and your message inside.' 
          }                
        />    

        { !singleClientView &&
          <Cell 
            content={ 
              <Link 
                // target="_blank"    
                className="table-link"      
                to={`/clients/?client_id=${ clientLog?.client?.id ? clientLog.client.id : '' }`}
              >{ clientName }</Link>
            }                                  
          />  
        }

        <Cell 
          cellClass="template-scheduled-cell"
          dataTip={ clientLog?.template?.description || null }
          content={ clientLog?.due_date ? formatDate(clientLog.due_date, false) : ''}                                  
        />    

        { !singleClientView &&
          <Cell 
            cellClass="template-start-cell"
            content={ clientLog?.client?.start_date ? formatDate(clientLog.client.start_date, false) : ''}                                  
          />
        }

        { !singleClientView &&
          <Cell 
            cellClass="template-close-cell"
            content={ clientLog?.client?.close_date ? formatDate(clientLog.client.close_date, false) : ''}                                  
          />  
        }                               

        <Cell 
          content={
            <Button 
              plain 
              // data-tip={ `${ showDetails ? 'Hide' : 'View'} message details`}
              className="row-btn"
              size="small" 
              onClick={ viewDetails } 
              a11yTitle="View Details"
              icon={ showDetails ? <ArrowUp height={20} width={20} /> : <EyeEmpty height={20} width={20} /> }  
            />
          }
        />

        { !singleClientView &&
          <Cell 
            content={
              <Button 
                plain 
                // data-tip="View messages for this client only"
                className="row-btn"
                size="small" 
                onClick={ viewClientMessages } 
                a11yTitle="View Client Messages"
                icon={ <Calendar height={20} width={20} /> }  
              />
            }
          />
        }      
      </Box>,

      <Box 
        className="row-client-details" 
        key={`client-log-list-item-bottom-${index}`}        
      >

        <Collapsible open={ showDetails }>

          <Grid
            fill="horizontal"
            align="stretch"      
            
            // rows={['auto']}                  
            // columns={ size === 'xsmall' ? ['50%', '50%'] : ['30%', '70%'] }

            rows={ size === 'xxsmall' ? ['auto', 'auto'] : ['auto'] }            
            columns={ size === 'xsmall' ? ['50%', '50%'] : size === 'xxsmall' ? ['100%'] : ['30%', '70%'] }


            border={ false }
            pad="none"
          > 

          <Box className="log-col" pad="small">

            <Heading 
              level="3" 
              size="xxsmall" 
              color="h2"
              margin={{ bottom: 'xsmall', top: 'none', left: 'none', right: 'none' }}
            >
              Message Info
            </Heading>           
            
            { /*
              clientLog?.updated_at &&
              <Text className="note-text" size="small" margin={{ bottom: 'xxsmall' }}>
                Updated: { clientLog?.updated_at ? formatDate(clientLog?.updated_at, false) : '' }
              </Text>
            */ }

            { /*
              checkVal(clientLog?.delivery_failures) && 
              <Text className="note-text" size="small" margin={{ bottom: 'xxsmall' }}>
                Delivery Failures: { checkVal(clientLog?.delivery_failures) }
              </Text>
            */ }

            { clientLog?.delivery_status?.length > 0 && 
          

                <StatusMsg 
                  // status={ clientLog.delivery_status } 
                  clientLog={ clientLog }
                  clientName={ clientName }
                />
         
            }            
                         
          </Box>

          { clientLog?.text?.length > 0 && 
            <Box className="log-col" pad={{ top: 'small', right: 'small', left: 'small', bottom: 'small' }}>
              <Heading 
                level="3" 
                size="xsmall"
                color="h2"
                margin={{ bottom: 'xsmall', top: 'none', left: 'none', right: 'none' }}
              >
                Message Text
              </Heading>              
              
              <Paragraph 
                size="small" 
                className="text-display" 
                margin="none"
              >
                { clientLog?.text }
              </Paragraph>              
            </Box>
          }

          </Grid>
        </Collapsible>
      </Box>
  ];
};