import { useState, useCallback } from 'react';
import { Box, Text } from 'grommet';

export const useWarning = (
  warningMsg = null
) => {
  const [ warning, setWarning ] = useState(false);
  const [ msg, setMsg ] = useState(null);

  const showWarning = useCallback((set, setText = null) => {
    if (setText) {
      setMsg(setText);
    }
    setWarning(set);
  }, []);

  const Warning = useCallback(() => {
    return warning &&
      <Box 
        className="warning-box"
        size="small"
        pad={{ vertical: 'xsmall', horizontal: 'small' }}
        margin={{ top: '-1rem', bottom: '1rem'}}
        round="xxsmall"
        background="orange10"
      >
        <Text
          dangerouslySetInnerHTML={{ __html: msg || warningMsg }} 
          a11yTitle={ msg || warningMsg }
          size="small"
          // margin="xsmall"
          wordBreak="break-all"
          color="white"
          corner="xsmall"
        />
      </Box>
  }, [ warning, msg, warningMsg ]);

  return { 
    Warning: Warning,
    setWarning: showWarning 
  };
};