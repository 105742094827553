import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Grommet } from 'grommet'; // https://v2.grommet.io/grommet
import { theme } from './themes/theme';
import GlobalStyle from './global.css';
import App from './app/main/App';
import { AuthProvider } from './app/auth/Auth';
import { TemplateProvider } from './app/context/template-context';
import { ClientProvider } from './app/context/client-context';
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Grommet 
      theme={ theme } 
      themeMode="light" 
      full={ true } 
      className="grommet-root"
    >
    <GlobalStyle />    
      <BrowserRouter basename="/archie">
        <AuthProvider>
          <TemplateProvider>
            <ClientProvider>                        
              <App />              
            </ClientProvider>
          </TemplateProvider>
        </AuthProvider>
      </BrowserRouter>      
    </Grommet>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
