import styled from 'styled-components';

export const ResetPasswordFormStyled = styled.form`
  width: 100%;
  max-width: 28rem;
  
  .text-input-wrap {
    margin-bottom: ${props => props.theme.global.edgeSize.small};
  }
  .text-input-wrap label {
    padding-left: ${props => props.theme.global.edgeSize.xxsmall};
  }
`;