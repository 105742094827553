import { useContext } from 'react';
import ReactTooltip from 'react-tooltip';
import { ResponsiveContext } from 'grommet';
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  // useRoutes,
} from 'react-router-dom';

// https://reactrouter.com/docs/en/v6/api#browserrouter
// https://github.com/remix-run/history/blob/main/docs/api-reference.md#location

import { RequireAuth, NoAuth /*, useContextVal */ } from '../auth/Auth.js';
import { Layout } from './Layout.js';
// import { NotFound } from './NotFound.js';

import { LogIn } from '../log_in_out/LogInOut.js';
import { ResetPassword, ChangePassword } from '../log_in_out/ResetPassword.js';
import { SignUp } from '../sign_up/SignUp.js';
import { Profile } from '../profile/Profile.js';

import { Connect } from '../connect/Connect.js';
import { AuditLog } from '../audit_log/AuditLog.js';
import { ClientLog } from '../client_log/ClientLog.js';
import { Clients } from '../clients/Clients.js';
import { Billing } from '../billing/Billing.js';
import { Templates } from '../templates/Templates.js';
import { TemplatesMaster } from '../templates/TemplatesMaster.js';

import { AppStyled } from './App.css.js';

// const ClientRoutes = () => useRoutes([
//   { path: "/clients", element: <RequireAuth><Clients /></RequireAuth> },
//   { path: "/clients/:add", element: <RequireAuth><Clients /></RequireAuth> },
//   { path: "/clients/:add/:add_id", element: <RequireAuth><Clients /></RequireAuth> }
// ]);

const App = () => {  
  // const ctxt = useContextVal();
  const { pathname } = useLocation();
  const size = useContext(ResponsiveContext);
  // const isStaff = ctxt?.user?.is_staff;

  return (
    <AppStyled 
      id="app"
      fill={ true }
      rows={ size === 'large' ? 
        ['5rem', 'auto'] : 
        ['5rem', 'auto', '7.4rem'] 
      }        
      columns={ ['100%'] }        
      areas={ size === 'large' ? 
        [
          ['header'],
          ['main'],
        ] : [
          ['header'],
          ['main'],
          ['footer'],
        ]
      }
    >

      <Routes>

        <Route element={ <Layout path={ pathname } /> }>         

          <Route 
            path="login" 
            element={ 
              <NoAuth>
                <LogIn /> 
              </NoAuth>              
            } 
          />
            
          <Route 
            path="signup" 
            element={ 
              <NoAuth>
                <SignUp /> 
              </NoAuth>              
            } 
          />  
          
          <Route 
            path="resetpw" 
            element={ 
              <NoAuth>
                <ResetPassword /> 
              </NoAuth>              
            } 
          />  

          <Route 
            path="changepw/:sub_id/:id" 
            element={ 
              <NoAuth>
                <ChangePassword /> 
              </NoAuth>              
            } 
          />     

          {/* invalid - needs sub_id & id path segments - show warning */}
          <Route 
            path="changepw/:sub_id" 
            element={ 
              <NoAuth>
                <ChangePassword /> 
              </NoAuth>              
            } 
          />    

          {/* invalid - needs sub_id & id path segments - show warning */}
          <Route 
            path="changepw" 
            element={ 
              <NoAuth>
                <ChangePassword /> 
              </NoAuth>              
            } 
          />                 

          <Route 
            path="profile" 
            element={ 
              <RequireAuth>
                <Profile /> 
              </RequireAuth>
            }            
          /> 

          <Route 
            path="clients" 
            element={ 
              <RequireAuth>
                <Clients /> 
              </RequireAuth>
            }            
          /> 
          <Route 
            path="clients/:add" 
            element={ 
              <RequireAuth>
                <Clients /> 
              </RequireAuth>
            }            
          /> 
          <Route 
            path="clients/:add/:tx_id" 
            element={ 
              <RequireAuth>
                <Clients /> 
              </RequireAuth>
            }            
          />                     

          <Route 
            path="billing" 
            element={ 
              <RequireAuth>
                <Billing /> 
              </RequireAuth>
            }            
          /> 

          <Route 
            path="connect" 
            element={ 
              <RequireAuth>
                <Connect /> 
              </RequireAuth>
            }            
          /> 

          <Route 
            path="templates" 
            element={ 
              <RequireAuth>                
                <Templates />                 
              </RequireAuth>
            }            
          />                                            

          <Route 
            path="templates-master" 
            element={ 
              <RequireAuth>                
                <TemplatesMaster />                 
              </RequireAuth>
            }            
          />
          
          <Route 
            path="audit-log" 
            element={ 
              <RequireAuth>                
                <AuditLog />                 
              </RequireAuth>
            }            
          /> 

          <Route 
            path="client-messages/:view" 
            element={ 
              <RequireAuth>                
                <ClientLog />                 
              </RequireAuth>
            }            
          />
          <Route 
            path="client-messages/:view/:client_id" 
            element={ 
              <RequireAuth>
                <ClientLog /> 
              </RequireAuth>
            }            
          />                  

          {/* <Route index element={ <RequireAuth><Clients /></RequireAuth> } /> */}

          {/* <Route path="*" element={<NotFound />} /> */}

          {/* added 030222, redirect any non-matched path to /clients */}
          
          <Route
            path="*"
            element={ <Navigate to="/clients" /> }
          />

        </Route>

      </Routes>
      
      <ReactTooltip 
        place="top" 
        type="dark" 
        effect="solid" 
        multiline={true} 
        className="react-tooltip"
        // delayHide={1000000}
      />

    </AppStyled>
  );
}

export default App;
