import { useState } from 'react';
import {
  useNavigate,
  Link
} from 'react-router-dom';
import { Heading } from 'grommet';
import { LogInForm } from './LogInForm.js';
import { useAxios } from '../ajax/Ajax.js';
import { useContextVal } from '../auth/Auth.js';
import { sessionStorage } from '../storage/session-storage.js';
import { LogInOutStyled } from './LogInOut.css.js';

export const LogIn = () => {
  const [ loading, setLoading ] = useState(false);
  return  (
    <LogInOutStyled
      forwardedAs="section"
      justify="center"
      align="center"
      pad="medium"
    >

      <Heading 
        level="1" 
        size="small" 
        color="h1"
        margin={{ vertical: 'small' }}        
      >
        Log In
      </Heading>
      
      <LogInForm setLoading={ setLoading } />
      
      <Link to="/resetpw" className={ `form-sub-link ${ loading ? ' disabled-link' : ''}`}>Forgot Password?</Link>

    </LogInOutStyled>
  )
};

export const useLogOut = () => {
  const logOutCtxt = useContextVal().signOut;
  const navigate = useNavigate();
  const [
    { 
      // data: getData, 
      loading: getLoading, 
      // error: getError
    },
    executeGet
  ] = useAxios(
    {
      url: 'logout/',
      method: 'GET'
    },
    { manual: true }
  );
  const logOutUi = () => {
    sessionStorage.set('csrf', '');
    logOutCtxt(() => {
      navigate('/login');
    });
  }
  const logOut = async () => {
    try {
      await executeGet();
      logOutUi();
    } catch (err) {
      logOutUi();
      return console.error(err);
    }
  };
  return { 
    logOut: logOut,
    loading: getLoading,
  };
};    