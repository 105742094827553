import styled from 'styled-components';
import { normalizeColor } from 'grommet/utils';

export const AuditLogFiltersStyled = styled.form`
  fieldset {
    /* border: 0.1rem solid ${({ theme }) => normalizeColor('light_border_main', theme)}; */
    border: none;
  }
  label {
    padding-left: ${({ theme }) => theme.global.edgeSize.xxsmall };
    font-size: ${({ theme }) => theme.text.xsmall.size };    
    line-height: ${({ theme }) => theme.text.xsmall.height };
  }
`;