import { Button, Box, Text } from 'grommet';
import { Edit, Calendar, Cancel, ArrowRight } from 'iconoir-react';
import { useNavigate } from 'react-router-dom';
import { formatDate, getClientParams, getClientName } from '../utilities/util.js';

const headerArr = (mobile) => {
  return [
    { text: 'Name', opts: {} },
    ...(!mobile ? [{ text: 'Close Date', opts: { justify: 'center' } }] : [] ), 
    ...(!mobile ? [{ text: 'Brokerage', opts: {} }] : [] ),     
    ...(!mobile ? [{ text: 'Cards Sent', opts: { justify: 'center' } }] : [] ), 
    { text: '', opts: {} },
    { text: '', opts: {} },
  ];
};

const StatusBlock = ({ isEnabled, unreviewed, editClient }) => {  
  const addClass = isEnabled ? 'cell-status-active' : unreviewed ? 'cell-status-unreviewed' : '';
  const blockText = isEnabled ? 'Active' : unreviewed ? <>Review&nbsp;<ArrowRight height={16} width={16} />  </> : 'Not Activated';
  return (
    <Box 
      direction="row"
      margin={{ left: 'xsmall' }}
      pad={{ horizontal: 'xsmall', vertical: 'xxsmall' }}
      round="xsmall"
      className={`cell-status ${ addClass }`}
      align="center"
      data-tip={ unreviewed ? 'Review & Setup' : null }
      onClick={ editClient } 
    >
      <Text size="xsmall">{ blockText }</Text>
    </Box>
  )
};

const Cell = ({ 
  content,
  isHeader = false, 
  opts = {},
  className = null,  
}) => {  
  return (
    <Box 
      className={`${ isHeader ? 'cell-header' : 'cell' } cell-client ${ className }`}
      direction="row" 
      pad={{ horizontal: 'small', vertical: `${ isHeader ? 'xsmall' : 'small' }` }}
      align="center"
      justfiy="start"
      { ...opts }
    >
      { typeof content === 'object' ?
        content :
        <Text size="small" className="cell-text">
          { content }
        </Text>
      }
    </Box>
  );
};

export const ClientListHeader = ({ mobile }) => {  
  return (
    <div className="row">
      {
        headerArr(mobile).map(({ text, opts }, key) => {            
          return (
            <Cell 
              content={ text }
              isHeader={ true }
              key={`header-text-${key}`}     
              opts={ opts }           
            />
          );
        })
      }
    </div>
  );        
};

export const ClientListItem = ({ client, setClient, deleteClient, mobile, disabled }) => {  
  const navigate = useNavigate();
  const clientName = getClientName(client);
  const editClient = () => {
    if (disabled) {
      return;
    }
    setClient(client);
  };
  const unreviewed = client?.sending_status?.toLowerCase() === 'unreviewed';
  const viewClientMessages = () => {
    navigate({
      pathname: '/client-messages/pending/',
      search: getClientParams(client, clientName),
    });
  };
  const deleteUnreviewedClient = () => {    
    deleteClient(client);
  };
  return (
    <div className={`row ${ client?.sending_status?.toLowerCase() === 'unreviewed' ? 'row-unreviewed' : '' }`}>

      <Cell         
        className="cell-status-block"
        content={  
          <>

            <Text 
              size="small" 
              className={`cell-text table-link ${ client?.sending_status?.toLowerCase() === 'unreviewed' ? 'cell-text-unreviewed' : '' }`}
              weight={ 500 }
              onClick={ editClient }
            >              
              { clientName }              
            </Text>             

            <StatusBlock unreviewed={ unreviewed } isEnabled={ client?.is_enabled }  editClient={ editClient } />
          </>
        }                                   
      />

      { !mobile &&
        <>

          <Cell 
            content={ client?.close_date ? formatDate(client.close_date) : ''}  
            opts={{ justify: 'center' }}                                  
          />

          <Cell 
            content={ client?.brokerage ? client.brokerage : ''}                                   
          />

          <Cell 
            content={ client?.postcards_sent ? client.postcards_sent : ''}     
            opts={{ justify: 'center' }}                             
          />    


        </>
      }                    

      <Cell 
        content={
          <Button 
            plain 
            className="row-btn"
            size="small" 
            onClick={ editClient } 
            // data-tip="View / edit client details"
            a11yTitle="View and Edit Client Details"
            icon={ <Edit height={20} width={20} /> }  
            disabled={ disabled }
          />
        }
      />

      <Cell 
        content={
          unreviewed ?
            <Button 
              plain 
              className="row-btn delete-client-btn"
              data-tip="Delete this client"
              size="small" 
              onClick={ deleteUnreviewedClient } 
              a11yTitle="View Client Messages"
              icon={ <Cancel height={20} width={20} /> }  
              disabled={ disabled }
            /> :
            <Button 
              plain 
              className="row-btn"
              // data-tip="View messages for this client"
              size="small" 
              onClick={ viewClientMessages } 
              a11yTitle="View Client Messages"
              icon={ <Calendar height={20} width={20} /> }  
              disabled={ disabled }
            />
        }
      />    
      
    </div>
  );
};