import styled from 'styled-components';
import { normalizeColor } from 'grommet/utils';
import { Sidebar } from 'grommet';

export const LeftSidebarStyled = styled(Sidebar)`  
  width: ${({ theme }) => `calc(100% - ${theme.global.edgeSize.medium})`};
  min-height: 0;
  height: calc(100vh - 10rem);
  overflow-y: auto;

  .nav-badge {
    position: absolute;
    top: -6px;
    right: -6px;
    border: none;
    background: ${({ theme }) => normalizeColor('blue10', theme)};  
    color: ${({ theme }) => normalizeColor('white', theme)};  
    font-weight: bold;
  }

  /* media queries -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
  @media (max-width: ${props => props.theme.global.breakpoints.medium.value}px) { 
    display: none;
  }  
`;