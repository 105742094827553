import { useState, useEffect } from 'react';

const getSessionStorageOrDefault = (key, defaultValue) => {
  const stored = window.sessionStorage && window.sessionStorage.getItem(key);
  if (!stored) {
    // console.log('no stored, ' + key)
    return defaultValue;
  }
  // console.log('return stored: ' + JSON.parse(stored));
  return JSON.parse(stored);
};

const setSessionStorage = (key, value) => {
  if (!window.sessionStorage) {
    return false;
  }
  window.sessionStorage.setItem(key, JSON.stringify(value));  
  return true;
};

// hook
export const useSessionStorage = (key, defaultValue) => {
  const [value, setValue] = useState(
    getSessionStorageOrDefault(key, defaultValue)
  );

  useEffect(() => {
    setSessionStorage(key, value);
  }, [key, value]);

  return [value, setValue];
};

// obj
export const sessionStorage = {
  get: (key, defaultValue) => {    
    //console.log('sessionstorage getting: ' + key);
    return getSessionStorageOrDefault(key, defaultValue);
  },
  set: (key, value) => {    
    //console.log('sessionstorage setting: ' + key + ' : ' + value);
    return setSessionStorage(key, value);
  }
};