import styled from 'styled-components';
import { Box } from 'grommet';

const noSidebarPath = (path) => {
  return path.includes('login') || 
      path.includes('signup') || 
      path.includes('changepw') || 
      path.includes('resetpw');
};

export const LayoutStyled = styled(Box)`
  display: grid;
  height: 100%;
  overflow: hidden;  

  grid-template-rows: repeat(auto-fit, 100%);
  grid-template-columns: ${ ({ path }) => {
    return noSidebarPath(path) ?
        '100%' : 
        '27rem 1fr';
  }};

  justify-items: ${ ({ path }) => {
    return noSidebarPath(path) ?
        'center' : 
        'flex-start';
  }};
  align-items: ${ ({ path }) => {
    return noSidebarPath(path) ?
        'center' : 
        'flex-start';
  }};

  && > section {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    padding-right: ${({ theme }) => theme.global.edgeSize.medium };
  }

  /* media queries -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
  @media (max-width: ${props => props.theme.global.breakpoints.medium.value}px) { 
    grid-template-rows: auto;
    grid-template-columns: 100%;

    && > section {
      padding-left: ${({ theme }) => theme.global.edgeSize.small };
      padding-right: ${({ theme }) => theme.global.edgeSize.small };
    }
  }
`;