import styled, { keyframes } from 'styled-components';
// import { normalizeColor } from 'grommet/utils';
import { Menu } from 'grommet';


const rotateAnim = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const SettingsNavStyled = styled(Menu)`
  overflow: hidden;
  border-color: ${({ theme }) => theme.global.colors.cyan8 };
  transition:
    width 1500ms linear,
    max-width 1500ms linear;
  svg {
    color: ${({ theme }) => theme.global.colors.cyan9 };
  }
  &&:hover {
    color: ${({ theme }) => theme.global.colors.cyan10 }; 
    border-color: ${({ theme }) => theme.global.colors.cyan9 };
    background: ${({ theme }) => theme.global.colors.cyan3 };    
  }
  &&:hover svg {
    color: ${({ theme }) => theme.global.colors.cyan10 };
  }
  &&:disabled {
    opacity: 1;
    color: ${({ theme }) => theme.global.colors.cyan10 }; 
    border-color: ${({ theme }) => theme.global.colors.cyan9 };  
  }
  &&:disabled svg {
    animation: 1500ms ${rotateAnim} linear infinite;
  }
`;