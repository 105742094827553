import { useEffect /*, useCallback */ } from 'react';
import { Link } from 'react-router-dom';
import { useModal } from '../modal/Modal';
import { Button, Paragraph, Anchor, Heading, Box } from 'grommet';
// import { useParams } from 'react-router-dom';

import { CloseModalButton } from '../modal/CloseModalButton.js';
import { useAxios /*, cache */ } from '../ajax/Ajax';
import { useContextVal } from '../auth/Auth.js';
import { useError } from '../errors/errors.js';
import { useSuccess } from '../success/success.js';
import { useLoading } from '../loading/loading.js';
import { ConnectFormStyled } from './ConnectForm.css.js';
import { DataTransferBoth } from 'iconoir-react';

const defaultErrMsg = 'An error occurred while connecting to Paperless Pipeline. Please refresh the page and try again.';
const successMsg = (isConnected) => {
  return `You have successfully ${ isConnected ? 'connected to' : 'disconnected from' } Paperless Pipeline.`;
};

export const ConnectForm = () => {
  const ctxt = useContextVal();  
  // const location = useLocation();
  // const from = location.state?.previousPath || '/';  
  const stripeConfig = ctxt?.user?.stripe_configured;
  const { Modal, opModal, clModal } = useModal();
  const isConnected = Number.isInteger(ctxt?.user?.pp_user_id);  
  const [
    { 
      data: getData, 
      // loading: getLoading, 
      error: getError
    }
  ] = useAxios(
    {
      url: 'paperless/connect/',
      method: 'GET'
    },
    { manual: false }
  );  
  const [
    { 
      data: postData, 
      loading: postLoading, 
      error: postError
    },
    executePost
  ] = useAxios(
    {
      url: `paperless/${isConnected ? 'disconnect' : 'connect'}/`,      
      method: 'POST'
    },
    { manual: true }
  );

  const { SuccessModal, setSuccess } = useSuccess(successMsg(isConnected));  
  const { ErrModal, onError } = useError(null, defaultErrMsg);
  const { Loading } = useLoading(
    postLoading, 
    'Loading...'
  );

  useEffect(() => {
    getData && !getData.success && onError(getData);
  }, [ getData, onError ]);   

  useEffect(() => {
    if (      
      postData?.success &&
      postData?.user
    ) {
      setSuccess(true);
      ctxt.updateUser(postData.user);  
      // cache.clear();    
      if (postData?.user?.pp_user_id) { // pp_user_id means connected
        opModal();   
      }
    }
    postData && !postData.success && onError(postData);
  }, [ postData, ctxt, onError, setSuccess, opModal ]);

  useEffect(() => {
    const error = getError ? 
      getError?.toJSON() : 
      postError?.toJSON();
    onError(error);
  }, [ getError, postError, onError ]);

  const connect = async () => {
    try {
      await executePost();
    } catch (err) {
      return onError({ message: 'error' }); // triggers global, default error msg
    }
  };

  return  (
    <ConnectFormStyled>   

      { /*
        getData?.connect_data?.company_name &&
        <Grid 
          margin={{ bottom: 'small' }}
          fill={ false }
          pad="small"        
          rows={['auto']}        
          columns={['auto', '1fr']}        
          className="form-info-box"
        >
      
          <Text size="xsmall">Paperless Pipeline Company Name:</Text>
          <Text size="xsmall" margin={{ left: 'xsmall' }}>{ getData.connect_data.company_name }</Text>        
        
        </Grid>  
      */} 

      <Paragraph
        size="small"
        margin={{ top: 'small', bottom: 'small' }}
      >
        { isConnected ? 
          <>Your { process.env.REACT_APP_NAME } account is currently connected to Paperless Pipeline. Buyers of eligible transactions (where you are the only agent on the buyer side) will now be shown on the <Link to="/clients">Manage Clients page</Link>.</> :
          <>Connecting your account to Paperless Pipeline will allow { process.env.REACT_APP_NAME } to detect and import buyers of eligible Pipeline transactions into your { process.env.REACT_APP_NAME } account. You can also add other clients manually on the <Link to="/clients">Manage Clients page</Link>.</>
        }
      </Paragraph>

      {
        getData?.is_logged_in === false && !isConnected ?

          <Box animation={['fadeIn', 'slideDown']} alignSelf="start"><Paragraph size="small" margin={{ vertical: 'small' }}>You'll need to log in to your Paperless Pipeline account first in order to connect - please <Anchor href="/accounts/login/?next=/archie/connect/ ">click here to Log In</Anchor>.</Paragraph></Box> : 
          getData ? 
            <Box animation={['fadeIn', 'slideDown']} alignSelf="start"><Button 
              primary
              onClick={ connect }
              size="small"
              gap="xsmall"   
              margin={{ vertical: 'small' }}               
              label={ <>Click to { isConnected ? 'disconnect from' : 'connect to' } Paperless&nbsp;Pipeline</> }
              icon={ <DataTransferBoth height={20} width={20} /> }   
            /></Box> : null
      }

      <Modal
        content={
          <>
            <Heading 
              level="2" 
              size="small" 
              color="h2"
              margin={{ vertical: 'xxsmall', horizontal: 'small' }}
            >
              Connected!
            </Heading>
            {
              stripeConfig ? 
                <>
                  <Paragraph size="small" margin="small">You have successfully connected to { process.env.REACT_APP_NAME }! There are no charges at this time. Going forward, you can confirm Pipeline clients or add new ones and pay for them at that time.</Paragraph>
                </> : 
                <>
                  <Paragraph size="small" margin="small">You have successfully connected to { process.env.REACT_APP_NAME }! There are no charges at this time. Going forward, you can confirm Pipeline clients or add new ones and pay for them at that time.</Paragraph>
                  <Paragraph size="small" margin={{ horizontal: 'small', top: 'none', bottom: 'small' }}>You'll need to add a credit card to activate clients &mdash; <Link to="/billing">add your credit card&nbsp;&rarr;</Link></Paragraph>
                </>
            }
  
            <CloseModalButton closeFunc={ clModal } />

          </>
        }  
      />

      <SuccessModal />   

      <ErrModal />

      <Loading />

    </ConnectFormStyled>
  )
};