import styled from 'styled-components';
import { normalizeColor } from 'grommet/utils';
import { Box, Heading } from 'grommet';

export const FormInfoBoxStyled = styled(Box)`  
  a {
    color: ${({ theme }) => normalizeColor('white', theme)}; 
    font-weight: 500;
    display: inline-block;
    border-radius: 6px;
    transition: color 150ms linear;
  }
  a:hover {    
    color: ${({ theme }) => normalizeColor('yellow5', theme)}; 
  }
`;

export const FormInfoBox = ({ content, background, title = null, boxProps = null }) => {
  return (
    <FormInfoBoxStyled
      background={ background }
      pad="small"
      round="xsmall"
      margin={{ bottom: 'small' }}
      basis="auto"
      flex={ false }
      { ...boxProps }
    >
      { title &&
        <Heading 
          level="4" 
          size="xsmall" 
          color="white"
          margin={{ top: 'none', bottom: 'xsmall', left: 'none', right: 'none' }}
        >{ title }</Heading>
      }
      { content }
    </FormInfoBoxStyled>
  )
};