import { LnkStyled, BtnLnkStyled } from './Lnk.css';

export const Lnk = ({ /*to, txt, className, badge = null, */ ...rest }) => {  
  return (
    <LnkStyled 
      // to={ to }
      // txt={ txt }
      // className={ className }
      // badge={ badge }
      { ...rest }
    />
  );
};

// only used for sign out btn
export const Btn = ({ content, ...props }) => {  
  return (
    <BtnLnkStyled 
      as="button"
      { ...props }
    >    
      { content }
    </BtnLnkStyled>
  );
};