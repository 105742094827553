import { useState, useEffect, useCallback } from 'react';
import { useModal } from '../modal/Modal.js';
import { Box, Text, Button } from 'grommet';
import { WarningCircledOutline } from 'iconoir-react';
import { concatErrors } from '../utilities/util.js';


export const formErr = (errors, errName) => {
  if (!errors[errName]?.message) {
    return;
  }
  return (
    <Box 
      // className="error-msg" 
      size="small"
      pad={{ vertical: 'xsmall', horizontal: 'small' }}
      margin={{ top: '-10px', bottom: '1rem'}}
      round="xxsmall"
      background="status-error"
    >
      <Text
        dangerouslySetInnerHTML={{ __html: errors[errName].message }} 
        a11yTitle={ errors[errName].message }
        size="small"
        // margin="xsmall"
        wordBreak="break-word"
        color="#fff"
        corner="xsmall"
      />

    </Box>
  );
};

export const useError = (
  setError, // only for form errors
  defaultErrorMsg = 'An error occurred, please refresh your browser and try again.',
  setFormErrors = null,
) => {
  const [ errorData, setErrorData ] = useState(null);
  const [ globalError, setGlobalError ] = useState(null);
  const { Modal, opModal, clModal } = useModal();
  
  useEffect(() => {      
    if (globalError && globalError.length) {      
      opModal();
    } else {      
      clModal();
    }
  }, [ opModal, clModal, globalError ]);

  const closeModal = useCallback(() => {
    setGlobalError(null);
  }, []);
  
  useEffect(() => {    
    // LOCAL ERRORS (network errors, etc)
    if (errorData?.message) {         
      setGlobalError(defaultErrorMsg);
    }

    // SERVER RETURNED ERRORS
    if (errorData?.errors) {      
      for (const err in errorData.errors) {        
        const errStr = concatErrors(errorData.errors[err]);  
        if (err === '__all__') {          
          setGlobalError(errStr);
        } else if (setError) {
          setError(err, { type: 'manual', message: errStr });
        }
      }
      if (setFormErrors) {
        setFormErrors(errorData.errors)
      }
    }    
  }, [ errorData, setError, setFormErrors, defaultErrorMsg ]);

  const ErrModal = () => {  
    return (
      <Modal 
        content={
          <Box 
            className="error-msg" 
            size="small"
            pad={{ vertical: 'small', horizontal: 'small' }}
            round="xxsmall"
          >

            <Box direction="row" justify="start" align="center" margin={{ bottom: 'small' }}>

              <WarningCircledOutline height={32} width={32} />

              <Text
                dangerouslySetInnerHTML={{ __html: globalError }} 
                a11yTitle={ globalError }
                size="small"
                wordBreak="break-word"
                corner="xsmall"
                margin={{ left: 'xsmall' }}
              />

            </Box>

            <Button 
              default 
              size="small" 
              color="cancel_btn" 
              onClick={ closeModal } 
              a11yTitle="Ok, close this." 
              label="Ok, Close This" 
              alignSelf="end" 
              margin={{ top: 'small' }}
            />   

          </Box>
        }
      />
    );
  };

  return { 
    ErrModal: ErrModal,
    onError: setErrorData 
  };
};
