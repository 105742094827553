import styled from 'styled-components';

export const ClientFiltersStyled = styled.form`
  fieldset {    
    border: none;
  }
  label {
    padding-left: ${({ theme }) => theme.global.edgeSize.xxsmall };
    font-size: ${({ theme }) => theme.text.xsmall.size };    
    line-height: ${({ theme }) => theme.text.xsmall.height };
  }  
  .checkbox-wrap {
    padding-bottom: ${({ theme }) => theme.global.edgeSize.xsmall};
  }
`;