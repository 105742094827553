import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, Grid } from 'grommet';
import { yupResolver } from '@hookform/resolvers/yup';
import { auditFilterSchema } from '../validation/validation.js';
import { SelectMultipleGroup, SelectGroup } from '../ui/SelectGroup.js';
import { InputGroup } from '../ui/InputGroup.js';
import { AuditLogFiltersStyled } from './AuditLogFilters.css.js';
import { Filter } from 'iconoir-react';

const formatQuery = (queryObj) => {
  return Object.keys(queryObj || {}).map((key) => {
    if (!queryObj[key]?.length) {
      return '';
    }
    return `&${encodeURIComponent(key)}=${encodeURIComponent(queryObj[key])}`;
  }).join('');
};

const formatEvents = (events) => {
  if (!events?.length) {
    return '';
  }  
  return events.reduce((acc, evt) => {
    return `${acc}&event=${encodeURIComponent(evt)}`;
  }, '');    
};

export const AuditLogFilters = ({ 
  setFilterQuery, 
  eventOpts,
  dateRangeOpts,
  getLoading,
  size,
}) => {
  const [ selectValue, setSelectValue ] = useState('');  
  const smallSize = size === 'small' || size === 'xsmall' || size === 'xxsmall';
  const formVals = useMemo(() => { 
    return {
      agent_name: '',
      client_name: '',
      created_at: '',
    };
  }, [])
  const {
    control,
    register,
    handleSubmit,       
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    defaultValues: formVals,    
    resolver: yupResolver(auditFilterSchema),
    delayError: 100
  });

  const onSubmit = async (data) => {        
    const filters = formatQuery(data);
    const events = formatEvents(selectValue);
    setFilterQuery(filters + events);
  };

  return  (
    <AuditLogFiltersStyled onSubmit={handleSubmit(onSubmit /*, onError */ )}>

      <Box 
        as="fieldset" 
        // margin={{ top: 'small', bottom: 'small' }}
        pad="none"
        align="start"
        direction="column"        
        round="xsmall"
        disabled={ getLoading }
      > 

        <Grid
          fill="horizontal"
          align="stretch"      
          rows={ smallSize ? ['auto', 'auto'] : ['auto'] }            
          columns={ smallSize ? [ '50%', '50%' ] : [ 'minmax(auto, 130px)', 'minmax(auto, 130px)', 'minmax(auto, 130px)', 'minmax(auto, 130px)', 'auto' ] }
          gap={ smallSize ? 'small' : 'xxsmall' }
          border={ false }
          pad="none"
        >         

          <SelectMultipleGroup            
            opts={ eventOpts }
            selectValue={ selectValue }
            setSelectValue={ setSelectValue }
            name="event"
            label="Event"            
            size="small"
            clear={ true }
            classNamePrefix="react-select"       
            disabled={ getLoading }            
          />   

          <SelectGroup        
            control={ control }    
            opts={ dateRangeOpts }
            errors={ errors }  
            name="created_at"
            label="Created"            
            size="small"
            clear={ true }
            classNamePrefix="react-select"       
            disabled={ getLoading }   
            // placeholder="Select"            
          />                

          <InputGroup
            type="text"
            name="client_name"
            label="Client Name"
            placeholder="June Smith"
            errors={ errors }
            register={ register }
            size="small"
          /> 

          <InputGroup
            type="text"
            name="agent_name"
            label="Agent Name"
            placeholder="John Smythe"
            errors={ errors }
            register={ register }
            size="small"
          />                     

          <Button 
            secondary             
            type="submit"
            alignSelf="end"
            size="small"              
            label="Filter Audit Logs" 
            icon={ <Filter height={20} width={20} /> }
            gap="xsmall"
          />                  

        </Grid>     

      </Box>

    </AuditLogFiltersStyled>
  );
};