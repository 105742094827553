import { useState, useCallback } from 'react';
import { Layer } from 'grommet'; // https://v2.grommet.io/layer
import { ModalStyled } from './Modal.css';

export const useModal = () => {
  const [ show, setShow ] = useState(false);

  const closeModal = useCallback(() => {
    setShow(false);
  }, []);

  const openModal = useCallback(() => {
    setShow(true);
  }, []);  

  const Modal = useCallback(({ content, id, initOpen = false }) => {
    return ( show || initOpen ) &&
      <Layer
        // onClickOutside={ closeModal }
        onEsc={ closeModal }
        // full="horizontal"
        margin="medium"
        background="light-1"
        animation="slide" // slide
      >
        <ModalStyled 
          pad="small"
        >            

          { content }

        </ModalStyled>

      </Layer>;
  }, [ show, closeModal ]);

  return { 
    Modal: Modal,
    opModal: openModal,
    clModal: closeModal,
    isOpen: show,
  };
};