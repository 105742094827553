import { useContext, useEffect, useState, useCallback } from 'react';
import ReactTooltip from 'react-tooltip';
import { Grid, Button, Collapsible, Heading, ResponsiveContext } from 'grommet';
import { useSearchParams } from 'react-router-dom';
/* ----- */
import { TemplateListItem, TemplateListHeader } from './TemplateListItem.js';
import { useAxios } from '../ajax/Ajax.js';
import { useModal } from '../modal/Modal.js';
import { CloseModalButton } from '../modal/CloseModalButton.js';
import { TemplateForm } from './TemplateForm.js';
import { TemplateMasterForm } from './TemplateMasterForm.js';
import { useContextVal } from '../auth/Auth.js';
import { useTemplateContext, initTemplates, initMasterTemplates } from '../context/template-context.js';
import { useError } from '../errors/errors.js';
import { useLoading } from '../loading/loading.js';
import { useSuccess } from '../success/success.js';
import { getTs, isBool, saveToLs, getFromLs } from '../utilities/util.js';
import { TemplateListStyled } from './TemplateList.css.js';
import { Plus, NavArrowDown, NavArrowUp } from 'iconoir-react';

const defaultErrMsg = 'An error occurred while accessing your templates. Please refresh the page and try again.';

const YearWrap = ({ templates, year, showYear }) => {
  const lsKey = `template-year-${year}`;
  const storedPref = getFromLs(lsKey);
  const [ show, setShow ] = useState(
    showYear ? true :
      isBool(storedPref) ? 
        storedPref : 
        ( year === 0 ? true : false )
  );
  const toggleYear = () => {
    setShow(!show);
    saveToLs(lsKey, !show);
  };
  return (
    <>
      <Heading 
        level="3"                 
        onClick={ toggleYear }
        className={`year-header ${ show && 'year-header-show' }`}
      >
        Year { year + 1 }&nbsp;{ show ? <NavArrowUp height={24} width={24} /> : <NavArrowDown height={24} width={24} /> }
      </Heading>
      <Collapsible open={ show } className="template-collapsible">
        { templates }
      </Collapsible>
    </>
  );
};

const yearArray = (templates, openModal, mobile, templateId = null) => {
  if (!templates?.length) {
    return null;
  }
  const ts = getTs();
  const yearsArr = [];
  let showYear = null;
  templates.forEach((template, index) => {
    const year = parseInt(template.year_number, 10);
    const templateComponent = <TemplateListItem 
      template={ template } 
      openModal={ openModal } 
      key={`template-${index}-${ts}`}     
      mobile={ mobile }
    />;
    if (templateId === template.id) {      
      showYear = year;
    }
    yearsArr[ year ] ? 
      yearsArr[ year ].push(templateComponent) : 
      yearsArr[ year ] = [ templateComponent ];
  })
  return yearsArr.map((templateArr, index) => {
    return <YearWrap 
      templates={ templateArr } 
      year={ index } 
      showYear={ showYear === index } 
      key={`year-section-${index}`}       
    />
  });
};

export const TemplateList = ({ master }) => {
  const ctxt = useContextVal();
  const isStaff = ctxt?.user?.is_staff;
  const size = useContext(ResponsiveContext);
  const mobile = size === 'xsmall' || size === 'xxsmall';
  const [ searchParams ] = useSearchParams(); 
  const templateId = parseInt(searchParams.get('template_id') || 0, 10);
  const [ loading, setLoading ] = useState(false);  
  const [ activeTemplate, setActiveTemplate ] = useState(null);
  const { Modal, opModal, clModal, isOpen } = useModal();
  const { templates, dispatch, masterTemplates, masterDispatch } = useTemplateContext();
  const [
    { 
      data: getData, 
      loading: getLoading, 
      error: getError
    }
  ] = useAxios(
    {
      url: master ? 
        'master-templates/' :
        'templates/',
      method: 'GET'
    },
    { manual: !ctxt?.user }
  );
  
  const { SuccessModal, setSuccess } = useSuccess();  
  const { ErrModal, onError } = useError(null, defaultErrMsg);
  const { Loading } = useLoading(getLoading, 'Loading templates...');

  const openModal = useCallback((modalTemplate) => {
    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 1000);
    setActiveTemplate(modalTemplate);
    opModal();
  }, [ opModal ]);

  const closeModal = useCallback(() => {
    clModal();
    setActiveTemplate(null);        
  }, [ clModal ] );  

  const newTemplate = () => {
    setActiveTemplate({ is_new: true });
    opModal();    
  };

  useEffect(() => {
    if (templateId) {      
      const findTemplate = templates.find( c => c.id === templateId );      
      if (findTemplate) {        
        setActiveTemplate(findTemplate);
        opModal();  
      }
    }
  }, [ templateId, templates, opModal ]);  

  useEffect(() => {
    if (!activeTemplate) {
      closeModal();
    };
  }, [ activeTemplate, closeModal ]);  

  useEffect(() => {
    if (
      getData?.success && 
      (
        getData?.master_templates ||
        getData?.templates
      )
    ) {
      getData?.master_templates ? 
        masterDispatch(initMasterTemplates(getData.master_templates)) :
        dispatch(initTemplates(getData.templates));
    }
    getData && !getData.success && onError(getData);
  }, [ getData, dispatch, masterDispatch, onError ]);     

  useEffect(() => {    
    onError(getError?.toJSON());
  }, [ getError, onError ]);

  useEffect(() => {
    ReactTooltip.hide();
    ReactTooltip.rebuild();
  }, [ templates, activeTemplate, isOpen ]);
  
  return  (
    <TemplateListStyled width={{ min: '28rem', max: '100rem' }}>     

      { isStaff && master && 
        <Button 
          secondary 
          size="small"
          onClick={ newTemplate }          
          label="Add New Template"
          alignSelf="end"
          margin={{ bottom: 'small' }}
          gap="xsmall"
          icon={ <Plus height={20} width={20} /> }
        />
      }

      { ( masterTemplates?.length > 0 || templates?.length > 0 )  &&
        <Grid
          fill="horizontal"          
          align="stretch"
          columns={ 
            mobile ? 
              ['auto', '32px'] :
              ['40%', '40%', '50px', 'auto', '44px']
          }
          border={ false }
          pad={{ top: 'none', right: 'none', bottom: 'large', left: 'none' }}
          className="template-grid"
        > 

        <TemplateListHeader mobile={ mobile } />

        { yearArray(( isStaff && master ? masterTemplates : templates ), openModal, mobile, templateId) }

        </Grid>
      }
      
      <Modal        
        content={
          <>

            { isOpen && activeTemplate && isStaff && master &&
              <TemplateMasterForm 
                ctxt={ ctxt }
                closeModal={ clModal }
                setLoading={ setLoading }
                activeTemplate={ activeTemplate } 
                setActiveTemplate={ setActiveTemplate }   
                setSuccess={ setSuccess }              
              />      
            }

            { isOpen && activeTemplate && ( !master || !isStaff ) &&
              <TemplateForm 
                ctxt={ ctxt }
                closeModal={ clModal }
                setLoading={ setLoading }
                activeTemplate={ activeTemplate } 
                setActiveTemplate={ setActiveTemplate }    
                setSuccess={ setSuccess }             
              />       
            }

            <CloseModalButton closeFunc={ closeModal } disabled={ loading } />

          </>
        }
      />     

      <ErrModal />   

      <Loading />

      <SuccessModal />

    </TemplateListStyled>
  )
};