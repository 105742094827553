import { Heading } from 'grommet';
import { ProfileForm } from './ProfileForm';
import { ProfileStyled } from './Profile.css';

export const Profile = () => {
  return  (
    <ProfileStyled 
      forwardedAs="section" 
      fill="horizontal"      
    >
      <Heading 
        level="1" 
        size="small" 
        color="h1"
        margin={{ vertical: 'small' }}
      >
        Profile
      </Heading>
      <ProfileForm />
    </ProfileStyled>
  )
};