import { useCallback } from 'react';
import { Box, Text, Spinner } from 'grommet';
import styled from 'styled-components';

export const LoadingStyled = styled(Box)` 
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
`;

export const useLoading = (
  isLoading = false,
  loadingMsg = 'Your request was successful.'
) => {
  const Loading = useCallback(() => {
    return isLoading ? (
      <LoadingStyled 
        className="loading-ui" 
        size="small"
        pad={{ vertical: 'medium', horizontal: 'medium' }}
        round="xsmall"
        background="white"
        align="center"
        border={{
          color: 'light_border_main',
          size: 'xsmall',
          style: 'solid',
          side: 'all'
        }}   
        elevation="medium"
      >
        <Text
          className="loading-ui-text"
          dangerouslySetInnerHTML={{ __html: loadingMsg }} 
          a11yTitle={ loadingMsg }
          size="medium"
          margin={{ bottom: 'medium' }}
          wordBreak="keep-all"
          color="teal_text_3"
          weight="bold"
          corner="xsmall"
          textAlign="center"
        />

        <Spinner
          color="teal_text_1"
          message={ loadingMsg }
          size="large"
        />
      </LoadingStyled>
    ) : null;
  }, [ isLoading, loadingMsg ]);

  return { 
    Loading: Loading,
    isLoading: isLoading,
  };
};