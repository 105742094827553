import { useEffect, useState } from 'react';
import {
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button } from 'grommet';

import { useContextVal } from '../auth/Auth.js';
import { InputGroup } from '../ui/InputGroup.js';
import { useAxios } from '../ajax/Ajax.js';
import { useError } from '../errors/errors.js';
import { useLoading } from '../loading/loading.js';
import { logInSchema } from '../validation/validation.js'
import { ShowPwButton } from '../ui/ShowPwButton.js';
import { LogInFormStyled } from './LogInForm.css.js';

const defaultErrMsg = 'An error occurred while logging in. Please refresh the page and try again.';

const defaultVals = {
  email: '',
  password: '',
};

// const defaultValsTest = {
//   email: 'janagent@pp.com',
//   password: '12345',
// };

export const LogInForm = ({ setLoading }) => {  
  const ctxt = useContextVal();
  const navigate = useNavigate();
  const location = useLocation();
  const [ showPw, setShowPw ] = useState(false);
  const from = location.state?.from?.pathname || '/clients';
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setFocus,
  } = useForm({
    mode: 'onTouched', //  onChange | onBlur | onSubmit | onTouched | all
    defaultValues: defaultVals,
    resolver: yupResolver(logInSchema),
    delayError: 250
  });  
  const [
    { 
      data: postData, 
      loading: postLoading, 
      error: postError
    },
    executePost
  ] = useAxios(
    {
      url: 'login/',
      method: 'POST'
    },
    { manual: true }
  );

  const { ErrModal, onError } = useError(setError, defaultErrMsg);
  const { Loading } = useLoading(
    postLoading, 
    'Loading...'
  );    

  const onSubmit = async (data) => {
    try {
      await executePost({ data: data });
    } catch (err) {
      return onError({ message: 'error' }); // triggers global, default error msg
    }    
  };

  useEffect(() => {
    if (
      postData?.success && 
      postData?.user
    ) {
      ctxt.signIn(postData, () => {      
        navigate(from, { replace: true });
      });
    }
    if (
      !postData?.success && 
      !postData?.user
    ) {    
      onError(postData);
    }
  }, [ postData, ctxt, navigate, from, onError ]);  

  useEffect(() => {    
    onError(postError?.toJSON());
  }, [ postError, onError ]);

  useEffect(() => {
    setFocus('email');
  }, [setFocus]);  

  useEffect(() => {
    setLoading(postLoading);
  }, [ setLoading, postLoading ])

  return  (
    <LogInFormStyled 
      onSubmit={handleSubmit(onSubmit /*, onError */ )}
    >

      <Box 
        as="fieldset"
        disabled={ postLoading }
        pad="medium"
        margin="none"
        round="small"        
        border={{
          color: 'light_border_login',
          size: 'xsmall',
          style: 'solid',
          side: 'all'
        }}       
      >

        <InputGroup
          label="Email"
          type="email"
          name="email"
          errors={errors}
          register={register}
        />

        <InputGroup
          label="Password"
          type={ showPw ? 'text' : 'password' }
          name="password"
          errors={ errors }
          register={ register }
          InputButton={ <ShowPwButton showPw={ showPw } setShowPw={ setShowPw } /> }
        />

        <Button 
          primary 
          size="small" 
          label="Log In" 
          type="submit" 
          margin={{ top: 'xsmall' }}           
        />

      </Box>

      <ErrModal />

      <Loading />   

    </LogInFormStyled>
  )
};