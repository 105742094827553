import { formErr } from '../errors/errors';
import { Box, TextInput, Text } from 'grommet';

export const InputGroup = ({ 
  type, 
  name, 
  label, 
  register, 
  errors, 
  autocomplete = 'off', 
  onChangeCb = null,
  InputButton = null,
  Warning = null,
  ...rest 
}) => {
  return (
    <>
      <Box         
        className="text-input-wrap"        
        direction="column"
      >
        <Text as="label" size="small">{ label }</Text>
        <TextInput           
          type={ type }
          a11yTitle={ label }
          size="small"
          autoComplete={ autocomplete } 
          focusIndicator={ true }
          { ...rest }
          { ...register(name, { 
            onChange: (e) => {          
              if (onChangeCb) {
                onChangeCb(e);
              }
            }            
          })}
        />

        { InputButton }

      </Box>  

      { Warning }

      { formErr(errors, name) }
    </>
  )
};