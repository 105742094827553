import styled from 'styled-components';
import { Layer } from 'grommet';

export const ArchieCardStyled = styled(Layer)`
    transition:
      height 1000ms linear,
      width 1000ms linear,
      min-height 1000ms linear,
      min-width 1000ms linear,      
      padding 1000ms linear,
      margin 1000ms linear;
    /* min-height: 300px; */
    min-width: calc(100% - 48px);

  .img-wrap {
    position: relative;
    /* padding: ${({ theme }) => theme.global.edgeSize.xsmall}; */
    transition:
      height 1000ms linear,
      width 1000ms linear,
      padding 1000ms linear,
      margin 1000ms linear;
  }
  .img-wrap img {
    display: block;
    box-sizing: border-box;
    padding: ${({ theme }) => theme.global.edgeSize.xsmall};
    flex: 1 1 auto;
    overflow: hidden;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: ${({ theme }) => theme.global.edgeSize.xsmall};
    overflow: hidden;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.5); 
    background: #fff;
    transition: opacity 500ms ease-in-out;
  }
  .img-wrap .archiecard-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    /* width: calc(100% - ${({ theme }) => theme.global.edgeSize.small});
    height: calc(100% - ${({ theme }) => theme.global.edgeSize.small}); */
  }
  .img-wrap .hide-placeholder {
    opacity: 0;
    pointer-events: none;
  }
  .spinner {
    position: absolute;
    bottom: 50%;
    left: 50%;
    margin-bottom: -36px;
    margin-left: -36px;
  }

  /* media queries -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
  @media (max-width: ${props => props.theme.global.breakpoints.small.value}px) { 
    min-width: calc(100% - ${({ theme }) => theme.global.edgeSize.small});
  }  
`;