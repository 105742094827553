import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useAxios } from '../ajax/Ajax';
import {
  useLocation,
  Navigate,
} from 'react-router-dom';

const isDev = process.env.NODE_ENV === 'development';

const AuthContext = React.createContext({
  user: null,
  settings: null,
  signin: null,
  signout: null,
  updateUser: null,  
  checkingAuth: false, 
  isDev: isDev,
});

const clearHistoryState = () => {
  if (window) {      
    window.history.replaceState({}, document.title);      
  }
}

export const useContextVal = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [ user, setUser ] = useState(null);
  const [ settings, setSettings ] = useState(null);
  const [ checkingAuth, setCheckingAuth ] = useState('init');
  const [
    { 
      data: getData, 
      loading: getLoading, 
      // error: getError
    },
  ] = useAxios(
    {
      url: 'auth/',
      method: 'GET'
    }
  );
  const [
    { 
      data: getSettingsData, 
      // loading: getSettingsLoading, 
      // error: getSettingsError
    },
    executeGetSettings
  ] = useAxios(
    {
      url: 'settings/',
      method: 'GET'
    },
    { manual: true }
  );  

  const getSettings = async () => {
    await executeGetSettings();    
  };

  const signIn = (postData, cb) => {
    if (postData?.user) {
      setUser(postData.user);
    }
    if (postData?.settings) {
      setSettings(postData.settings);
    } else {
      getSettings();
    }
    cb();
  };

  const signOut = (cb) => {
    setUser(null);
    setSettings(null);
    cb();
    clearHistoryState();
  };

  const updateUser = useCallback((updatedUser) => {
    setUser(updatedUser);
  }, []);

  useEffect(() => {
    clearHistoryState();
  }, []);

  useEffect(() => {
    if (getData?.user) {
      setUser(getData.user);
    }  
    if (getData?.settings) {
      setSettings(getData.settings);
    }        
  }, [ getData ]);

  useEffect(() => {    
    if (getSettingsData?.settings) {      
      setSettings(getSettingsData.settings);
    }
  }, [ getSettingsData?.settings ] );

  useEffect(() => {
    setCheckingAuth(getLoading ? 'inprogress' : 'done');
  }, [ getLoading ]);

  return (
    <AuthContext.Provider 
      value={{ 
        user,
        settings,
        updateUser,        
        signIn, 
        signOut,        
        checkingAuth,      
        isDev,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const RequireAuth = ({ children }) => {
  const auth = useContextVal();  
  const location = useLocation();
  if (auth.checkingAuth === 'done' && !auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace={true} />;    
  }  
  return children;
};

export const NoAuth = ({ children }) => {
  const auth = useContextVal();
  const location = useLocation();
  // const from = location.state?.from?.pathname || '/';  
  // console.log('noauth from: ' + from);
  if (
    auth.checkingAuth === 'done' && 
    auth.user &&
    (
      location?.pathname?.includes('login') ||
      location?.pathname?.includes('signup')
    )
  ) {    
    console.log('NoAuth redirect blocked...')
    // return <Navigate to="/" state={{ from: location }} replace={true} />;
  }
  return children;
};

