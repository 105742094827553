import { useState, useEffect, useContext } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Button, Box, Text, Grid, ResponsiveContext } from 'grommet';
/* ----- */
import { useClientAjax } from './ClientAjax.js';
import { ClientListItem, ClientListHeader } from './ClientListItem.js';
import { ClientForm } from './ClientForm.js';
import { ClientFilters } from './ClientFilters.js';
import { useContextVal } from '../auth/Auth';
import { useClientContext } from '../context/client-context.js';
import { useClientDelete } from './ClientDelete.js';
import { ClientToDo } from './ClientToDo.js';
import { FormInfoBox } from '../ui/FormInfoBox.js';
import { getTs } from '../utilities/util.js';
import { ClientListStyled } from './ClientList.css.js';
import { Plus } from 'iconoir-react';

export const ClientList = () => {
  const { clients } = useClientContext(); 
  const [ searchParams ] = useSearchParams();  
  const { add = null, tx_id = null } = useParams();
  const ctxt = useContextVal();
  const size = useContext(ResponsiveContext);
  const ts = getTs();
  const [ client, setClient ] = useState(null);
  const [ formErrors, setFormErrors ] = useState(null);  
  const clientId = searchParams.get('client_id');
  const smallSize = size === 'small' || size === 'xsmall' || size === 'xxsmall';
  const { AjaxComponents, loading, onGet, onPost, onDelete } = useClientAjax(
    ctxt, 
    tx_id, 
    client, 
    setClient, 
    setFormErrors
  );
  const { 
    DeleteModal, 
    deleteClient 
} = useClientDelete(onDelete);

  useEffect(() => {
    if (clientId && clientId.length) {
      const intClientId = parseInt(clientId, 10);
      const findClient = clients.find( c => c.id === intClientId ) || null;
      if (findClient) {
        setClient(findClient);
        ReactTooltip.hide();
      }
    }
  }, [ clientId, clients ]);

  useEffect(() => {
    // hide tooltip if showing or hiding client
    ReactTooltip.hide();
  }, [ client ]);

  useEffect(() => {
    if (add && !tx_id) {
      setClient({ is_new: true });    
    }
  }, [ add, tx_id ]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [ clients ]);

  return  (
    <ClientListStyled 
      gap="none"
      width={{ min: '28rem', max: '100rem' }}
    >

      <Box 
        fill="horizontal"
        direction="row"         
        gap="xsmall" 
        justify="between"
        margin={{ top: 'small', bottom: 'small' }}
        style={{ flex: '0 0 auto' }}  
      >      

        <ClientFilters 
          loading={ loading } 
          onGet={ onGet }
          size={ size }
        />

        <Button 
          secondary 
          size="small" 
          onClick={ () => setClient({ is_new: true }) } 
          label={ smallSize ? null : 'Add New Client' }
          alignSelf="end"  
          gap="xsmall"
          icon={ <Plus height={20} width={20} /> }
          disabled={ loading }
        />

      </Box>    

      { clients?.length > 0 &&
        <Grid
          fill="horizontal"
          rows={['auto']}
          align="stretch"
          columns={ 
            smallSize ? 
              ['auto', '32px', '32px'] :
              ['1.5fr', '1fr', '1fr', '0.6fr', '44px', '44px']
          }          
          border={ false }
          pad="none"
        > 
          <ClientListHeader mobile={ smallSize } />
          {
            clients?.map( (clientObj, key) => {                    
              return <ClientListItem 
                client={ clientObj } 
                setClient={ setClient } 
                key={`client-list-item-${key}-${ts}`} 
                deleteClient={ deleteClient }
                mobile={ smallSize }    
                disabled={ loading }            
              />;
            })
          }
        </Grid>
      }

      { clients && !clients.length && 
        <Box
          fill="horizontal"
          pad="small"
          direction="row" 
          round="xsmall"
          background="brand_light"
          justify="center"
          align="center"
        >
          <Text size="small">No clients found.</Text>
        </Box>
      }

      { 
        ( 
          ctxt?.user?.stripe_configured === false || 
          ctxt?.user?.return_address_configured === false 
        ) &&
        <FormInfoBox
          content={ <ClientToDo user={ ctxt?.user } /> }
          title="To-Do"
          background="blue10"
          boxProps={{ margin: { top: 'small' } }}
        />
      }

      { client && // force new component for each edit
        <ClientForm
          client={ client } 
          setClient={ setClient } 
          formErrors={ formErrors }
          mobile={ smallSize } 
          loading={ loading }      
          onPost={ onPost }  
          deleteClient={ deleteClient }
        />
      }

      <DeleteModal />
      
      <AjaxComponents />

    </ClientListStyled>    
  );
};