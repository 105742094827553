import { useState, useEffect } from 'react';
import { Button, Box, Text, Grid, Heading } from 'grommet';
import { EyeEmpty, ArrowUp } from 'iconoir-react';
import { formatDate, isBool } from '../utilities/util.js';

const headerArr = [
  'Event',
  'Client',
  'Agent',
  'Created',
  '',
];

const splitLines = (t) => { 
  return t.split(/\r\n|\r|\n/); 
};

const checkVal = (val) => { 
  if (isBool(val) || Number.isInteger(val)) {
    return val.toString();
  }
  return val;
};

const Cell = ({ isHeader = false, content }) => {
  return (
    <Box 
      className={`${ isHeader ? 'cell-header' : 'cell' } cell-client`}
      direction="row" 
      pad={{ horizontal: 'small', vertical: `${ isHeader ? 'xsmall' : 'small' }` }}
      align="center"
      justfiy="start"
    >
      { typeof content === 'object' ?
        content :
        <Text size="small" className="cell-text">
          { content }
        </Text>
      }
    </Box>
  );
};

export const AuditLogListHeader = () => {  
  return (
    <div className="row">
      {
        headerArr.map((headerText, key) => {                    
          return (
            <Cell 
              content={ headerText }
              isHeader={ true }
              key={`header-text-${key}`}                
            />
          );
        })
      }
    </div>
  );        
};

export const AuditLogListItem = ({ auditLog, index, showAllDetails }) => {  
  const [ showDetails, setShowDetails ] = useState(false);
  const viewDetails = () => {
    setShowDetails(d => !d);
  };
  const splitNotes = auditLog?.notes ? splitLines(auditLog.notes) : null;
  useEffect(() => {
    setShowDetails(showAllDetails);
  }, [ showAllDetails ]);
  return (
    <>
      {/* start top row */}
      <Box 
        className={`row ${ showDetails ? 'row-show-details' : ''}`}
        key={`audit-log-list-item-top-${index}`}
      >

        <Cell 
          content={ 
            <Text 
              size="small" 
              className="cell-text" 
              weight={ 500 }
            >
              { auditLog?.event || 'No Event' }
            </Text> 
          }                                   
        />

        <Cell 
          content={ 
            `${ auditLog?.client?.first_name || '' } ${ auditLog?.client?.last_name || '' }`
          }                                  
        />     

        <Cell 
          content={ 
            `${ auditLog?.agent?.first_name || '' } ${ auditLog?.agent?.last_name || '' }`
          }                          
        />     

        <Cell 
          content={ auditLog?.created_at ? formatDate(auditLog.created_at, true) : ''}                                  
        />                 

        <Cell 
          content={
            <Button 
              plain 
              className="row-btn"
              size="small" 
              onClick={ viewDetails } 
              a11yTitle="View Log Details"
              // label="View / Edit" 
              // margin={{ left: 'auto' }}
              icon={ showDetails ? <ArrowUp height={20} width={20} /> : <EyeEmpty height={20} width={20} /> }  
            />
          }
        />
      </Box>
      {/* end top row */}
      
      {/* start bottom row */}
      <Box className="row-audit-details" key={`audit-log-list-item-bottom-${index}`}>

        <div className={`row-details ${ showDetails && 'row-details-show'}`}>

          <Grid
            fill="horizontal"
            rows={['auto']}
            align="stretch"
            columns={['34%', '33%', '33%']}
            border={ false }
            pad="none"
          > 

          <Box className="log-col" pad="small">
            <Heading 
              level="3" 
              size="xsmall"               
              color="h2"
              margin={{ bottom: 'xsmall', top: 'none', left: 'none', right: 'none' }}
            >
              Notes
            </Heading>           
            {
              splitNotes?.map((note, i) => (
                <Text className="note-text" size="small" margin={{ bottom: 'xxsmall' }} key={`split-note-${ i }`}>{ note }</Text>
              ))
            }
          </Box>

          <Box className="log-col" pad="small">
            <Heading 
              level="3" 
              size="xsmall"               
              color="h2"
              margin={{ bottom: 'xsmall', top: 'none', left: 'none', right: 'none' }}
            >
              Client Data
            </Heading>              
            {
              Object.keys(auditLog?.client || {}).map((key) => (
                <Text size="small" margin={{ bottom: 'xxsmall' }} key={`client-${ key }`}>{key}: {checkVal(auditLog.client[key])}</Text>
              ))
            }
          </Box>

          <Box className="log-col" pad="small">
            <Heading 
              level="3" 
              size="xsmall"               
              color="h2"
              margin={{ bottom: 'xsmall', top: 'none', left: 'none', right: 'none' }}
            >
              Agent Data
            </Heading>             
            {
              Object.keys(auditLog?.agent || {}).map((key) => (
                <Text size="small" margin={{ bottom: 'xxsmall' }} key={`agent-${ key }`}>{key}: {checkVal(auditLog.agent[key])}</Text>
              ))
            }        
          </Box>

          </Grid>

        </div>

      </Box>
      {/* end bottom row */}
    </>
  );
}; 