import { Outlet } from 'react-router-dom';
import { Header } from './Header.js';
import { Footer } from './Footer.js';
import { LeftSidebar } from '../nav/LeftSidebar.js';
import { LayoutStyled } from './Layout.css.js';

const noSidebarPath = (path) => {
  return path.includes('login') || 
      path.includes('signup') || 
      path.includes('changepw') || 
      path.includes('resetpw');
};

export const Layout = ({ path }) => {
  return  (
    <>

      <Header />
      
      <LayoutStyled
        gridArea="main"  
        fill={ true }
        // pad={{ right: 'medium'}}
        forwardedAs="main"
        path={ path }
      >

        <LeftSidebar noSidebarPath={ noSidebarPath(path) } />

        <Outlet />

      </LayoutStyled>

      <Footer noSidebarPath={ noSidebarPath(path) } />

    </>
  );
};