import { css } from 'styled-components';
import { normalizeColor } from 'grommet/utils/index.js';

export const TableStyled = css`
  .row { 
    display: contents;    
  }
  .template-grid > div[class*="Collapsible"] { /* can't add class to collapsible grommet component - template view... */
    display: grid;
    align-items: stretch;
    grid-template-columns: 40% 40% 50px auto 44px;
    grid-column: 1 / 6;
  }
  .row-template-details,
  .row-audit-details,
  .year-header {        
    grid-column: 1 / 6; /* span 5 cols */
  }
  .row-client-details {        
    grid-column: 1 / 9;
  }  
  .year-header { 
    max-width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.text.medium.size };    
    line-height: ${({ theme }) => theme.text.medium.height };    
    box-shadow: inset 0 0 0 1px ${({ theme }) => normalizeColor('sage6', theme)};
    color: ${({ theme }) => normalizeColor('sage10', theme)};    
    margin: ${({ theme }) => theme.global.edgeSize.xsmall} 0 0 0;
    border-radius: ${({ theme }) => theme.global.edgeSize.xsmall};
    padding: ${({ theme }) => theme.global.edgeSize.xxsmall} ${({ theme }) => theme.global.edgeSize.small};
    transition:
      color 150ms linear,
      background 150ms linear,
      box-shadow 150ms linear;
  }
  .year-header-show,
  .year-header:hover {
    background: ${({ theme }) => normalizeColor('teal5', theme)};
    box-shadow: inset 0 0 0 1px ${({ theme }) => normalizeColor('teal5', theme)};
    color: ${({ theme }) => normalizeColor('teal11', theme)};  
  }
  .row-audit-details,
  .row-client-details {        
    box-shadow: inset 0 0 0 1px ${({ theme }) => normalizeColor('sage7', theme)};
    border-radius: ${({ theme }) => theme.global.edgeSize.xsmall};
    background: ${({ theme }) => normalizeColor('sage2', theme)};
  }
  .row-client-details {
    opacity: 0;
    transition: 
      transform 250ms ease-in-out, 
      opacity 250ms ease-in-out;
  }
  .row-show-details + .row-client-details {
    opacity: 1;
    transform: translateY(-6px);
  }
  .single-client-view .row-client-details {       
    grid-column: 1 / 6;
  }
  .cell-client {
    border-bottom: 0.1rem solid ${({ theme }) => normalizeColor('sage5', theme)};
    transition: border 150ms linear;
  }
  .cell-status-block {
    justify-content: space-between;
  }
  .row-show-details .cell-client {
    border-bottom: 0.1rem solid rgba(255,255,255,0);
  }
  .row:last-child .cell-client {
    border-bottom: none;
  }  
  ${'' /* .row-unreviewed .cell-client {
    background: ${({ theme }) => normalizeColor('blue3', theme)};
    border-bottom-color: ${({ theme }) => normalizeColor('blue6', theme)};
  } */}
  .cell-header {
    background: ${({ theme }) => normalizeColor('sage3', theme)};
    border: none;
  }
  .cell-header:first-child {
    border-top-left-radius: ${({ theme }) => theme.global.edgeSize.xsmall};
    border-bottom-left-radius: ${({ theme }) => theme.global.edgeSize.xsmall};
  }
  .cell-header:last-child {
    border-top-right-radius: ${({ theme }) => theme.global.edgeSize.xsmall};
    border-bottom-right-radius: ${({ theme }) => theme.global.edgeSize.xsmall};
  }
  .cell-text {
    line-height: 1.2;
  }
  .cell-text-unreviewed {
    color: ${({ theme }) => normalizeColor('blue11', theme)};
  }
  .cell-status {
    cursor: pointer;
    flex: 0 0 auto;
    background: ${({ theme }) => normalizeColor('sage4', theme)};
  }
  .cell-status > span {
    display: flex;
    align-items: center;
  }
  .cell-status-active {
    background: ${({ theme }) => normalizeColor('green6', theme)};    
  }
  .cell-status-unreviewed {
    background: ${({ theme }) => normalizeColor('blue10', theme)};    
    color: ${({ theme }) => normalizeColor('white', theme)};          
  }  
  .row-btn svg {
    color: ${({ theme }) => theme.global.colors['sage9']};
    transition: color 250ms linear;
  }
  .row-btn:hover svg {
    color: ${({ theme }) => theme.global.colors['sage12']};
  }  
  .text-display {
    max-width: 100%;
    white-space: pre-line;    
  }
  .row-template-details .cell-template {
    border: 0.1rem solid ${({ theme }) => normalizeColor('sage6', theme)};
    border-radius: ${({ theme }) => theme.global.edgeSize.xsmall};
    max-width: 46rem;
    width: 100%;
  }
  .note-text {
    word-break: break-word;
  }
  .row-audit-details h3,
  .row-client-details h3 {
    font-size: ${({ theme }) => theme.text.small.size };    
    line-height: ${({ theme }) => theme.text.small.height };
    border-bottom: 1px solid ${({ theme }) => theme.global.colors['sage8']};
    padding-bottom: ${({ theme }) => theme.global.edgeSize.xxsmall};
    max-width: 100%;
  }
  .row-error .cell {
    background: ${({ theme }) => theme.global.colors['tomato4']};
  }
  .badge {
    border: none;    
    transform: translateX(-5px);
    flex: 0 0 auto;
    background:  ${({ theme }) => normalizeColor('white', theme)};
  }  
  .badge-error svg {
    color: ${({ theme }) => normalizeColor('status-critical', theme)};  
  }
  .badge-skipped svg {
    color: ${({ theme }) => {
      // console.log(theme.global.breakpoints)
      return normalizeColor('blue10', theme)
    }};  
  }  
  .cell-text-with-badge {
    display: flex;
    align-items: center;
  }
  .table-link {
    cursor: pointer;
    text-decoration: underline;
    color: ${({ theme }) => theme.global.colors['sage12']};
  }
  .table-link:hover {
    color: ${({ theme }) => theme.global.colors.blue11 };
  }  
  /* media queries -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
  @media (max-width: ${props => props.theme.global.breakpoints.medium.value}px) { 
    .row-client-details {        
      grid-column: 1 / 7;
    }  
  }
  @media (max-width: ${props => props.theme.global.breakpoints.xsmall.value}px) { 
    .row-client-details {        
      grid-column: 1 / 5;
    }
    .single-client-view .row-client-details {       
      grid-column: 1 / 4;
    }    
    .template-grid > div[class*="Collapsible"] {
      grid-template-columns: auto 32px;
      grid-column: 1 / 3;
    }    
    .year-header,
    .row-template-details {        
      grid-column: 1 / 3;
    }
  }
`;