import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Box, Text, ResponsiveContext } from 'grommet'; 
import { Menu } from 'iconoir-react';
/* ----- */
import { Lnk } from './Lnk.js';
import { useContextVal } from '../auth/Auth.js';
import { SettingsNav } from './SettingsNav.js';
import { SettingsNavStyled } from './SettingsNav.css.js';
import { TopNavStyled } from './TopNav.css.js';


const LoginNav = () => {
  const size = useContext(ResponsiveContext);
  const navigate = useNavigate();  
  const navBtnClick = (route) => {
    navigate(route);
  };

  if (size !== 'xxsmall') {
    return (
      <>
        <Lnk to="login" txt="Log In" className="log-in-btn" />

        <Lnk to="signup" txt="Sign Up" className="sign-up-btn" />
      </>
    );
  }

  return (
    <SettingsNavStyled
      // open={ open }
      className="settings-nav"
      label={ null }
      size="small"      
      icon={ <Menu height={20} width={20} /> }      
      dropAlign={{ top: 'bottom', right: 'right' }}
      dropProps={{
        background: 'cyan3',
        margin: { top: 'xxsmall' },
        round: 'xsmall',        
      }}      
      items={    
        [{ 
          label: 'Log In', 
          onClick: () => navBtnClick('/login'),
          plain: true,
          size: 'small',
          className: 'settings-nav-btn'
        },
        { 
          label: 'Sign Up', 
          onClick: () => navBtnClick('/signup'),
          plain: true,
          size: 'small',
          className: 'settings-nav-btn'
        }]
      }
    />
  );
};

export const TopNav = () => {
  const ctxt = useContextVal();
  useEffect(() => {
    setTimeout(() => {      
      ReactTooltip.rebuild();
    }, 1000);    
  }, []);
  return  (
    <TopNavStyled
      align="center"
      justify="end"
      fill={ true }
      direction="row"      
      flex={ true }
      pad={{ top: 'xsmall', bottom: 'xsmall' }}      
    >
      { ctxt.user ?

        <>

          <Box 
            className="top-nav-info"
            direction="column"
            align="end"
            margin={{ right: 'small' }}          
          > 
            
            { ctxt?.user?.first_name && 
              <Text size="xsmall">
                {`${ctxt.user.first_name} ${ctxt.user.last_name}`}
              </Text> 
            }
                        
          </Box>

          <SettingsNav />

        </> : <LoginNav />

      }

    </TopNavStyled>
  )
};