import { Button, Box, Text, Paragraph } from 'grommet';
import { Edit } from 'iconoir-react';

const headerArr = (mobile) => {
  return [
    { text: 'Message', opts: {} },
    ...(!mobile ? [{ text: 'Description', opts: {} }] : [] ),
    ...(!mobile ? [{ text: 'Year', opts: { justify: 'center' } }] : [] ),
    ...(!mobile ? [{ text: 'Type', opts: {} }] : [] ),    
    { text: '', opts: {} },    
  ];
};

const defaultCellPadding = { left: 'small', right: 'small', top: 'small', bottom: 'xxsmall' };

const Cell = ({ 
  content,
  isHeader = false, 
  opts = {}, 
  pad = defaultCellPadding 
}) => {
  return (
    <Box 
      className={`${ isHeader ? 'cell-header' : 'cell' } cell-template`}
      direction="row"       
      pad={ pad }
      align="center"
      { ...opts }
    >
      { typeof content === 'object' ?
        content :
        <Text size="small" className="cell-text">
          { content }
        </Text>
      }
    </Box>
  );
};

export const TemplateListHeader = ({ mobile }) => {  
  return (
    <div className="row">
      {
        headerArr(mobile).map(({ text, opts }, key) => {                   
          return (
            <Cell 
              content={ text }
              isHeader={ true }
              pad={{ horizontal: 'small', vertical: 'xsmall' }}
              key={`header-text-${key}`}    
              opts={ opts }
            />
          );
        })
      }
    </div>
  );        
};

export const TemplateListItem = ({ template, openModal, mobile }) => {  
  const editTemplate = () => {
    openModal(template);
  };
  return (
    <>
      {/* start top row */}
      <Box className="row">

        <Cell         
          content={ 
            <>
              <Text 
                size="small"
                weight={ 500 }                
              >
                { template.name }
              </Text>

              <Box 
                direction="row"
                round="xsmall"
                margin={{ left: 'xsmall' }}
                pad={{ left: 'small', right: 'small', top: 'xxsmall', bottom: 'xxsmall' }}
                className={`cell-status ${ template.is_enabled ? 'cell-status-active' : ''}`}
              >
                <Text size="xsmall">{ template.is_enabled ? 'Active' : 'Inactive' }</Text>
              </Box>
            </>
          }                                        
        />

        { !mobile &&
          <>
            <Cell 
              content={ template?.description ? template.description : ''}                                     
            />

            <Cell 
              content={ 
                template?.year_number >= 0 ? 
                  ( template.year_number + 1 ) : 
                  ''
              }
              opts={{ justify: 'center' }}                                
            />

            <Cell 
              content={ template?.type === 'sms' ? 'Text' : 'Archie Card' || ''}
              opts={{
                'data-tip': template?.type === 'sms' ? 
                  null : 
                  'Folded note card with picturesque image on front and your message inside.' 
              }}                                   
            />
          </>
        }

        <Cell 
          content={
            <Button 
              plain 
              className="row-btn"
              size="small" 
              onClick={ editTemplate } 
              a11yTitle="View / Edit Template"
              // data-tip="View / edit message template"
              icon={ <Edit height={20} width={20} /> }  
            />
          }          
        />
      </Box>
      {/* end top row */}
      
      {/* start messasge text */}
      <Box className="row-template-details" margin={{ bottom: 'medium' }}>
        <Cell 
          content={ 
            <Paragraph 
              size="small" 
              className="text-display" 
              margin="none"
              onClick={ editTemplate } 
            >
            {template?.text ? template.text : ''}
          </Paragraph>
          }
          pad="small"
        />    
      </Box>
      {/* end messasge text */}
    </>
  );
};