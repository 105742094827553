import * as yup from 'yup';
// import valid from 'card-validator';
// https://github.com/jquense/yup

const states = [ 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY' ];

// custom validations
const pwTest = {
  name: 'pw_test',
  message: 'Passwords must be at least 5 characters long.',
  test: (val) => {
    if (val === undefined) {
      return true;
    }
    return val.length === 0 || val.length >= 5;
  }
};

const stateTest = {
  name: 'state_test',
  message: 'Invalid 2-letter state abbreviation.',
  test: (val) => {
    if (val === undefined || val.length > 2) { // if already existing state has more than 2 letters, disabled validation
      return true;
    }
    return val.length === 0 || ( val.length === 2 && states.includes( val.toUpperCase() ) );
  }
};

// log in
export const logInSchema = yup.object({
  email: yup.string().email('Must be a valid email.').required('Email is required.'),
  password: yup.string().min(5, 'Password must be at least 5 characters long.').required('Password is required.'),
}).required();

// sign up
export const signUpSchema = yup.object({
  first_name: yup.string().trim().required('First name is required.'),
  last_name: yup.string().trim().required('Last name is required.'),
  email: yup.string().trim().email('Must be a valid email').required('Email is required.'),
  backup_email: yup.string().trim().email('Must be a valid email.'),
  phone: yup.string().trim().min(7, 'Not a valid phone number').required('Cell phone number is required.'),
  brokerage: yup.string().trim(),
  password: yup.string().trim().min(5, 'Password must be at least 5 characters long.').required('Password is required.'),
  confirm_password: yup.string().trim().test(pwTest).oneOf([yup.ref('password')], 'Passwords must match.').required('Confirm New Password is required.'),
  tos: yup.boolean().oneOf([true], 'The terms of service must be accepted to continue'),
});

// address
export const addressSchema = yup.object({
  address: yup.string(),
  address2: yup.string(),
  city: yup.string(),
  state: yup.string().trim().test(stateTest),
  zip_code: yup.string(),
  // address: yup.string().required('Address is required.'),
  // address2: yup.string(),
  // city: yup.string().trim().required('City is required.'),
  // state: yup.string().trim().min(2, 'State must be the entire state name or a 2-letter abbreviation.').required('State is required.'),
  // zip_code: yup.string().trim().min(3, 'Invalid zip code.').required('Zip code is required.'),
});

// inherit from signUpSchema, override password + confirm password
export const profileSchema = signUpSchema.shape({
  agent_return_name: yup.string(), //.trim().required('Agent from name is required.'),
  password: yup.string().trim().test(pwTest),
  confirm_password: yup.string().trim().test(pwTest).oneOf([yup.ref('password')], 'Passwords must match.'), 
}).concat(addressSchema);

// template schema
export const templateSchema = yup.object({  
  text: yup.string().trim().min(3, 'Not a valid message.').required('Message is required.'),
  is_enabled: yup.boolean(),
});

// admin template schema
export const templateMasterSchema = yup.object({
  offset_days: yup.number().integer('Offset days must be an integer.').required('Offset days is required.'),
  year_number: yup.number().integer('Year number must be an integer.').required('Year number is required.'),
  name: yup.string().trim().min(3, 'Not a valid name.').required('Name is required.'),  
  text: yup.string().trim().min(3, 'Not a valid message.').required('Message is required.'),  
  type: yup.string().trim().required('Type is required.'),
  trigger: yup.string(), // can be empty str, dont use required
  is_enabled: yup.boolean(),
});

// client schema
export const clientSchema = yup.object({
  first_name: yup.string().trim().required('First name is required.'),
  last_name: yup.string().trim().required('Last name is required.'),
  email: yup.string().trim().email('Must be a valid email.').required('Email is required.'),
  phone: yup.string().trim().min(7, 'Not a valid phone number.').required('Cell phone number is required.'),
  brokerage: yup.string().trim(),
  is_enabled: yup.boolean(),
  sale_price: yup.string().trim(),
  close_date: yup.string().trim().nullable(true), // .test(startCloseDateDiffTest)
  start_date: yup.string().trim().nullable(true), // .test(startCloseDateDiffTest) // .test(startDateTest)  
}).concat(addressSchema);

// filter client schema
export const clientFilterSchema = yup.object({
  name: yup.string(),
  needs_attention: yup.boolean(),
});

// filter client schema
export const auditFilterSchema = yup.object({  
  // event: yup.array().of(yup.object({
  //   label: yup.string(),
  //   value: yup.string(),
  // })),
  client_name: yup.string().trim(),
  agent_name: yup.string().trim(),
});

// filter client logs schema
export const clientLogFiltersSchema = yup.object({  
  client_message_filter: yup.string().trim().required(),
});

// change password
export const changeSchema = yup.object({
  password: yup.string().min(5, 'Password must be at least 5 characters long.').required('Password is required.'),
  confirm_password: yup.string().min(5, 'Password must be at least 5 characters long.').oneOf([yup.ref('password')], 'Passwords must match.').required('Confirm Password is required'),
}).required();

// reset password
export const resetSchema = yup.object({
  email: yup.string().email('Must be a valid email').required(),  
}).required();