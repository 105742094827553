import styled from 'styled-components';
import { Footer } from 'grommet';

export const FooterStyled = styled(Footer)`
  width: 24.6rem;
  position: fixed;
  left: 0;
  bottom: 0;

  .gray-footer-text {
    color:  ${({ theme }) => theme.global.colors['sage8']};
  }

  /* media queries -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+- */
  @media (max-width: ${props => props.theme.global.breakpoints.medium.value}px) {   
    width: 100%;
    position: relative;    
    margin-top: ${({ theme }) => theme.global.edgeSize.medium };

    &.no-sidebar-footer {      
      justify-content: center;
      padding: 0;
    }

    br {
      display: none;
    }
  }
  @media (max-width: ${props => props.theme.global.breakpoints.small.value}px) {   
    padding: 0 ${({ theme }) => theme.global.edgeSize.small };
  }
`;