import { Heading } from 'grommet';
import { SignUpForm } from './SignUpForm';
import { SignUpStyled } from './SignUp.css';

export const SignUp = () => {
  return  (
    <SignUpStyled
      forwardedAs="section"
      justify="start"
      align="center"
      pad="medium"
    >
      <Heading 
        level="1" 
        size="small" 
        color="h1"
        margin={{ vertical: 'small' }}
      >
        Sign Up
      </Heading>
      <SignUpForm />
    </SignUpStyled>
  )
};