import { useState, useEffect, useContext } from 'react';
import { useSearchParams, useParams, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Grid, Pagination, Heading, Box, Text, ResponsiveContext } from 'grommet';
import { UserCircleAlt } from 'iconoir-react';
/* ----- */
import { formatDate, getTs } from '../utilities/util.js';
import { useAxios } from '../ajax/Ajax.js';
import { useError } from '../errors/errors.js';
import { useLoading } from '../loading/loading.js';
import { ClientLogListHeader, ClientLogListItem } from './ClientLogListItem.js';
import { ClientLogFilters } from './ClientLogFilters.js';
import { ClientLogListStyled } from './ClientLogList.css.js';

const defaultErrMsg = 'An error occurred while accessing your client messages. Please refresh the page and try again.';

const tableCols = (size, singleClientView) => {  
  if (singleClientView) {
    return ( size === 'xsmall' || size === 'xxsmall' ) ? 
      ['auto', 'auto', '32px'] :
      ['auto', 'auto', 'auto', 'auto', '44px'];
  }
  return size === 'large' ? 
    ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', '44px' , '44px'] :
    ( size === 'xsmall' || size === 'xxsmall' ) ?
        ['auto', 'auto', '32px' , '32px'] :
        ['auto', 'auto', 'auto', 'auto', '32px' , '32px'];
};

export const ClientLogList = () => {  
  const ts = getTs();
  const { view = null } = useParams();
  const path = view === 'sent' ? 'messages' : 'pending';
  const size = useContext(ResponsiveContext);  
  const [ searchParams ] = useSearchParams();
  const clientId = searchParams.get('client_id');
  const clientName = searchParams.get('client_name');
  const clientStartDate = searchParams.get('client_start_date');
  const clientCloseDate = searchParams.get('client_close_date');
  const singleClientView = clientId && clientId.length;
  // const [ filterQuery, setFilterQuery ] = useState(null);  
  const [ clientLogs, setClientLogs ] = useState(null);
  const [ pageObj, setPageObj ] = useState({ 
    page: 1, 
    pages: 1,
    per_page: 0,
    total: 0,
  });
  const [
    { 
      data: getData, 
      loading: getLoading, 
      error: getError
    },
    // executeGet
  ] = useAxios(
    {
      url: singleClientView ? 
      `clients/${ clientId }/${ path }/` :
      `clients/${ path }/`,
      method: 'GET',
      params: { page: pageObj.page /*, ...filterQuery */ },
    },
    { manual: !view?.length || !path?.length }     
  );  

  const { ErrModal, onError } = useError(null, defaultErrMsg);
  const { Loading } = useLoading(
    getLoading, 
    'Loading Client Messages...'
  );

  const paginate = ({ page, startIndex, endIndex }) => {
    if (page !== pageObj.page) {
      setPageObj(curr => {
        return {
          ...curr,
          ...{ page: page },
        }
      });
    }
  };
  
  useEffect(() => {
    if (      
      getData?.success && 
      getData?.clientmessages
    ) {      
      setClientLogs(getData.clientmessages);
      setPageObj({
        page: getData?.page || 0,
        pages: getData?.pages || 0,
        total: getData?.total || 0,
        per_page: getData?.per_page || 0,
      });
    }    
    getData && !getData.success && onError(getData);
  }, [ getData, onError ]);  

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [ clientLogs ]);

  useEffect(() => {        
    onError(getError?.toJSON());
  }, [ getError, onError ]);

  
  return  (
    <ClientLogListStyled 
      gap="none"
      width={{ min: '28rem', max: '100rem' }}
      height={{ min: '30rem' }}
    >

      {/* <Text>
        // https://storybook.grommet.io/?path=/story/controls-pagination-grid--paginated-grid
        Showing {indices[0] + 1} - {indices[1]} of {data.length}
      </Text> */}

      <Box 
        align="center"
        justify="between" 
        direction="row-responsive"
        align-content="between"
        alignSelf="start"
        basis="auto"
        fill="horizontal"
        flex={ false }
        gap="none"
      >

        <Box>

          <Heading 
            level="1" 
            size="small" 
            color="h1"
            margin={{ vertical: 'small' }}
          >
            <strong>{ view === 'sent' ? 'Sent' : 'Pending' }</strong> Client Messages
          </Heading>

          { singleClientView &&

            <Box direction="row" margin={{ top: 'xxsmall', bottom: 'small' }} pad="none" className="client-header">

              <UserCircleAlt height={24} width={24} />

              <Text className="client-header-text" data-tip="Click to view client">
                <Link 
                  to={`/clients/?client_id=${ clientId || '' }`}
                >{ clientName }</Link>
              </Text>

              { clientStartDate && <Text className="client-header-text">start date: <strong>{ formatDate(clientStartDate) }</strong></Text> }

              { clientCloseDate && <Text className="client-header-text">close date: <strong>{ formatDate(clientCloseDate) }</strong></Text> }
              
            </Box>          
          }

        </Box>

      </Box>

      <Box 
        fill="horizontal"
        direction="row"         
        gap="xsmall" 
        justify="between"
        margin={{ top: `${ singleClientView ? 'medium' : 'small' }`, bottom: 'small' }}
        style={{ flex: '0 0 auto' }}  
        className="table-top-ui-wrap"
      >

        <ClientLogFilters
          view={ view }
          getLoading={ getLoading }
        />

        { clientLogs?.length > 0 && pageObj?.pages > 1 &&
  
          <Pagination 
            className="pagination"
            a11yTitle="pagination ui"
            pad={{ top: 'none', right: 'none', bottom: 'none', left: 'small' }}
            margin="none"
            page={ pageObj?.page }
            numberEdgePages={ 1 }            
            numberItems={ pageObj?.total }
            numberMiddlePages={ 3 }
            step={ pageObj?.per_page }
            onChange={ paginate }
            size="small"
            alignSelf="end"
            style={{ pointerEvents: `${ getLoading ? 'none' : 'all'}` }}
          /> 

        }

      </Box>

      { clientLogs?.length > 0 &&
        <Grid
          fill="horizontal"
          rows={['auto']}
          align="stretch"
          columns={ tableCols(size, singleClientView) }
          border={ false }
          pad="none"
          className={ singleClientView ? 'single-client-view' : 'all-clients-view' }
        > 
          <ClientLogListHeader singleClientView={ singleClientView } />
          {
            clientLogs?.map( (clientLog, index) => {                    
              return (
                <ClientLogListItem 
                  clientLog={ clientLog } 
                  key={`client-log-list-item-${index}-${ts}`} 
                  singleClientView={ singleClientView } 
                  size={ size }
                />
              );
            })
          }
        </Grid> 
      }

      { clientLogs && !clientLogs.length && 
        <Box
          fill="horizontal"
          pad="small"
          direction="row" 
          round="xsmall"
          background="brand_light"
          justify="center"
          align="center"
        >
          <Text size="small">No client messages found.</Text>
        </Box>
      }  

      <Loading /> 

      <ErrModal />

    </ClientLogListStyled>    
  );
};