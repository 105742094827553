import { useState, useEffect, useCallback, useContext } from 'react';
import { Box, Text, Button, Image, Spinner, Grid, ResponsiveContext } from 'grommet';
import { useAxios } from '../ajax/Ajax.js';
import { useError } from '../errors/errors.js';
import { ArchieCardStyled } from './ArchieCard.css.js';
import archieCardPlaceholder from '../../img/postcard-placeholder.png';

const defaultErrMsg = 'An error occurred while previewing the Archie card.';

export const useImgPreview = ({ 
  text = null, 
  image = null, 
  master = false 
}) => {
  const size = useContext(ResponsiveContext);
  const mobile = size === 'xsmall' || size === 'xxsmall';
  const [ showPreview, setShowPreview ] = useState(false);
  const [ imgUrls, setImgUrls ] = useState(null);
  const [
    { 
      data: postData,
      // loading: postLoading, 
      // error: postError
    },
    executePost,
    cancelPost,
  ] = useAxios(
    {
      url: `${ master ? 'master-templates' : 'templates' }/preview/`,
      method: 'POST'
    },
    { manual: true }
  );  

  const { ErrModal, onError } = useError(null, defaultErrMsg);

  const closePreview = useCallback(() => {
    cancelPost();
    setShowPreview(false);
    setTimeout(() => {      
      setImgUrls(null);
    }, 500); 
  }, [ cancelPost ]);

  const getImages = useCallback(() => {
    executePost({ data: { 
      text: text, 
      image: image,
    }});
  }, [ executePost, text, image ]);

  useEffect(() => {
    if (showPreview) {    
      getImages();
    }
  }, [ showPreview, getImages ]);

  useEffect(() => {
    if (      
      postData?.success
    ) {
      setImgUrls({
        front: postData.front,
        inside: postData.inside
      });
    }    
    postData && !postData.success && onError(postData);
  }, [ postData, onError ]);    

  const ImgPreviewModal = useCallback(() => {
    return showPreview && (
      <ArchieCardStyled            
        background="sage3"
        margin={ mobile ? 
          { vertical: 'medium', horizontal: 'xsmall' } :
          { vertical: 'medium', horizontal: 'medium' }
        }
        onEsc={ closePreview }
        // onClickOutside={ closePreview }
        responsive={ false }          
        full="horizontal"
        animate={ false }
      >

        <Grid
          fill={ true }
          rows={['auto']}
          align="stretch"
          columns={ mobile ? ['100%'] : ['50%', '50%'] }
          areas={ mobile ? 
            [
              ['text1'],
              ['img1'],
              ['text2'],
              ['img2'],
              ['btns'],
            ] : 
            [
              ['text1', 'text2'],
              ['img1', 'img2'],
              ['btns', 'btns'],
            ] 
          }
          border={ false }
          pad={{ vertical: 'small', horizontal: 'small' }}
        >           

          <Text gridArea="text1" size="small" margin={{ bottom: 'xsmall', left: 'xsmall' }}>Archie Card Front</Text>
          
          <Box     
            gridArea="img1"      
            className="img-wrap"
            margin={{ right: 'small' }}
            as="a"
            href={ imgUrls?.front || '/' }
            target="_blank"              
          >
          
            { imgUrls?.front ?
              <Image
                src={ imgUrls.front }
                fallback={ archieCardPlaceholder }                
                alt="Archie card front view"       
                fit="cover"      
                className="archiecard-img"                                  
              /> :
              <Spinner
                color="teal_text_1"
                message="Loading Preview..."
                size="large"
                className="spinner"
              />
            }

            <Image
              src={ archieCardPlaceholder }
              fallback={ archieCardPlaceholder }                
              alt="Archie card front view"     
              fit="cover" 
              className={ imgUrls?.front ? 'hide-placeholder' : '' }                                                  
            /> 

          </Box>

          <Text gridArea="text2" size="small" margin={{ bottom: 'xsmall', left: `${ mobile ? 'xsmall' : '18px'}` }}>Archie Card Inside</Text>

          <Box 
            gridArea="img2"
            margin={{ left: 'small' }} 
            className="img-wrap"
            as="a"
            href={ imgUrls?.inside || '/' }
            target="_blank"                    
          >              

            { imgUrls?.inside ?
              <Image
                src={ imgUrls.inside }
                fallback={ archieCardPlaceholder }                
                alt="Archie card inside view"     
                fit="cover"    
                className="archiecard-img"                                         
              /> :
              <Spinner
                color="teal_text_1"
                message="Loading Preview..."
                size="large"
                className="spinner"
              />                
            }

            <Image
              src={ archieCardPlaceholder }
              fallback={ archieCardPlaceholder }                
              alt="Archie card inside view"     
              fit="cover" 
              className={ imgUrls?.inside ? 'hide-placeholder' : '' }                                             
            />

          </Box>

          <Box 
            gridArea="btns"
            fill={true}
            justify="end" 
            align="center"
            pad={{ top: 'small', bottom: 'none', left: 'small', right: 'none' }}
          >

            <Button               
              alignSelf="end"
              default 
              size="small"
              onClick={ closePreview } 
              a11yTitle="Close Archie card preview" 
              label="Close This"      
            /> 

          </Box>

        </Grid>

        <ErrModal />

      </ArchieCardStyled>
    );
  }, [ showPreview, closePreview, imgUrls?.front, imgUrls?.inside ]);     

  return { 
    ImgPreviewModal: ImgPreviewModal,
    setShowPreview: setShowPreview 
  };
};