import styled from 'styled-components';

export const BillingFormStyled = styled.form`
  max-width: 32rem;
  width: 100%; 
  position: relative;
  fieldset {
    border: none;
  }
  .text-input-wrap {
    margin-bottom: ${props => props.theme.global.edgeSize.small};
  }
`;