import { Text, Grid, Paragraph, Box, Collapsible } from 'grommet';

export const TemplateTags = ({ tags, gridArea, open, master = false }) => {
  if (!tags || !tags.length) {
    return null;
  }

  return  (
    <Box
      align="end"
      // margin={{ top: `${ master ? 'small' : 'none'}` }}
      pad={{ horizontal: "xsmall" }}      
      margin="none"
      gridArea={ gridArea }
      fill="horizontal"
      className="collapsible-wrap"
    >

      <Collapsible open={ open } >

        <Box
          className="form-info-box"          
          margin={{ vertical: 'small' }}
          pad="small"     

        >

          <Paragraph size="small" margin="none" style={{ maxWidth: '100%' }}>Autofill tags can be used to add data to your messages. They should be wrapped in double-curly-braces, like this: <strong>&#123;&#123; { tags[0].value } &#125;&#125;</strong></Paragraph>

          <Grid      
            rows={['auto']}        
            columns={['1fr', '1fr']}  
            margin={{ top: 'small' }}
            align="end"                   
          >

            { tags.map((templateTag, index) => {
              return ([
                <Text size="small" key={`tag-label-${index}`} margin={{ top: ( index > 0 ? 'xsmall' : '0') }}>{ templateTag.label }:</Text>,
                <Text size="small" key={`tag-value-${index}`} margin={{ left: 'xsmall' }}>&#123;&#123; { templateTag.value } &#125;&#125;</Text>
              ]);
            })}

          </Grid>
        </Box>
      </Collapsible>
    </Box>
  );
};




