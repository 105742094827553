// https://github.com/grommet/grommet/blob/master/src/js/themes/base.js
// https://github.com/grommet/grommet-starter-existing-app
// https://v2.grommet.io/color
// https://theme-designer.grommet.io/
// https://github.com/grommet/grommet/wiki/Grommet-v2-theming-documentation
// https://v2.grommet.io/starter
// bug/issue with as="div" : https://github.com/grommet/grommet/issues/2699

/*
  Try using colors from here:

  https://nextui.org/docs/theme/default-theme

  or: https://colors.eva.design/
  or: https://www.radix-ui.com/colors - THIS IS COOL, see: https://www.radix-ui.com/docs/colors/getting-started/usage
*/

import { normalizeColor } from 'grommet/utils';
import { colors } from './colors.js'
// import { lighten } from 'polished';

const fontRules = `
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: "pnum";
  font-feature-settings: "pnum";
  font-variant-numeric: proportional-nums;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  -moz-font-variant-ligatures: none;
  -ms-font-variant-ligatures: none;           
`;

export const theme = {

  // GLOBAL -+-+-+-+-+-+-+-+-+-+-
  global: {
    colors: colors,    
    font: {
      size: '1.6rem',
      family: 'TT Norms, Poppins, sans-serif, Helvetica, Arial, sans-serif',
      height: '1.4', // this is line height
    },
    drop: {
      // zIndex: 99,
    },
    // control: {
    //   color: {
    //     dark: 'blue',
    //     light: 'red',
    //   },
    // },
    active: {
      background: {
        color: 'sage9',
        opacity: '1',
      },
      // color: {
      //   dark: 'sage2',
      //   light: 'sage12',
      // },      
    },
    input: {
      font: {
        weight: 'normal',
        size: 'small'
      },
      padding: 'xsmall',
      // extend: (test) => {
      //   console.log(test.theme);
      //   return ``;
      // } 
    },
    selected: {
      // background: 'selected',
      background: 'teal6',
      // COLOR DOESNT WORK OR IS OVERIDDEN
      // color: 'red !important'
    },
    hover: {
      background: 'brand_light',
      // color: 'yellow',      
    },
    breakpoints: {
      medium: {
        value: '1000'
      },
      // small is 768
      xsmall: {
        value: '650'
      },   
      xxsmall: {
        value: '500'
      }      
    }    
  },

  // GROMMET (BASE WRAPPER) -+-+-+-+-+-+-+-+-+-+-
  grommet: {
    extend: () => {
      return fontRules;
    },
  },

  // COLLAPSIBLE -+-+-+-+-+-+-+-+-+-+-
  // collapsible: {
  //   extend: () => {
  //     return `
  //       width: 100%;
  //       background: red;
  //       border: 10px solid blue;
  //     `
  //   },
  // },

  // PAGINATION -+-+-+-+-+-+-+-+-+-+-
  pagination: {
    button: {
      color: 'teal11',
      background: {
        color: 'teal5',        
      },
      border: {
        color: 'teal5',    
        radius: '0.4rem',
        width: '0.1rem',        
      }, 
    }
  },

  // TIP -+-+-+-+-+-+-+-+-+-+-
  tip: {
    content: { 
      background: 'light-3',
      color: 'text', 
      elevation: 'small', 
      margin: 'xsmall', 
      pad: { vertical: 'xsmall', horizontal: 'small' }, 
      round: 'small',
      size: 'xsmall',    
      extend: () => {
        return `font-size: 10px;`
      },  
    },
    drop: {
      elevation: 'none',
      margin: 'none',    
      size: 'xsmall',    
      extend: () => {
        return `font-size: 10px;`
      },        
    }
  },

  // ANCHOR -+-+-+-+-+-+-+-+-+-+-  
  anchor: {
    color: 'blue9',
    fontWeight: 'normal',
    textDecoration: 'underline',
    hover: {
      fontWeight: 'normal',
      textDecoration: 'underline',
      extend: (props) => {
        return `      
          color: ${props.theme.global.colors.blue11};
        `
      },
    },
    // extend: () => {}
  },

  // LAYER -+-+-+-+-+-+-+-+-+-+-
  layer: {    
    extend:(props) => { 
      return `
        @media (max-width: ${props.theme.global.breakpoints.xsmall.value}px) { 
          position: absolute; /* for iOS safari, bottom UI covers controls */
          min-height: -webkit-fill-available; /* for iOS safari, bottom UI covers controls */
        }
      `
    },
    container: {      
      extend:(props) => {        
        return `        
          font-weight: normal;   
          ${fontRules}
          @media (max-width: ${props.theme.global.breakpoints.xsmall.value}px) { 
            min-height: -webkit-fill-available; /* for iOS safari, bottom UI covers controls */
            max-height: -webkit-fill-available; /* for iOS safari, bottom UI covers controls */
          }          
        `
      },
    }
  },

  // GRID -+-+-+-+-+-+-+-+-+-+-
  grid: {
    extend:() => {
      return `
        border: none;          
      `
    },    
  },  

  // BOX -+-+-+-+-+-+-+-+-+-+-
  box: {
    extend:(props) => {
      // console.log(props.theme)
      return `
        position: relative;        
      `
    },    
  },   
  
  // MENU -+-+-+-+-+-+-+-+-+-+-
  // menu: {
  //   extend:(props) => {
  //     // console.log(props.theme)
  //     return `
  //       background: red;      
  //     `
  //   },    
  // },     

  // TEXTAREA -+-+-+-+-+-+-+-+-+-+-
  textArea: {
    extend: () => {
      return `
        font-weight: normal;    
        ${fontRules}
      `      
    },
  },  

  // TEXTINPUT -+-+-+-+-+-+-+-+-+-+-
  textInput: {
    extend: () => {
      return `
        font-weight: normal;        
        ${fontRules}
      `      
    },
    // container: {
    //   extend: () => {
    //     return `
    //       margin-bottom: 2rem;         
    //     `
    //   }
    // }
  },  

  // CHECKBOX -+-+-+-+-+-+-+-+-+-+-
  checkBox: {
    color: 'green9',
    gap: 'xsmall',
    pad: 'none',
    border: {
      width: '1px',
      color: 'sage8',
    },
    check: {
      radius: '3px',
      thickness: '2px',
    },
    label: {
      align: 'center',
    },
    hover: {
      border: {
        color: 'green8',      
      },
      // background: {
      //   color: 'green11',      
      // }
    },    
    extend: (props) => {
      // console.log(props.theme.text.large);
      return `      
        font-size: ${props.theme.text.small.size};
      `
    },
    toggle: {
      background: 'white',
      color: 'sage10'
    }
  },  

  // SELECT -+-+-+-+-+-+-+-+-+-+-
  select: {
    size: 'small',
    // background: '#ccc',
    clear: {
      container: {
        background: 'info_light'
      },
      text: {
        size: 'small',
        color: 'info_dark',
      }
    },
    options: {
      text: {
        size: 'small',
        color: 'sage12',
        // tip: 'here\'s a tooltip for text'
      },
      container: {
        // extend: props => `
        //   font-size: 122px;
        // `,
      },
    },
  },

  // BUTTONS -+-+-+-+-+-+-+-+-+-+-
  button: { 
    // DEFAULT BUTTON ********
    default: {
      background: {
        color: 'teal1',
      },
      border: {
        color: 'teal7',    
        radius: '0.4rem',
        width: '0.1rem',        
      },
      font: {
        weight: 'normal',
      },
      color: 'teal10',      
      padding: {
        horizontal: 'small',
        vertical: '5px',
      },
      extend: ({ colorValue, theme }) => {
        // console.log(props.colorValue)
        if (colorValue === 'cancel_btn') {
          return `
            color: 'text',
            background: ${normalizeColor('cancel_btn', theme)};
            border-color: ${normalizeColor('cancel_btn', theme)};         
          `;
        }
      }   
    },
    // PRIMARY BUTTON ********
    primary: {
      color: 'teal2', 
      background: {
        color: 'teal9',        
      },
      border: {
        color: 'teal9',    
        radius: '0.4rem',
        width: '0.1rem',        
      },
      font: {
        weight: 'normal',
      },  
      padding: {
        horizontal: 'small',
        vertical: '5px',
      },                
    },
    // SECONDARY BUTTON ********
    secondary: {
      color: 'teal11',
      background: {
        color: 'teal5',        
      },
      border: {
        color: 'teal5',    
        radius: '0.4rem',
        width: '0.1rem',        
      },      
      padding: {
        horizontal: 'small',
        vertical: '5px',
      },      
      extend: ({ colorValue, theme }) => {
        // console.log(props.colorValue)
        if (colorValue === 'warn_btn') {
          return `
            color: ${normalizeColor('status-critical', theme)};            
            border-color: ${normalizeColor('status-critical', theme)};         
          `;
        }
      }         
    },
    active: {
      color: 'text',
      background: { color: 'brand-contrast' },      
      // secondary: {
      //   background: 'none',
      //   border: {
      //     color: 'brand-contrast',
      //   },
      // },
    },
    disabled: {
      color: 'sage9',
      background:{
        color: 'sage2',        
      },
      border: {
        color: 'sage9',
      },
      opacity: 0.3,
      // secondary: {
      //   border: { color: 'text-weak' },
      // },
    },
    hover: {
      // background: { color: 'active' },
      default: {
        color: 'teal11',
        background: { color: 'teal3'},
        border: { color: 'teal8'},        
        extend: ({ colorValue, theme }) => {
          // console.log(props.colorValue)
          if (colorValue === 'cancel_btn') {
            return `
              color: white;
              background: ${normalizeColor('cancel_btn_hover', theme)};
              border-color: ${normalizeColor('cancel_btn_hover', theme)};         
            `;
          }
        }        
      },
      primary: {
        color: 'white',
        border: { color: 'teal10'},
        background: { color: 'teal10'},
      },
      secondary: {
        color: 'teal12',
        border: { color: 'teal6'},
        background: { color: 'teal6'},
        extend: ({ colorValue, theme }) => {
          // console.log(props.colorValue)
          if (colorValue === 'warn_btn') {
            return `
              color: ${normalizeColor('white', theme)};
              background: ${normalizeColor('status-critical', theme)};
              border-color: ${normalizeColor('status-critical', theme)};                  
            `;
          }
        }           
      },  
    },
  },
};
