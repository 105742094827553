import { ClientLogList } from './ClientLogList.js';
import { ClientLogStyled } from './ClientLog.css.js';

export const ClientLog = () => {
  return  (
    <ClientLogStyled 
      forwardedAs="section"
      width="100%"
    >
      <ClientLogList />
    </ClientLogStyled>
  )
};